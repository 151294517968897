<template>
  <!-- <v-scale-screen
    width="1920"
    height="1080"
    :delay="100"
    :fullScreen="true"
    :boxStyle="{
      backgroundColor: '#000'
    }"
  > -->
  <!-- <VScaleScreen 
    width="1920"
    height="1080"
    :delay="200"
    :boxStyle="boxStyle"
  > -->
  <div class="mainbox">
    <div class="header">
      <div class="timeDiv">
        <el-select
            v-model="branchId"
            value-key="branchId"
            placeholder="选择单位"
            @change="branchIdChange"
            class="custom-select"
          >
            <el-option
              v-for="item in branchIdOptions"
              :key="item.id"
              :label="item.unitName"
              :value="item.id"
            >
            </el-option>
          </el-select>
      </div>
      <div class="titleDiv">
        <span class="title">兵团设计院EPC项目管理云平台</span>
      </div>
      <div class="adminDiv">
        <!-- <img
          src="../assets/images/设置.png"
          class="shezhiImg"
          @click="goSystem"
        /> -->
        <span class="time">{{ date | formatDate }}</span>
        <img src="../assets/images/admin.png" class="adminImg" />
        <div>
          <el-dropdown>
            <span class="loginname"
              >{{ name }}<i class="el-icon-arrow-down el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click="logout"
                ><span @click="logout">注销账户</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="leftCont">
        <div class="box leftContTop">
          <div class="boxheader">
            <span> 历年项目合同及分析 </span>
          </div>
          <div class="boxcont aqsccont">
            <div class="aqsc">
              <p class="aqscP1">历史项目数<span>个</span></p>
              <p class="aqscP2">{{hteData}}</p>
            </div>
            <div class="aqsc">
              <p class="aqscP1">合同额<span>亿元</span></p>
              <p class="aqscP2">{{hteData1}}</p>
            </div>
            <div class="aqsc_zl">
              <div class="aqsc_zldiv">
                <p class="aqsc_zlP1">{{slNum}}<span>个</span></p>
              </div>
             <div class="aqsc_zldiv">
              <p class="aqsc_zlP2">{{slHt}}<span>亿元</span></p>
             </div>
             <div class="aqsc_zldiv">
                <p class="aqsc_zlP3">{{slzb}}</p>
              </div>
            </div>
            <div class="aqsc_zl1">
              <div class="aqsc_zldiv">
                <p class="aqsc_zlP1">{{jzNum}}<span>个</span></p>
              </div>
             <div class="aqsc_zldiv">
              <p class="aqsc_zlP2">{{jzHt}}<span>亿元</span></p>
             </div>
             <div class="aqsc_zldiv">
              <p class="aqsc_zlP3">{{jzzb}}</p>
              </div>
            </div>
            <div class="aqsc_zl2">
              <div class="aqsc_zldiv">
                <p class="aqsc_zlP1">{{glNum}}<span>个</span></p>
              </div>
             <div class="aqsc_zldiv">
              <p class="aqsc_zlP2">{{glHt}}<span>亿元</span></p>
             </div>
             <div class="aqsc_zldiv">
              <p class="aqsc_zlP3">{{glzb}}</p>
              </div>
            </div>
            <div class="aqsc_zl3">
              <div class="aqsc_zldiv">
                <p class="aqsc_zlP1">{{dlNum}}<span>个</span></p>
              </div>
             <div class="aqsc_zldiv">
              <p class="aqsc_zlP2">{{dlHt}}<span>亿元</span></p>
             </div>
             <div class="aqsc_zldiv">
              <p class="aqsc_zlP3">{{dlzb}}</p>
              </div>
            </div>
            <div class="aqsc_zl4">
              <div class="aqsc_zldiv">
                <p class="aqsc_zlP1">{{nyNum}}<span>个</span></p>
              </div>
             <div class="aqsc_zldiv">
              <p class="aqsc_zlP2">{{nyHt}}<span>亿元</span></p>
             </div>
             <div class="aqsc_zldiv">
              <p class="aqsc_zlP3">{{nyzb}}</p>
              </div>
            </div>
          </div>
        </div>
        
        <!-- <div class="box leftContBot">
          <div class="boxheader">
            <span> 总合同信息 </span>
          </div>
          <div class="boxcont">
            
            <el-descriptions :column="2" direction="vertical">
              <el-descriptions-item label="合同名称"  :span="2"><a style=" color: #ff919c; cursor: pointer;"  @click="yulan(tableData2.files[0].filePath)">{{tableData2.contractorName}}</a></el-descriptions-item>
              <el-descriptions-item label="签订日期">{{tableData2.signatureTime}}</el-descriptions-item>
              <el-descriptions-item label="合同金额">{{tableData2.contractorMonetary}}</el-descriptions-item>
                        <el-descriptions-item label="建设单位（业主单位名称）">{{tableData2.constructionUnit}}</el-descriptions-item>
                        <el-descriptions-item label="建设单位-联系人及联系方式">{{tableData2.constructionLiaison}}</el-descriptions-item>
                        <el-descriptions-item label="实施单位（分院名称）">{{tableData2.implementingEntity}}</el-descriptions-item>
                        <el-descriptions-item label="实施单位-联系人及联系方式">{{tableData2.implementingLiaison}}</el-descriptions-item>
                  </el-descriptions>
          </div>
        </div> -->
      </div>
      <div class="centerCont">
        <div class="protitle">
          <span>项目分布</span></div>
          <div class="contentCenter">
            <div id="map" style="">

            </div>
            <div class="tuli">
                 <div>
                        <img src="../assets/img/homepage_new/定位.png" style="">
                        <el-badge :value="doingNum" class="item" type="success">
                        <span> 正在进行中</span>
                        </el-badge>
                        <i v-if="doingShow" class="el-icon-view" @click="doingClick"></i>
                        <i v-else class="el-icon-noshowyincang iconfont" @click="doingClick"></i>
                </div>
                <div >
                    <img src="../assets/img/homepage_new/定位blue.png" >
                    <el-badge :value="doneNum" class="item" type="primary">
                    <span > 已完成项目</span>
                    </el-badge>
                    <i v-if="doneShow" class="el-icon-view" @click="doneClick"></i>
                    <i v-else   class="el-icon-noshowyincang iconfont" @click="doneClick"></i>
                </div>
                <!-- <div >
                    <img src="../assets/img/homepage_new/定位yeelow.png" >
                    <el-badge :value="todoNum" class="item" type="warning">
                        <span> 未开始项目</span>
                    </el-badge>
                    <i v-if="todoShow" class="el-icon-view" @click="todoClick"></i>
                    <i v-else   class="el-icon-noshowyincang iconfont" @click="todoClick"></i>
                </div>
                <div  >
                    <img src="../assets/img/homepage_new/定位red.png" >
                    <el-badge :value="notdoNum" class="item">
                        <span> 已超期项目</span>
                    </el-badge>
                    <i v-if="notdoShow" class="el-icon-view" @click="notdoClick"></i>
                    <i v-else   class="el-icon-noshowyincang iconfont" @click="notdoClick"></i>
                </div> -->
            </div>
          </div>
      </div>
      <div class="rightCont">
        <div class="box rightContTop">
          <div class="boxheader">
            <span> 年度项目合同额分析</span>
          </div>
          <div class="boxcont">
            <div class="htfx">
              <div class="htfxfb">
                <div class="htfxfbh">
                  <p class="htfxfbp">{{zbht}}</p>
                </div>
                <div class="htfxfbh">
                  <p class="htfxfbp">{{zbhte}}</p>
                </div>
              </div>
              <div class="htfxfb">
                <div class="htfxfbh">
                  <p class="htfxfbp">{{qdht}}</p>
                </div>
                <div class="htfxfbh">
                  <p class="htfxfbp">{{qdhte}}</p>
                </div>
              </div>
              <div class="htfxfb">
                <div class="htfxfbh">
                  <p class="htfxfbp">{{dqht}}</p>
                </div>
                <div class="htfxfbh">
                  <p class="htfxfbp">{{dqhte}}</p>
                </div>
              </div>
            </div>
            <div class="htqd">
              <!-- <div class="htqd1">
                <div class="htqd1div">
                  <p class="htqd1p1">目标值<span>{{mubiaozhi}}</span></p>
                </div>
                <div class="htqd1div">
                  <p class="htqd1p1">完成值<span>{{wanchengzhi}}</span></p>
                </div>
                <div class="htqd1div">
                  <p class="htqd1p1">完成度<span>{{wanchengdu}}</span></p>
                </div>
              </div>
              <div class="htqd2">

              </div> -->
              <div class="htqd3">
                <div class="htqd1div">
                  <p class="htqd1p1">合同情况</p>
                </div>
                <div id="myChart1"></div>
              </div>
              <div class="htqd3">
                <div class="htqd1div">
                  <p class="htqd1p1">权责收入</p>
                </div>
                <div id="myChart2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomCont">
        <div class="centerContTop">
          <div class="box centerContTopTop">
            <div class="boxheader">
              <span> 本年度项目进度 </span>
            </div>
            <div class="boxcont">
              <el-table
                :data="tasksdata"
                class="custom-table"
                row-key="id"
                default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                style="width: 100%; "
                max-height="250"
                :row-class-name="tableRowClassName"
              >
                <!-- <el-table-column prop="taskName" label="" width="250">
                  <template slot-scope="scope">
                    <span :title="scope.row.taskName"  style="color: yellow;display: -webkit-box;text-overflow: ellipsis;overflow: hidden; -webkit-box-orient: vertical; white-space: pre-line; -webkit-line-clamp: 1;">{{ scope.row.taskName }}</span>
                  </template>
                </el-table-column> -->
                <el-table-column
                  prop="typeName"
                  label="本年度"
                  align="center"
                  width="200"
                  :show-overflow-tooltip='true'
                >
                </el-table-column>
                <el-table-column label="中标情况">
                  <el-table-column
                  prop="bidWinningTotalCount"
                  label="数量（个）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="bidWinningContractMonetary"
                  label="中标额（万元）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                </el-table-column>

                <el-table-column label="合同情况">
                  <el-table-column
                  prop="signedContractTotalCount"
                  label="已签数量（个）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="signedContractTotalMonetary"
                  label="已签合同额（万元）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="contractAnnualTarget"
                  label="年度目标值（万元）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="contractCompleteProportion"
                  label="完成度(%)"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="unsignedContractTotalCount"
                  label="待签数量（个）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="unsignedContractTotalMonetary"
                  label="待签合同额（万元）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                </el-table-column>

                <el-table-column label="收入情况">
                  <el-table-column
                  prop="incomeTaxIncluded"
                  label="权责收入含税（万元）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="incomeTaxExcluded"
                  label="权责收入不含税（万元）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="incomeAnnualTarget"
                  label="年度目标值（万元）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="incomeCompleteProportion"
                  label="完成度(%)"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="invoiceMonetary"
                  label="开票（万元）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="arriveAccountMonetary"
                  label="到账（万元）"
                  sortable 
                  align="center"
                >
                </el-table-column>
                </el-table-column>
                
                <!-- <el-table-column prop="completeRate" label="完成率">
                  <template slot-scope="scope">
                    <div class="jinduDiv">
                      <div
                        class="jinduNum"
                        v-bind:style="{ width: scope.row.completeRate + '%' }"
                      ></div>
                    </div>
                    &nbsp;{{ scope.row.completeRate }}%
                  </template>
                </el-table-column> -->
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <el-button round size="mini" class="shouye" @click="gohome">首页</el-button>
      <el-button round size="mini"  :loading="loading" class="shouye1" @click="exportMB">数据导出</el-button>
    </div>
 
    <el-dialog
      title="本年度信息"
      :visible.sync="dialogVisible"
      :modal="false"
      width="50%"
      height="400"
      max-height="400"
      class="diagTable"
      z-index="9999999999999"
      :header-cell-style="{background:'#f5f5f5',color:'#000',fontSize:'14px'}"
    >
      <el-table :data="tableDataChart1" border style="height: 400px;">
        <el-table-column prop="typeName" label="项目类型"></el-table-column>
        <el-table-column prop="projectTotalCount" label="项目总数"></el-table-column>
        <el-table-column prop="contractorTotalAmount" label="合同额（亿元）"></el-table-column>
        <el-table-column prop="proportion" label="占比"></el-table-column>
      </el-table>
    </el-dialog>

  </div>
<!-- </VScaleScreen > -->
</template>

<script>
import "../assets/css/ol1.css";
import "../assets/css/ol2.css";

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile'
import * as control from 'ol/control';
import * as olProj from 'ol/proj';
import * as olExtent from 'ol/extent';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import GeoJSON from 'ol/format/GeoJSON';
import Stroke from 'ol/style/Stroke';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Point  from 'ol/geom/Point';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import {fromExtent} from 'ol/geom/Polygon';
import LinearRing  from 'ol/geom/LinearRing';
import homeEcharts from "./echarts/homeEcharts";
import homeEchartsPro from "./echarts/homeEchartsPro";
import homeEchartsShi from "./echarts/homeEchartsShi";
import jinduEcharts from "./echarts/jinduEcharts";
import vueSeamlessScroll from 'vue-seamless-scroll';
import homeEchartsTouzi from "./echarts/homeEchartsTouzi";
import * as echarts from 'echarts';
// import { VScaleScreen } from 'v-scale-screen'
var padDate = function (value) {
  return value < 10 ? "0" + value : value;
};
var map,pop1;
    var doingSource= new VectorSource();
    var doingLayer = new VectorLayer({
        source: doingSource,
    });
    var doneSource= new VectorSource();
    var doneLayer = new VectorLayer({
        source: doneSource,
    });
    var todoSource= new VectorSource();
    var todoLayer = new VectorLayer({
        source: todoSource,
    });
    var notdoSource= new VectorSource();
    var notdoLayer = new VectorLayer({
        source: notdoSource,
    });

    var doingSource2= new VectorSource();
    var doingLayer2 = new VectorLayer({
        source: doingSource2,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位.png'),
            }))
        })
    });
    var doneSource2= new VectorSource();
    var doneLayer2 = new VectorLayer({
        source: doneSource2,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位blue.png'),
            }))
        })
    });
    var todoSource2= new VectorSource();
    var todoLayer2 = new VectorLayer({
        source: todoSource2,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位yeelow.png'),
            }))
        })
    });
    var notdoSource2= new VectorSource();
    var notdoLayer2 = new VectorLayer({
        source: notdoSource2,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位red.png'),
            }))
        })
    });
    var createdoingStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 14px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#d4ddec',
                    width: 2
                }),
                offsetY:30,
                backgroundFill:new Fill({
                    color: '#d4ddec'
                }),
                padding: [5, 3, 2, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: 'black',
                    font:'bold'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位.png'),
            }))
        });
    };
    var createdoneStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 14px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#d4ddec',
                    width: 2
                }),
                offsetY:30,
                backgroundFill:new Fill({
                    color: '#d4ddec'
                }),
                padding: [5, 3, 2, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: 'black',
                    font:'bold'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位blue.png'),
            }))
        });
    };
    var createtodoStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 14px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#d4ddec',
                    width: 2
                }),
                offsetY:30,
                backgroundFill:new Fill({
                    color: '#d4ddec'
                }),
                padding: [5, 3, 2, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: 'black',
                    font:'bold'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位yeelow.png'),
            }))
        });
    };
    var createnotdoStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 14px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#d4ddec',
                    width: 2
                }),
                offsetY:30,
                backgroundFill:new Fill({
                    color: '#d4ddec'
                }),
                padding: [5, 3, 2, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: 'black',
                    font:'bold'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/homepage_new/定位red.png'),
            }))
        });
    };
    var mystyle = new Style({
        fill: new Fill({
            color:"rgba(255,255,255, 0.9)",
        }),
        stroke: new Stroke({
            color:"#BDBDBD",
            width:2
        })
    });
export default {
  name: "largescreen",
  data() {
    return {
      boxStyle: {
        backgroundColor: '#0a1132',
        overflow: 'hidden'
      },
      dialogVisible: false, // 控制弹窗显示
      tableDataChart1: [], // 表格数据
      dialogVisible1: false, // 控制弹窗显示
      tableDataChart2: [], // 表格数据
      date: new Date(),
      name: "管理员",
      proname: window.sessionStorage.getItem("proname"),
      proid: window.sessionStorage.getItem("id"),
      branchId:'',
      allUrl:
        "http://8.130.130.12:8808/iserver/services/map-epcdata/rest/maps/china",
      dataurl: "http://8.130.130.12:8808/iserver/services/data-epcdata/rest/data",
      accessToken: "at.8gw4vfjo8wq5lrag2c6sy4ea9fjjor2r-6pn6l05ik6-1xq86j8-fvhjjneuy",
      shexiangurl : "ezopen://open.ys7.com/AF3754209/2.hd.live",
      deviceSerial: "",
      channelNo: "",
      costData:{
        "contractSum": null,
                    "paymentRatio": null,
                    "progressMeasurement": null,
                    "projectReceivable": null,
                    "actualCollection": null,
                    "projectAppropriation": null,
                    "balanceAccount": null,
                    "remainingReceivables": null,
                    performanceBond:null,
                    performanceDeadlines:null,
                    qualityAssurance:null,
                    qualityDeadlines:null,
      },
      tableData2: {
        contractorName:"",
        constructionUnit:"",
        contractorMonetary:"",
        constructionUnit:"",
        constructionLiaison:"",
        implementingEntity:"",
        implementingLiaison:''
      }
      ,
      typeData:[],
      liData: [
       
      ],
      loading: false, 
      index: 0,
      tasksdata:[],
      popupdata:{},
      qualiFrom:{
      },
      doingNum:0,
      doneNum:0,
      todoNum:0,
      notdoNum:0,
      doingShow:true,
      doneShow:true,
      todoShow:true,
      notdoShow:true,
      popupdata:{},
      costform:{},
      proData:[{id:1,projectName:'图木舒克市农副产品精深加工标准化厂房项目（EPC）',address:'新疆维吾尔自治区自治区直辖县级行政区划图木舒克市第三师图木舒克达坂山工业园区',"projectType": {
                        "id": 1,"code": "222", "typeName": "园区" },longitude:'79.071925',latitude:'39.867719444',time:28}],
      hteData:'',
      hteData1:'',
      slNum:'',
      slHt:'',
      jzNum:'',
      jzHt:'',
      dlNum:'',
      dlHt:'',
      glNum:'',
      glHt:'',
      nyNum:'',
      nyHt:'',
      slzb:'',
      jzzb:'',
      glzb:'',
      dlzb:'',
      nyzb:'',

      zbht:'',
      zbhte:'',
      qdht:'',
      qdhte:'',
      dqht:'',
      dqhte:'',

      mubiaozhi:'',
      wanchengzhi:'',
      wanchengdu:'',

      branchIdOptions:[],
      percent:'', 
      percent1:'', 
      datachart1:[], 
      datachart2:[],               
    };
  },
  methods: {
     
    async initMap(){
      // console.log(VScaleScreen.name)
                map = new Map({
                    target: 'map',
                    view: new View({
                        center: [95.28838135,38.209817352],
                        zoom: 4.5,
                        projection: 'EPSG:4326'
                    }),
                });
                var projection = olProj.get('EPSG:4326');
                var projectionExtent = projection.getExtent();
                var size = olExtent.getWidth(projectionExtent) / 256;
                var resolutions = new Array(19);
                var matrixIds = new Array(19);
                for(var z = 0; z < 19; z++) {
                    // generate resolutions and matrixIds arrays for this WMTS
                    //resolutions[z] = size / Math.pow(2, z);
                    matrixIds[z] = z;
                }
                resolutions = [1.40625, 0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625, 0.010986328125, 0.0054931640625, 0.00274658203125, 0.001373291015625, 0.0006866455078125, 0.00034332275390625, 0.000171661376953125,
                    0.0000858306884765625, 0.00004291534423828125, 0.000021457672119140625, 0.000010728836059570312, 0.000005364418029785156, 0.000002682209014892578, 0.000001341104507446289
                ];
                var webKey = "b0f80d188ee3100e0327398d8afb39cd";
                var tdtsldtLayer = new TileLayer({
                    className:'blackLayer',
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/vec_c/wmts?tk=" + webKey,
                        layer: 'vec',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtslzjLayer =new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/cva_c/wmts?tk=" + webKey,
                        layer: 'cva',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtyxzjLayer =new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/cia_c/wmts?tk=" + webKey,
                        layer: 'cia',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtyxLayer = new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/img_c/wmts?tk=" + webKey,
                        layer: 'img',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                map.addLayer(tdtsldtLayer)
                map.addLayer(tdtslzjLayer)
                //  map.addLayer(tdtyxLayer);
                //map.addLayer(tdtyxzjLayer);
                var converLayer = new VectorLayer({
                    source: new VectorSource(),
                    style: mystyle
                });
                //  map.addLayer(converLayer)
                const {data:result_xj}= await this.$httpaxios.get('./china_R.json')
                var fts = new GeoJSON().readFeatures(result_xj);
                var ft = fts[0];
                var converGeom = this.erase(ft.getGeometry());
                var convertFt = new Feature({
                    geometry: converGeom
                })
                converLayer.getSource().addFeature(convertFt);
                var xjSource = new VectorSource({
                   // url: 'http://localhost:8086/china_R.json',
                    url:'./china_R.json',
                    format: new GeoJSON(),
                    wrapX: false
                });
                var xjLayer = new VectorLayer({
                    source: xjSource,
                    style: new Style({
                        stroke: new Stroke({
                            //color: '#0033ff',
                            color: 'rgb(0, 186 ,255)',
                            width: 3
                        }),
                        fill: new Fill({
                            color: 'rgba(0, 186 ,255, 0.01)'
                        })
                    })
                });
                map.addLayer(xjLayer)
                notdoLayer.setVisible(false)
                doneLayer.setVisible(false)
                todoLayer.setVisible(false)
                doingLayer.setVisible(false)
                // map.addLayer(notdoLayer);
                map.addLayer(doneLayer);
                // map.addLayer(todoLayer);
                map.addLayer(doingLayer);
                // map.addLayer(notdoLayer2);
                map.addLayer(doneLayer2);
                // map.addLayer(todoLayer2);
                map.addLayer(doingLayer2);
                pop1 = new Overlay({
                    element: document.getElementById('popup2'),
                    autoPan: true,
                    autoPanAnimation: {
                        duration: 250
                    },
                    offset: [0, -20]
                });
                map.on('click',evt=> {
                    var coordinate = evt.coordinate;
                    map.forEachFeatureAtPixel(evt.pixel, (cxfeature, layerVetor)=> {
                        if(layerVetor == gjLayer) {
                            this.pro.projectName=cxfeature.values_.projectName;
                            this.pro.projectType.typeName=cxfeature.values_.typeName;
                            this.pro.projectAddress=cxfeature.values_.projectAddress;
                            this.pro.planEndDate=cxfeature.values_.planEndDate;
                            this.pro.picmapurl=cxfeature.values_.picmapurl;
                            this.pro.srcmapList=cxfeature.values_.srcmapList;
                            this.popupshow=true;
                            map.addOverlay(pop1);
                            pop1.setPosition(coordinate);
                            map.getView().setCenter(coordinate)
                        }
                    })
                })
                map.getView().on('change:resolution', function() {
                    console.log(map.getView().getZoom())
                    if(map.getView().getZoom() < 6){
                        notdoLayer2.setVisible(true);
                        doneLayer2.setVisible(true);
                        todoLayer2.setVisible(true);
                        doingLayer2.setVisible(true);
                        notdoLayer.setVisible(false);
                        doneLayer.setVisible(false);
                        todoLayer.setVisible(false);
                        doingLayer.setVisible(false);
                    }else {
                        notdoLayer2.setVisible(false);
                        doneLayer2.setVisible(false);
                        todoLayer2.setVisible(false);
                        doingLayer2.setVisible(false);
                        notdoLayer.setVisible(true);
                        doneLayer.setVisible(true);
                        todoLayer.setVisible(true);
                        doingLayer.setVisible(true);
                    }
                })
            },
            erase(geom) {
                var extent = [-180,-90,180,90];
                var polygonRing =new fromExtent(extent);
                var coords = geom.getCoordinates();
                for(let i in coords){
                    var linearRing = new LinearRing(coords[i][0]);
                    polygonRing.appendLinearRing(linearRing);
                }
                console.log(polygonRing)
                return polygonRing;
            },
            doingClick(){
                this.doingShow=!this.doingShow;
                if(this.doingShow){
                    if(map.getView().getZoom() < 6){
                        doingLayer2.setVisible(true);
                    }else {
                        doingLayer.setVisible(true);
                    }
                }else {
                    if(map.getView().getZoom() < 6){
                        doingLayer2.setVisible(false);
                    }else {
                        doingLayer.setVisible(false);
                    }
                }
            },
            doneClick(){
                this.doneShow=!this.doneShow;
                if(this.doneShow){
                    if(map.getView().getZoom() < 6){
                        doneLayer2.setVisible(true);
                    }else {
                        doneLayer.setVisible(true);
                    }
                }else {
                    if(map.getView().getZoom() < 6){
                        doneLayer2.setVisible(false);
                    }else {
                        doneLayer.setVisible(false);
                    }
                }
            },
            todoClick(){
                this.todoShow=!this.todoShow;
                if(this.todoShow){
                    if(map.getView().getZoom() < 6){
                        todoLayer2.setVisible(true);
                    }else {
                        todoLayer.setVisible(true);
                    }
                }else {
                    if(map.getView().getZoom() < 6){
                        todoLayer2.setVisible(false);
                    }else {
                        todoLayer.setVisible(false);
                    }
                }
            },
            notdoClick(){
                this.notdoShow=!this.notdoShow;
                if(this.notdoShow){
                    if(map.getView().getZoom() < 6){
                        notdoLayer2.setVisible(true);
                    }else {
                        notdoLayer.setVisible(true);
                    }
                }else {
                    if(map.getView().getZoom() < 6){
                        notdoLayer2.setVisible(false);
                    }else {
                        notdoLayer.setVisible(false);
                    }
                }
            },
            dingwei(item){
                this.pro.projectName=item.projectName;
                this.pro.projectType.typeName=item.projectType.typeName;
                this.pro.projectAddress=item.projectAddress;
                this.pro.planEndDate=item.planEndDate;
                this.pro.picmapurl=item.picmapurl;
                this.pro.srcmapList=item.srcmapList;
                this.popupshow=true;
                map.addOverlay(pop1);
                pop1.setPosition([Number(item.longitude),Number(item.latitude)]);
                map.getView().setCenter([Number(item.longitude),Number(item.latitude)])
                map.getView().setZoom(6)
            },
            closePop(){
                this.popupshow=false;
            },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "shallow-row";
      } else {
        return "deep-row";
      }
    },
    tableRowClassName2({ row, rowIndex }) {
      return "tou-row";
    },
    async getProtype(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAllType')
                this.typeData=res.data
            },
    async getLnhte(){
        const {data:res}= await this.$http1.get('/v1.0/panel/statistics/queryProjectTotalCount?branchId='+this.branchId)
        this.hteData=res.data
        
    },
    async getLnhte1(){
        const {data:res}= await this.$http1.get('/v1.0/panel/statistics/queryContractTotalMonetary?branchId='+this.branchId)
        this.hteData1 = (res.data/ 100000000).toFixed(2);
        // return this.hteData1.toFixed(2); // 保留两位小数
    },
    async getLnhte2(){
        const {data:res}= await this.$http1.get('/v1.0/panel/statistics/queryProjectContractorStatistics?branchId='+this.branchId)
        for(let i in res.data){
          if(res.data[i].typeId == 2){
            this.jzNum = res.data[i].projectTotalCount
            this.jzHt = (res.data[i].contractorTotalAmount/ 100000000).toFixed(1)
            this.jzzb = (res.data[i].proportion*100).toFixed(0) + '%'
          }else if(res.data[i].typeId == 3){
            this.glNum = res.data[i].projectTotalCount
            this.glHt = (res.data[i].contractorTotalAmount/ 100000000).toFixed(1)
            this.glzb = (res.data[i].proportion*100).toFixed(0) + '%'
          }else if(res.data[i].typeId == 4){
            this.dlNum = res.data[i].projectTotalCount
            this.dlHt = (res.data[i].contractorTotalAmount/ 100000000).toFixed(1)
            this.dlzb = (res.data[i].proportion*100).toFixed(0) + '%'
          }else if(res.data[i].typeId == 5){
            this.nyNum = res.data[i].projectTotalCount
            this.nyHt = (res.data[i].contractorTotalAmount/ 100000000).toFixed(1)
            this.nyzb = (res.data[i].proportion*100).toFixed(0) + '%'
          }else if(res.data[i].typeId == 1){
            this.slNum = res.data[i].projectTotalCount
            this.slHt = (res.data[i].contractorTotalAmount/ 100000000).toFixed(1)
            this.slzb = (res.data[i].proportion*100).toFixed(0) + '%'
          }
        }
        // this.tableDataChart1 = res.data
    },
    async getdep(){
      this.branchId = ""
        const {data:res}= await this.$http1.get('/v1.0/panel/branch/findAllBranchType')
        this.branchIdOptions.push({"id":"","unitName":"集团公司"})
        for(let i in res.data){
          this.branchIdOptions.push({"id":res.data[i].id,"unitName":res.data[i].unitName})
        }
    },

    async getLnhte3(){
        const {data:res}= await this.$http1.get('/v1.0/panel/statistics/queryContractorStatisticsNowYear?branchId='+this.branchId)
        this.zbht = res.data.totalBidWinningContractorCount
        this.zbhte = (res.data.totalBidWinningContractorMonetary/ 100000000).toFixed(2)

        this.qdht = res.data.totalSignedContractorCount
        this.qdhte = (res.data.totalSignedContractorMonetary/ 100000000).toFixed(2)
        // this.qdhte = res.data.totalSignedContractorMonetary

        this.dqht = res.data.totalUnsignedContractorCount
        this.dqhte = (res.data.totalUnsignedContractorMonetary/ 100000000).toFixed(2)
        
        this.mubiaozhi = (res.data.contractAnnualTarget/ 100000000).toFixed(2)
        this.wanchengzhi = (res.data.totalSignedContractorMonetary/ 100000000).toFixed(2)
        this.wanchengdu = res.data.completeProportion
      
        this.percent = (res.data.completeProportion*100).toFixed(0)
        this.datachart1 = [{
                        value: this.qdhte,
                        name: '已签订',
                        itemStyle: {
                            normal: {
                                color: { // 完成的圆环的颜色
                                    colorStops: [{
                                        offset: 0,
                                        color: '#00cefc' // 0% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: '#367bec' // 100% 处的颜色
                                    }]
                                },
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    show: false
                                }
                            } 
                        }
                    }, {
                        name: '目标值',
                        value: this.mubiaozhi
                    }]
        console.log(this.datachart1,this.qdhte)
        // this.percent = '60'
        this.getChart1();
        this.getChart2();
        
    },
    branchIdChange(value){
      console.log(value)
      this.branchId = value
      this.getLnhte(),
      this.getLnhte1(),
      this.getLnhte2(),
      this.getLnhte3(),
      this.getTaskAll()
    },
    async getProinfo(){
                const {data:res}= await this.$http1.get('/v1.0/panel/statistics/findAllProject?branchId='+this.branchId)
                if(res.businessCode!='3000'){
                    return this.$message.error('查询EPC项目失败！'+res.message)
                }
                this.proData=res.data
              
                // const {data:res2}= await this.$http0.get('/v1.0/panel/statistics/findAllProject?branchId='+ window.sessionStorage.getItem("userid"))
                // for(let i in res2.data){
                //     for(let j in res.data){
                //         if(res2.data[i].projectId==res.data[j].id){
                //             this.proData.push(res.data[j])
                //         }
                //     }
                // }
                this.proData2=this.proData
                window.sessionStorage.setItem('proName','')
                //this.proData=res.data;
                var numprocee=0;
                for(let i in this.proData){
                    this.proData[i]['projectType']=this.typeData.find(o => o.id == this.proData[i].projectTypeId)
                    numprocee=0
                    for(let j in this.processData){
                        if(this.processData[j].projectId==this.proData[i].id){
                            numprocee=Number((Number(this.processData[j].avgCompleteRate)*100).toFixed(2))
                            this.proData[i].jindunum=numprocee
                        }else {
                            this.proData[i].jindunum=numprocee
                        }
                    }
                    if(this.proData[i].projectState == '7'){
                        this.proData[i].tianshu='已完成'
                    }else if(this.proData[i].projectState == '2'){
                      this.proData[i].tianshu='正在进行中'
                    } 
                //     {
                //         if(new Date(this.proData[i].planStartDate.replace('-','/'))-new Date()>0){
                //             this.proData[i].tianshu='未开始'
                //         }else {
                //             var time=((new Date(this.proData[i].planEndDate.replace('-','/'))-new Date())/(1*24*60*60*1000)).toFixed(0);
                //             time=time-1
                //             if(time<0){
                //                 this.proData[i].tianshu='已超期'
                //             }else {
                //                 this.proData[i].tianshu='正在进行中'
                //             }
                //     }
                // }
              }
                var tianshu,status;
                doneSource.clear()
                todoSource.clear()
                doingSource.clear()
                notdoSource.clear()
                doneSource2.clear()
                todoSource2.clear()
                doingSource2.clear()
                notdoSource2.clear()
                this.doneNum=0
                this.todoNum=0
                this.notdoNum=0
                this.doingNum=0
                for(let i in this.proData){
                    if(this.proData[i].actualEndDate){
                        tianshu='已完成'
                        status=0;
                    }else {
                        if(new Date(this.proData[i].planStartDate.replace('-','/'))-new Date()>0){
                            tianshu='未开始'
                            status=1;
                        }else {
                            var time=((new Date(this.proData[i].planEndDate.replace('-','/'))-new Date())/(1*24*60*60*1000)).toFixed(0);
                            if(time<0){
                                tianshu='已超期'+time+'天'
                                status=2;
                            }else {
                                tianshu='计划还剩'+time+'天'
                                status=3;
                            }
                        }
                    }
                    var jingdu = (Math.abs(this.proData[i].longitude) + (Math.abs(this.proData[i].longitudeMinute) / 60 + Math.abs(this.proData[i].longitudeSecond) / 3600));
                    var weidu = (Math.abs(this.proData[i].latitude) + (Math.abs(this.proData[i].latitudeMinute) / 60 + Math.abs(this.proData[i].latitudeSecond) / 3600));

                    var gjFeature = new Feature({
                        geometry: new Point([Number(jingdu),Number(weidu)]), //几何信息
                        name:this.proData[i].projectName+'--'+tianshu,
                        projectName:this.proData[i].projectName,
                        typeName:this.proData[i].projectType.typeName,
                        projectAddress:this.proData[i].projectAddress,
                        planEndDate:this.proData[i].planEndDate,
                      /*  picmapurl:this.proData[i].files[0].filePath,
                        srcmapList:this.proData[i].files*/
                    });
                    var gjFeature2 = new Feature({
                        geometry: new Point([Number(this.proData[i].longitude),Number(this.proData[i].latitude)]), //几何信息
                    });
                    if(status==0){
                        doneSource2.addFeature(gjFeature2)
                        gjFeature.setStyle(createdoneStyle(gjFeature));
                        doneSource.addFeature(gjFeature)
                        this.doneNum++
                    }else if(status==1){
                        todoSource2.addFeature(gjFeature2)
                        gjFeature.setStyle(createtodoStyle(gjFeature));
                        todoSource.addFeature(gjFeature)
                        this.todoNum++
                    }else if(status==2){
                        notdoSource2.addFeature(gjFeature2)
                        gjFeature.setStyle(createnotdoStyle(gjFeature));
                        notdoSource.addFeature(gjFeature)
                        this.notdoNum++
                    }else if(status==3){
                        doingSource2.addFeature(gjFeature2)
                        gjFeature.setStyle(createdoingStyle(gjFeature));
                        doingSource.addFeature(gjFeature)
                        this.doingNum++;
                    }
                }
            },
    leftlcb() {
      if (this.index > 0) {
        this.index = this.index - 1;
        document.querySelector(".time-box ul").style.transform = `translate(${
          -170 * this.index
        }px)`;
      }
    },
    rightlcb() {
      if (this.index < this.liData.length - 2) {
        this.index++;
        document.querySelector(".time-box ul").style.transform = `translate(${
          -170 * this.index
        }px)`;
      }
    },
    async yulan(path){
        window.open('http://8.130.180.224:9000/epc/'+path)
    },
    async getTaskAll(){
                const {data:res}= await this.$http1.get('/v1.0/panel/statistics/getPanelStatistics?branchId='+this.branchId)
                this.tasksdata=res.data;
                for (let i = 0; i < this.tasksdata.length; i++) {
                  this.tasksdata[i].children=this.tasksdata[i].nextLevelStatistics
                  this.tasksdata[i].id= i
                  for(let j = 0; j < this.tasksdata[i].nextLevelStatistics.length; j++){
                    this.tasksdata[i].children[j].children = this.tasksdata[i].nextLevelStatistics[j].nextLevelStatistics
                    this.tasksdata[i].children[j].id= 12+j
                    for(let m = 0; m < this.tasksdata[i].children[j].children.length; m++){
                      this.tasksdata[i].children[j].children[m].id = 30+m+i+j
                    } 
                  }
                }
                // for (let i = 0; i < this.tasksdata.length; i++) {
                //   for(let j = 0; j < this.tasksdata[i].children.length; j++){
                //     this.tasksdata[i].children.children = this.tasksdata[i].nextLevelStatistics[j].nextLevelStatistics
                //   }
                // }
                console.log( this.tasksdata)
    },
    async getlichengbei(){
      const {data:res}= await this.$http6.get('/v1.0/schedule/milestone/findAll?projectId='+this.proid)
                    if(res.businessCode!='3000'){
                        return 
            }
            this.liData = res.data;
      console.log(this.liData)
      for(let i in this.liData){
        this.liData[i].filePath=require("../assets/images/lichengbei"+i+".jpg")
      }
    },
    getChart1(){
      var myChart1 = echarts.init(document.getElementById('myChart1'));
              let option = {
                legend: {
                    show: false,
                    itemGap: 12,
                    data: ['已签订', '目标值']
                },
                tooltip: {
                  trigger: 'item',
                  formatter: '{b}: {c}亿元'
                },
                title: {
                    text: `${this.percent}%`,
                    x: 'center',
                    y: 'center',
                    textStyle: {
                        fontWeight: 'normal',
                        color: '#0580f2',
                        fontSize: '25'
                    }
                },
                color: ['rgba(176, 212, 251, 1)'], 
              
                series: [{
                    name: 'Line 1',
                    type: 'pie',
                    clockWise: true,
                    radius: ['50%', '66%'],
                    itemStyle: {
                        normal: {
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            }
                        }
                    },
                    hoverAnimation: false, 
                    data: this.datachart1
                }]
              };
              myChart1.setOption(option);

               // 监听点击事件
               myChart1.on('click', (params) => {
                this.handlePieClick(params);
              });
    }
    ,
    async getChart2(){
      const {data:res}= await this.$http1.get('/v1.0/panel/statistics/queryIncomeNowYear?branchId='+this.branchId)
      this.datachart2 = [{
                        value: (res.data.incomeTaxExcluded/ 100000000).toFixed(2),
                        name: '权责收入不含税',
                        itemStyle: {
                            normal: {
                                color: { // 完成的圆环的颜色
                                    colorStops: [{
                                        offset: 0,
                                        color: '#00cefc' // 0% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: '#367bec' // 100% 处的颜色
                                    }]
                                },
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    show: false
                                }
                            } 
                        }
                    }, {
                        name: '收入情况',
                        value: (res.data.incomeAnnualTarget/ 100000000).toFixed(2),
                    }]
      var myChart2 = echarts.init(document.getElementById('myChart2'));
              let option = {
                legend: {
                    show: false,
                    itemGap: 12,
                    data: ['权责收入不含税', '收入情况']
                },
                tooltip: {
                  trigger: 'item',
                  formatter: '{b}: {c}亿元'
                },
                title: {
                  text: `${this.percent1}%`,
                    x: 'center',
                    y: 'center',
                    textStyle: {
                        fontWeight: 'normal',
                        color: '#0580f2',
                        fontSize: '25'
                    }
                },
                color: ['rgba(176, 212, 251, 1)'], 
              
                series: [{
                    name: 'Line 1',
                    type: 'pie',
                    clockWise: true,
                    radius: ['50%', '66%'],
                    itemStyle: {
                        normal: {
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            }
                        }
                    },
                    hoverAnimation: false, 
                    data: this.datachart2
                }]
              };
              myChart2.setOption(option);
    }
    ,
    goSystem() {
      this.$router.push({ path: "/system" });
    },
    gohome() {
      this.$router.push({ path: "/home" });
    },
    async exportMB(){
         const res =  await this.$http1.get('/v1.0/panel/statistics/export?branchId='+this.branchId, {
            // params: { branchId: this.branchId}, // 使用 params 传递参数
            responseType: 'blob', // 指定响应类型为 blob
          });
          try{
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = '项目报表.xlsx';
            link.click();
          }catch (error) {
              console.error('下载文件时出错:', error);
          }

          // const branchId = this.branchId; // 替换为实际的分院ID
          //   const url = await this.$http1.get('/v1.0/panel/statistics/export?branchId='+branchId);
            
          //   // 创建隐藏的iframe来触发下载
          //   const iframe = document.createElement('iframe');
          //   iframe.style.display = 'none';
          //   iframe.src = url;
          //   document.body.appendChild(iframe);
            
          //   setTimeout(() => {
          //     document.body.removeChild(iframe);
          //   }, 5000);
         
          // const {data:res}= await this.$http1.get('/v1.0/panel/statistics/export',{  params: { branchId: this.branchId},},{ responseType: 'blob'})
          //     if(res){
          //         var elink = document.createElement('a');
          //             elink.download = '项目报表';
          //             elink.style.display = 'none';
          //             var blob = new Blob([res],{ type:"application/vnd.ms-excel" });
          //             elink.href = URL.createObjectURL(blob);
          //             document.body.appendChild(elink);
          //             elink.click();
          //             document.body.removeChild(elink);
          //         }

                  // const branchId = this.branchId;
                  // const link = document.createElement('a');
                  // link.href = this.$http1.get('/v1.0/panel/statistics/export?branchId='+branchId);
                  // link.target = '_blank';
                  // document.body.appendChild(link);
                  // link.click();
                  // document.body.removeChild(link);
         
        },
    logout() {
      window.sessionStorage.removeItem("token");
      this.$router.push({ path: "/login" });
    },
    async handlePieClick(params){
        // 显示弹窗
        const {data:res}= await this.$http1.get('/v1.0/panel/statistics/queryProjectContractorStatisticsNowYear?branchId='+this.branchId)
        for(let i in res.data){
          res.data[i].contractorTotalAmount = (res.data[i].contractorTotalAmount/ 100000000).toFixed(1)
          res.data[i].proportion = (res.data[i].proportion*100).toFixed(0) + '%'
        }
        this.tableDataChart1 = res.data
        this.dialogVisible = true;
    },
  },
  filters: {
    formatDate: function (value) {
      var date = new Date(value);
      var year = date.getFullYear();
      var month = padDate(date.getMonth() + 1);
      var day = padDate(date.getDate());
      var hours = padDate(date.getHours());
      var minutes = padDate(date.getMinutes());
      var seconds = padDate(date.getSeconds());
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
  mounted() {
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = new Date(); // 修改数据date
    }, 1000);
    this.initMap();
   
  },

  created(){
    this.getProtype()
    this.getProinfo();
    this.getdep()
    this.getTaskAll();
    this.getlichengbei();
    this.getLnhte(),
    this.getLnhte1(),
    this.getLnhte2(),
    this.getLnhte3()
  }
};
</script>



<style scoped lang="less">

.mainbox {
  color: #fff;
  font-size: 18px;
  background: url(../assets/images/beijing.png) no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  // #map{
  //       position: absolute;
  //       width: 950px;
  //       height: 460px;
  //       padding: 5px;
  //       z-index: 999999;
  //   }
    .tuli{
            width: 160px;
            height: 100px;
            background-color: white;
            position: absolute;
            z-index: 9999999;
            border-radius: 15px;
            margin-left: 780px;
            margin-top: 350px;
            padding-left: 7px;
            div{
                margin-top: 10px;
                img{
                    vertical-align: middle;height: 25px
                }
                span{
                    font-size: 16px;margin-left: 10px;color: #000;
                }
               
            }
            i{
                margin-left: 24px;
                font-size: 16px;
                vertical-align: -45%;
                color: #000;
            }

        }

  .header {
    background: url(../assets/images/header.png) no-repeat;
    background-size: 100% 100%;
    height: 60px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .timeDiv {
      width: 30%;
      .time {
        margin-left: 10px;
        line-height: 64px;
        color: #aae1ff;
        font-size: 18px;
      }
      .custom-select{
        margin-top: 3%;
        margin-left: 4%;
      }
      
      // .custom-select .el-input__inner {
      //   background-color: transparent !important;
      //   color: white !important;
      //   border-color: lightgreen !important;
      // }
   
    }
    .titleDiv {
      text-align: center;
      width: 40%;
      .title {
        font-size: 32px;
        color: #36f2ff;
        letter-spacing: 4px;
        line-height: 54px;
      }
    }
    .adminDiv {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .shezhiImg {
        margin-left: 18%;
        height: 28px;
        margin-top: 19px;
        cursor: pointer;
      }
      .time {
        margin-left: 22%;
        line-height: 64px;
        color: #aae1ff;
        font-size: 18px;
      }
      .adminImg {
        margin-left: 18%;
        height: 38px;
        margin-top: 13px;
      }
      div {
        line-height: 60px;
        margin-right: 20px;
        .loginname {
          color: #aae1ff;
          font-size: 20px !important;
          cursor: pointer;
        }
      }
    }
  }

  .content {
    height: calc(100vh - 60px);
    display: flex;
    justify-content: space-between;
    .leftCont {
      width: 20%;
      height: calc(100% - 340px);
      margin-left: 1%;
      background-color: rgba(4, 45, 66, 0.6);
      padding: 10px;
      .leftContTop {
        width: 100%;
        height: 20%;
        .aqsccont {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          .aqsc {
            width: 48%;
            height: 90%;
            background: url(../assets/images/历年合同.png) no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            .aqscP1 {
              font-weight: 555;
              margin-top: 5%;
            }
            .aqscP2{
              margin-top: 2%;font-weight: bolder;color: #47ffdf;font-size: 25px;
            }
            span {
              color: #47ffdf;
              font-size: 15px;
              margin-left: 10%;
            }
          }
          .aqsc_zl {
            margin-top: 1%;
            width: 100%;
            height: 85%;
            background: url(../assets/images/project1.png) no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            .aqsc_zlP1{
              text-align: left;
              font-weight: 555;
              margin-top: 15%;
              margin-left: 60%;
              font-size: 25px;
            }
            .aqsc_zlP2{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 15%;
              font-size: 25px;
            }.aqsc_zlP3{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 45%;
              font-size: 25px;
            }
            .aqsc_zldiv{
              float: left;
              width: 125px;
            }
            span {
              color: #47ffdf;
              font-size: 15px;
              margin-left: 3%;
            }
          }
          .aqsc_zl1 {
            margin-top: 1%;
            width: 100%;
            height: 85%;
            background: url(../assets/images/project2.png) no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            .aqsc_zlP1{
              text-align: left;
              font-weight: 555;
              margin-top: 15%;
              margin-left: 65%;
              font-size: 25px;
            }
            .aqsc_zlP2{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 15%;
              font-size: 25px;
            }.aqsc_zlP3{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 45%;
              font-size: 25px;
            }
            .aqsc_zldiv{
              float: left;
              width: 125px;
            }
            span {
              color: #47ffdf;
              font-size: 15px;
              margin-left: 3%;
            }
          }
          .aqsc_zl2 {
            margin-top: 1%;
            width: 100%;
            height: 85%;
            background: url(../assets/images/project3.png) no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            .aqsc_zlP1{
              text-align: left;
              font-weight: 555;
              margin-top: 15%;
              margin-left: 65%;
              font-size: 25px;
            }
            .aqsc_zlP2{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 15%;
              font-size: 25px;
            }.aqsc_zlP3{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 45%;
              font-size: 25px;
            }
            .aqsc_zldiv{
              float: left;
              width: 125px;
            }
            span {
              color: #47ffdf;
              font-size: 15px;
              margin-left: 3%;
            }
          }
          .aqsc_zl3 {
            margin-top: 1%;
            width: 100%;
            height: 85%;
            background: url(../assets/images/project4.png) no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            .aqsc_zlP1{
              text-align: left;
              font-weight: 555;
              margin-top: 15%;
              margin-left: 65%;
              font-size: 25px;
            }
            .aqsc_zlP2{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 15%;
              font-size: 25px;
            }.aqsc_zlP3{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 45%;
              font-size: 25px;
            }
            .aqsc_zldiv{
              float: left;
              width: 125px;
            }
            span {
              color: #47ffdf;
              font-size: 15px;
              margin-left: 3%;
            }
          }.aqsc_zl4 {
            margin-top: 1%;
            width: 100%;
            height: 85%;
            background: url(../assets/images/project5.png) no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            .aqsc_zlP1{
              text-align: left;
              font-weight: 555;
              margin-top: 15%;
              margin-left: 65%;
              font-size: 25px;
            }
            .aqsc_zlP2{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 15%;
              font-size: 25px;
            }.aqsc_zlP3{
              text-align: left;
              font-weight: 555;
              margin-top: 5%;
              margin-left: 45%;
              font-size: 25px;
            }
            .aqsc_zldiv{
              float: left;
              width: 125px;
            }
            span {
              color: #47ffdf;
              font-size: 15px;
              margin-left: 3%;
            }
          }
          .aqsc2 {
            width: 100%;
            height: 30%;
            background: url(../assets/images/安全生产框长.png) no-repeat;
            background-size: 100% 100%;
            text-align: left;
            display: flex;
            img {
              margin-left: 4%;
              margin-top: 4%;
              height: 60%;
            }
            div {
              margin-top: 2%;
              margin-left: 4%;
              line-height: 28px;
              p {
                text-shadow: 0 1px #37b6ff;
              }
            }
          }
        }
      }
      .leftContCen {
        height: 26%;
        .zhiliang {
          display: flex;
          justify-content: space-between;
        }
        .zltj {
          width: 33%;
          margin-top: 1%;
          height: 90%;
          text-align: center;
          background: url(../assets/images/合格框.png) no-repeat;
          background-size: 100% 100%;
          .zltjP {
            font-size: 15px;
          }
          .zltjP1 {
            color: #2cffb1;
            margin-top: 3px;
            font-size: 22px;
          }
          .zltjP2 {
            color: #1cfbff;
            margin-top: 3px;
            font-size: 22px;
          }
          .zltjP3 {
            color: #7a84e4;
            margin-top: 3px;
            font-size: 22px;
          }
        }
      }
      .leftContBot {
        width: 100%;
        height: 40%;
        margin-top: 1%;
      }
    }
    .centerCont {
      margin-top: 30px;
      width: 50%;
      height: calc(100% - 340px);
      position: relative;
      .protitle {
        position: absolute;
        width: 100%;
        font-size: 22px;
        font-style: italic;
        letter-spacing: 4px;
        font-weight: 455;
        color: #fff;
        text-shadow: 0px 2px #73cbfd;
        margin-top: -10px;
        z-index: 999999999999;
        background: url('../assets/images/5548.png') no-repeat;
        background-size: 100% 100%;
        span{
          display: block;
          width: 68%;
          margin-left: 80px;
        }
      }
      .contentCenter{
        border: 0.005208rem solid #E1E9E8;
        border-radius: 0.078125rem;
        margin-top: 10px;
        width: 100%;
        height: 92%;
        #map{
        position: absolute;
        width: 950px;
        height: 460px;
        padding: 5px;
        z-index: 999999;
    }
      }
      .jkList {
        width: 80px;
        height: 500px;
        max-height: 500px;
        overflow-y: auto;
        position: absolute;
        right: 2px;
        z-index: 999999999999;
        .jiankong {
          background: url(../assets/images/dizuo.png) no-repeat;
          background-position-y: bottom;
          text-align: center;
          height: 80px;
          img {
            width: 80px;
            height: 75px;
            cursor: pointer;
          }
          p {
            font-size: 14px;
            margin-top: -20px;
          }
        }
      }
      .centerContTop {
        margin-top: 4%;
        height: 58%;
        width: 100%;
        .centerContTopTop{
          height: 100%;
          .boxcont{
            width: 98%;
            background: url('../assets/images/processkuang.png') no-repeat;
            background-size: 100% 100%;
            padding: 10px;
          }
        }
      }
      .centerContBot {
        padding: 10px 20px;
        height: 34%;
        background: url('../assets/images/jiankongkuang.png') no-repeat;
        background-size: 100% 100%;
        .boxcont{
          display: flex;
          justify-content: space-between;
          .centerContBotLeft{
            width: 35%;
            .jiankong1{
              background: url('../assets/images/监控1.png') no-repeat;
              background-size: 100% 100%;
              width: 100%;
              height: 70px;
              margin-top: 5px;
              margin-bottom: 5px;
              .p1{
                margin-left: 104px;
                padding-top: 4px;
                color: #fff;
                font-size: 16px;
                text-shadow: 0 2px #73cbfd;
                letter-spacing: 1px;
              }
              .p2{
                margin-left: 104px;
                padding-top: 6px;
                font-size: 16px;
                color:rgba(255, 255, 255, 0.8);
                letter-spacing: 1px;
              }
            }
          }
          .centerContBotRight{
            width: 60%;
            background: url('../assets/images/监控2.png') no-repeat;
            background-size: 100% 100%;
            text-align: center;
            margin-top: -20px;
            .centerContBotRighttitle{
              margin-left: 34%;
              margin-top: -15px;
                width: 30%;
                background: url('../assets/images/监控列表.png') no-repeat;
                background-size: 100% 100%;
                p{
              font-size: 20px;
              text-shadow: 0 2px #73cbfd;
                }
            }           
          }
        }
      }
    }
    .bottomCont{
        position: absolute;
        margin-top: 28%;
        margin-left: 1%;
        width: 97%;
        height: 270px;
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 20px;
        padding-left: 20px;
        background-color: rgba(4, 45, 66, 0.6);
        .centerContTop {
        height: 100%;
        width: 100%;
        .centerContTopTop{
          height: 100%;
          .boxcont{
            width: 98%;
            height: 90%;
            background: url('../assets/images/processkuang.png') no-repeat;
            background-size: 100% 100%;
            padding: 10px;
          }
        }
      }
     
      }
    .rightCont {
      margin-top: 30px;
      width: 20%;
      height: calc(100% - 375px);
      margin-right: 1%;
      background-color: rgba(4, 45, 66, 0.6);
      padding: 10px;
      .rightContTop{
        height: 34%;
        .boxcont{
          .htfx{
            width: 100%;
            height: 180%;
            background: url('../assets/images/htlx.png') no-repeat;
            background-size: 100% 100%;
            text-align: center;
            .htfxfb{
              height: 85px;
              .htfxfbh{
                float: left;
                width: 150px;
                .htfxfbp{
                  font-weight: 555;
                  margin-top: 3%;
                  margin-left: 45%;
                  font-size: 25px;
                  color: #47ffdf;
                }
              }
              .htfxfbp{
                float: left;
                width: 150px;
              }
            }
          }
          .htqd{
            width: 100%;
            height: 143%;
            background: url('../assets/images/qdhte.png') no-repeat;
            background-size: 100% 100%;
            text-align: center;
            margin-top: 5px;
            .htqd1{
              padding-top: 60px;
              text-align: left;
              padding-left: 40px;
              .htqd1div{
                margin-top: 4%;
                .htqd1p1{
                  font-weight: 555;
                  font-size: 20px;
                }
                span{
                  font-weight: 555;
                  margin-left: 10%;
                  font-size: 20px;
                  color: #47ffdf;
                }
              }
            }
            .htqd2{
              
            }
            .htqd3{
            float: left;
            width: 50%;
              .htqd1div{
                margin-top: 4%;
                .htqd1p1{
                  font-weight: 555;
                  font-size: 20px;
                }
                span{
                  font-weight: 555;
                  margin-left: 10%;
                  font-size: 20px;
                  color: #47ffdf;
                }
              }
              #myChart1{
                  width: 200px;
                  height: 150px;
              }
              #myChart2{
                  width: 200px;
                  height: 150px;
              }
            }
          }
          ul{
            list-style: none;
          }
          ul li {
            height: 27px;
           
            margin-left: 20px;
          }
          
          ul li span {
            line-height: 27px;
            width: 350px;
            margin-left: -6px;
            font-size: 14px;
            overflow: hidden; /*超出部分隐藏*/
            text-overflow: ellipsis; /*超出部分省略号表示*/
            white-space: nowrap; /*强制单行显示*/
            display: inline-block; /*转换为行内块元素*/
          }
          ul li::marker {
            color: rgba(22, 214, 220);
            text-shadow: 0 5px #37b6ff;
            font-size: 18px;
            line-height: 7px;
            display: inline-block; 
          }
        }
      }

      .rightContCen {
        height: 25%;
        .dashiji {
          width: 94%;
          height: 78%;
          padding: 2%;
          border: 4px solid rgba(28, 175, 248, 0.2);

          /deep/ .el-carousel {
            width: 100%;
            height: 100%;
          }
          /deep/ .el-carousel__container {
            height: 100%;
          }
          /deep/ .el-carousel__indicator--horizontal {
            padding-top: 0;
            padding-bottom: 0;
          }
          /deep/ .el-carousel__arrow--left {
            left: 0;
          }
          /deep/ .el-carousel__arrow--right {
            right: 0;
          }
          /deep/ .el-carousel__arrow {
            background-color: rgba(31, 55, 111, 0.3);
          }
          .carcont {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
          }
          .dashijiimg {
            width: 38%;
            height: 120px;
            margin-top: 5px;
            border: 1px solid rgb(125, 204, 243);
            background: url(../assets/images/dashiji.jpg) no-repeat;
            background-size: 100% 100%;
          }
          .dashijicont {
            font-size: 16px !important;
            width: 60%;
            height: 100%;
            tr {
              height: 25px;
            }
            .yuandian {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              margin-top: 3%;
            }
            /deep/ .el-button--mini {
              padding: 5px 8px !important;
            }
            .xiugai {
              background-color: #01384d !important;
              color: #fff !important;
              border: 1px solid rgba(28, 175, 248, 0.2) !important;
            }
            .shanchu {
              background-color: #044c9d !important;
              color: #fff !important;
              border: 1px solid rgba(28, 175, 248, 0.2) !important;
            }
          }
        }
      }
      
      .rightContBot {
        height: 34%;
        margin-top: 6%;
             .boxcont{
              background: transparent;
             }
      }
    }
    .box {
      width: 100%;

      .boxheader {
        width: 100%;
        height: 34px;
        background: url(../assets/images/5548.png) no-repeat;
        span {
          margin-left: 35px;
          line-height: 35px;
          font-style: italic;
          font-weight: bolder;
          color: #fff;
          text-shadow: 0px 2px #37b6ff;
          letter-spacing: 3px;
        }
      }
      .boxcont {
        width: 100%;
        height: 85%;
        margin-top: 10px;
      }
    }
  }
}
.zongti {
  position: absolute;
  left: 20px;
  top: 55px;
  background-color: #01384d !important;
  color: #fff !important;
  border: 1px solid rgba(28, 175, 248, 0.2) !important;
}
.shouye {
  position: absolute;
  right: 110px;
  top: 55px;
  background-color: #01384d !important;
  color: #fff !important;
  border: 1px solid rgba(28, 175, 248, 0.2) !important;
}
.shouye1 {
  position: absolute;
  right: 20px;
  top: 55px;
  background-color: #01384d !important;
  color: #fff !important;
  border: 1px solid rgba(28, 175, 248, 0.2) !important;
}
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/deep/ .el-table__body-wrapper {
  background-color: transparent !important;
  overflow-y: auto;
}
/deep/ .el-table__header-wrapper tr,
/deep/ .el-table th {
  background-color: transparent !important;
  color: #fff;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 0 !important;
}
/deep/ .el-table__header-wrapper .el-table__cell {
  border-bottom: 0 !important;
}
/deep/ .el-table .el-table__cell {
  padding: 4px 0;
}
/deep/ .el-table__header-wrapper .cell {
  text-align: center !important;
}
.jinduDiv {
  float: left;
  margin-left: 2%;
  width: 90%;
  height: 15px;
  border-radius: 20px;
  margin-top: 4px;
  border: 2px solid #1296df;
  overflow: hidden;
  .jinduNum {
    border-radius: 20px;
    height: 100%;
    background-image: linear-gradient(to right, #2b89e0, #00deff);
  }
}
/deep/ .shallow-row {
  // background-color: #024962 !important;
  background: transparent !important;
  color: rgba(22, 214, 220, 0.9) !important;
}
/deep/ .deep-row {
  // background-color: #02415b !important;
  background: transparent !important;
  // color: #16d6dc !important;
  color: rgba(22, 215, 222, 0.9) !important;
}
/deep/ .tou-row {
  background-color: transparent !important;
  color: #fff !important;
}
/deep/ .el-table__body tr:hover > td {
  background-color: #02283a59   !important;
}

//里程碑
.time-line {
  width: 390px;
  margin: 10px auto;
  margin-top: 0;
  position: relative;
}
.time-line .left-btn,
.time-line .right-btn {
  z-index: 99999;
  width: 40px;
  height: 30px;
  background: url("../assets/img/process/arrow.png") no-repeat;
  position: absolute;
  margin-top: 5px;
  cursor: pointer;
}
.time-line .left-btn {
  left: -10px;
  top: 40%;
}
.time-line .right-btn {
  right: 0;
  top: 40%;
  transform: rotate(180deg);
}
.time-line .right-btn:hover {
  background: url("../assets/img/process/arrow-hover.png") no-repeat;
  transform: rotate(180deg);
}
.time-line .left-btn:hover {
  background: url("../assets/img/process/arrow-hover.png") no-repeat;
}
.time-line .time-box {
  width: 360px;
  margin: 0 auto;
  margin-left: 20px;
  overflow: hidden;
}
.time-line .time-box ul {
  list-style: none;
  overflow: hidden;
  position: relative;
  height: 220px;
  width: 100rem;
  padding-top: 10px;
  transition: all 0.5s linear;
}
.time-line .time-box ul:after {
  content: "";
  background: #d2d2d2;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
}
.time-line .time-box li {
  width: 140px;
  float: left;
  box-sizing: border-box;
  border: 1px solid #d2d2d2;
  position: relative;
  cursor: pointer;
  height: 90px;
  margin-left: 20px;
}
.time-line .time-box li:nth-child(2n) {
  margin-top: 130px;
  margin-left: 40px;
}
.time-line .time-box li:before {
  content: "";
  border-left: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  position: absolute;
  width: 16px;
  height: 16px;
  background: #fff;
  bottom: -9px;
  transform: rotate(-45deg);
  left: 50%;
  margin-left: -8px;
}
.time-line .time-box li:after {
  content: "";
  width: 15px;
  height: 15px;
  background: url("../assets/img/process/point.png") no-repeat;
  position: absolute;
  left: 50%;
  margin-left: -7px;
  bottom: -33px;
  z-index: 1;
}
.time-line .time-box li:nth-child(2n):before {
  bottom: auto;
  top: -10px;
  transform: rotate(-225deg);
}
.time-line .time-box li:nth-child(2n):after {
  bottom: auto;
  top: -33px;
}
.time-line .time-box li:hover,
.time-line .time-box li.active {
  color: #fff;
  border-color: #1b96ff;
}
.time-line .time-box li:hover:before,
.time-line .time-box li.active:before {
  border-color: #1b96ff;
  background: #1b96ff;
}
.time-line .time-box li:hover:after,
.time-line .time-box li.active:after {
  background: url("../assets/img/process/point-hover.png") no-repeat;
}
.lcbdiv2 {
  position: absolute;
  margin-top: -130px;
  width: 140px;
  text-align: center;
  p {
    word-wrap: break-word;
  }
}
.lcbdiv1 {
  position: absolute;
  margin-top: 130px;
  width: 140px;
  text-align: center;
  p {
    word-wrap: break-word;
  }
}
/deep/ .el-descriptions__body{
  background: transparent !important;
}
/deep/ .el-descriptions-item__label{
    color: #fff;
    font-size: 15px;
    font-weight: 777 !important;
    background: transparent !important;
}
/deep/ .el-descriptions-item__content{
    font-size: 13px;
    color: rgb(25, 234, 239);
}
   
:deep(.el-input__inner) { /* Vue 3 样式穿透 */
  background-color: transparent;
  border: 1px solid #36f2ff;
  color: #fff;
}

:deep(.el-select-dropdown) { /* Vue 3 样式穿透 */
  background-color:  #282828 !important;
}

:deep(.el-select-dropdown__item.hover, .el-select-dropdown__item:hover) { /* Vue 3 样式穿透 */
  background-color: #013a47 !important;
}

:deep(.el-select-dropdown__item) { /* Vue 3 样式穿透 */
  color: #fff !important;
}

::v-deep .el-table__expand-icon {
    color: #fff;
  }
</style>

<style>
.blackLayer{
  
  filter:grayscale(100%) sepia(18%) invert(100%) saturate(350%) ;
}

/* .el-table__expand-icon{
        color: #fff;
      } */
.custom-table .el-table__body-wrapper td {
  text-align: left;
}
.el-badge__content.is-fixed {
                  position: absolute;
                  top: 10px;
                  right: -1px;
                  -webkit-transform: translateY(-50%) translateX(100%);
                  transform: translateY(-50%) translateX(100%);
              }
.el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {

    border: 0.005208rem solid #EBEEF5;
}

.diagTable th{
  color: #000  !important;
}

.diagTable tr:hover{
  background-color: #02283a59  !important;
}

.diagTable__header-wrapper tr, .diagTable th{
  color: #000  !important;
}

.diagTable {
  height: 400px !important;
}
</style>