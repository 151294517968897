<template>
    <div class="bg">
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
            <el-card class="box-card" >
                <!-- <div slot="header" class="btnheader" >
                  <el-button  icon="el-icon-circle-plus-outline" @click="beforeuploadModel">新增拨款表</el-button>
                </div> -->
                <div class="serverTable">
                    <el-table
                            :data="tableDataHt"
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                          <el-table-column type="expand">
                            <template #default="props">
                                <el-table
                                :data="props.row.children"
                                border
                                style="width: 100%"
                                :header-cell-style="{background:'#e5ebf4',color:'black'}"
                                >
                                <el-table-column 
                                        type="index"
                                        label="序号"
                                        width="50"
                                >
                                </el-table-column>
                                <el-table-column prop="recipientUnit"  label="收款单位"  width="230">  
                                </el-table-column>
                                <el-table-column prop="recipientTime"  label="付款日期"  width="200" >  
                                </el-table-column>
                                <el-table-column prop="monetary"  label="付款金额"  width="150" >  
                                </el-table-column>
                                <el-table-column prop="remarks"  label="备注（预付款/工程款 ）"  width="150" >  
                                </el-table-column>
                                <el-table-column prop="attacheIds"  label="附件"  width="320" >  
                                    <template v-slot="scope">
                                        <a href="#" @click="yulan(scope.row.files[0].filePath)" v-if="scope.row.files.length>0"> {{scope.row.files[0].fileName}}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        label="操作"
                                        align="center"
                                >
                                    <template v-slot="scope">
                                        <el-button  type="primary" @click="downFile(scope.row.files[0].filePath,scope.row.files[0].fileName)"  size="mini">下载</el-button>
                                        <el-button  type="primary" @click="editInfo(scope.row)"  size="mini">修改</el-button>
                                        <el-button  type="primary" @click="shanchu(scope.row)"  size="mini" >删除</el-button >
                                    </template>
                                </el-table-column>
                                </el-table>
                            </template>
                            </el-table-column>
                          <el-table-column prop="contractorName"  label="合同名称">  
                        </el-table-column>
                        <el-table-column prop="contractorMonetary"  label="合同金额">  
                        </el-table-column>
                        <el-table-column prop="obligations"  label="甲方单位" >  
                        </el-table-column>
                        <el-table-column prop="secondParty"  label="乙方单位" >  
                        </el-table-column>
                        <el-table-column prop="signatureTime"  label="签订时间">  
                        </el-table-column>
                        <el-table-column prop="duration" label="状态/项目周期" width="180">
                            <template #default="{ row }">
                            <el-tag v-if="row.type === 'contractor'" size="small" :type="row.status === '已付款' ? 'success' : 'info'">
                                {{ row.status }}
                            </el-tag>
                            <span v-else>{{ row.duration || '--' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="attacheIds"  label="附件" >  
                            <template v-slot="scope">
                                <a href="#" @click="yulan(scope.row.files[0].filePath)" v-if="scope.row.files.length>0"> {{scope.row.files[0].fileName}}</a>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="beforeuploadModel(scope.row)"  size="mini">新增付款信息</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
   
        <el-dialog :visible.sync="writeForm" :title="diatitle" width="50%" >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" >
                <el-form-item label="收款单位" prop="recipientUnit">
                    <el-input  v-model="addForm.recipientUnit"></el-input>
                </el-form-item>
                <el-form-item label="付款日期" prop="recipientTime">
                    <el-date-picker
                        v-model="addForm.recipientTime"
                        align="right"
                        value-format="yyyy-MM-dd HH:mm:ss" 
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="付款金额" prop="monetary">
                    <el-input  v-model="addForm.monetary"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input  v-model="addForm.remarks"></el-input>
                </el-form-item>
                <el-form-item label="附件" prop="tenant" v-show="diatitle=='新增拨款信息'"> 
                    <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechange"
                                  :on-remove="handleRemove"
                                  :file-list="fileList"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div>
                             </el-upload>
                     <el-progress :percentage="percentage" v-if="percentage > 0"></el-progress>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm = false">关 闭</el-button>
                                  <el-button @click="uploadModel" :loading="loadbut">{{loadbutext}}</el-button>
                              </span>
                  </template>
        </el-dialog>
    </div>
  </template>
  
  <script>

  let inputel=[]
    export default {
        name: "commonTable",
        data(){
            return{
                tableDataHt:[],
                loadbut:false,
                loadbutext:"保 存",
                diatitle:'新增拨款信息',
                tableData:[],
                generatorBusinessList:[],
                menuTable:[],
                isZhank: false,
                dialogVisible: false,
                writeForm:false,
                fileList:[],
                menuId:window.sessionStorage.getItem('menuId'),
                addForm:{
                    contractId:'',
                    recipientUnit:'',
                    recipientTime:'',
                    monetary:'',
                    remarks:'',
                    projectId:this.$root.proId,
                    attacheIds:[]
                },
                progress: 0,
                percentage:0,
                pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
                }
            }
        },
        created(){
            this.getLeftMenu()
            this.getProAppHt()
            this.getProApp()
            const load = this.$loading({
            lock:true,
            text:'Loading',
            spinner:'el-icon-loading',
            background:'rgba(0,0,0,0.7)'
            });
            load.close();
        },
        methods:{
            async getProAppHt(){
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-disbursements/findAllInfoByProjectId?projectId='+this.$root.proId)
              var tableDataHt=[]
                tableDataHt=res.data
                this.tableDataHt = this.transformToTreeData(tableDataHt);
                console.log(this.tableDataHt)
            },
            transformToTreeData(apiData) {
                return apiData.map(item => {
                   
                    if(item.projectDisbursementsVOList?.length > 0){
                        const contractorNode = {
                        id: item.projectSubContractorVO?.id || `contractor-${Math.random().toString(36).substr(2, 8)}`,
                        type: 'contractor',
                        contractorName: item.projectSubContractorVO?.contractorName,
                        contractorMonetary: item.projectSubContractorVO?.contractorMonetary || 0,
                        contractorSerial: item.projectSubContractorVO?.contractorSerial || '',
                        signatureTime: item.projectSubContractorVO?.signatureTime || '',
                        projectDuration: item.projectSubContractorVO?.projectDuration || '',
                        status: item._disbursed ? '已付款' : '未付款',
                        obligations: item.projectSubContractorVO?.obligations || '',
                        secondParty: item.projectSubContractorVO?.secondParty || '',
                        files: item.projectSubContractorVO?.files || [],
                        hasChildren: item.projectDisbursementsVOList?.length > 0,
                        children: []
                        };
                        if (item.projectDisbursementsVOList?.length) {
                        contractorNode.children = item.projectDisbursementsVOList.map(payment => ({
                            id: payment.id || `payment-${Math.random().toString(36).substr(2, 8)}`,
                            type: 'payment',
                            recipientUnit: payment.recipientUnit || '',
                            recipientTime: payment.recipientTime || '',
                            monetary: payment.monetary || 0,
                            remarks: payment.remarks || '',
                            files: payment.files || [],
                            parentId: contractorNode.id,
                        }));
                        }

                        return contractorNode;
                    }
                });
                },
            async getProApp(){
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-disbursements/findAllByProjectId?projectId='+this.$root.proId)
              this.tableData=res.data
            },
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=64')
                this.menuTable=[]
                for(let i in res.data){
                  if(res.data[i].belongOrg==1){
                    this.menuTable.push(res.data[i])
                  }
                }
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            filechange(file,List){
            this.fileList=List
            },
            handleRemove(file, List) {
                    this.fileList=List
              },
            async shanchu (row)  {
              const confirmResult = await this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/enterprises/project-disbursements/delete/'+row.id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProAppHt();
             },
            async yulan(path){
                window.open('http://8.130.180.224:9000/epc/'+path)
             },
            async downFile(path,name){
                console.log(name)
                  const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
                 if(res){
                      var elink = document.createElement('a');
                      elink.download = name;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            beforeuploadModel(row){
                console.log(row)
                this.fileList=[]
                this.addForm={
                    recipientUnit:'',
                    recipientTime:'',
                    monetary:'',
                    remarks:'',
                    projectId:this.$root.proId,
                    attacheIds:[],
                    contractId:row.id
                }
                this.diatitle='新增拨款信息'
                this.writeForm=true
            },
            editInfo(row){
                this.diatitle='修改拨款信息'
                this.writeForm=true
                this.fileList=[]
                this.addForm=JSON.parse(JSON.stringify(row)) 
            },
            isPDF(file) {
            return file.name.toLowerCase().endsWith('.pdf');
        },
           async uploadModel(){
           
            var formData = new FormData();
            for(let i in this.fileList){
                if(this.isPDF(this.fileList[i])){
                    formData.append("files", this.fileList[i].raw);
                 }else{
                    this.$message.error("文件格式为PDF");
                    return
                 }
            }
            this.loadbut = true
            this.loadbutext = "提交中..."
         
            const config = {
                    onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    }
                    }
                };
            const { data: res2 } = await this.$http1.post('/v1.0/enterprises/project-file/upload/AttacheInfoBatch', formData,config)
            if(res2.businessCode==1000){
                this.addForm.attacheIds=[]
                for(let j in res2.data){
                    this.addForm.attacheIds.push(res2.data[j].attacheId)
                }
            }else{
                this.addForm.attacheIds=[]
            }
            if(this.diatitle=='新增拨款信息'){
                const { data: res } = await this.$http1.post('/v1.0/enterprises/project-disbursements/save', this.addForm)
                if(res.businessCode==4000){
                    this.$message.success("提交成功");
                    this.getProAppHt()
                    this.percentage = 0
                    this.loadbut = false
                this.loadbutext = "保 存"
                    this.writeForm = false;
                }else{
                this.$message.error("提交失败！");
                }
            }else{
                const { data: res } = await this.$http1.put('/v1.0/enterprises/project-disbursements/update', this.addForm)
                if(res.businessCode==4300){
                    this.$message.success("修改成功");
                    this.getProApp()
                    this.writeForm = false;
                }else{
                this.$message.error("提交失败！");
                 }
            }
          }
        },
        watch: {
            $route(to, from) {
                this.getProApp()
                this.getLeftMenu()
            },
           
        },
        mounted() {
        },
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 92.6% !important;
   }
   .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
#asiderBar .el-menu-item{
  left: 2%;
  width: 96%;
  padding-left: 5px !important;
}
  </style>
  