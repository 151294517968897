<template>
    <div class="bg">
        <pro-left></pro-left>
        <div id="proInfo">
            <div  class="proleft">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>项目基本信息</span>
                        <el-button style="float: right; padding:  0" type="text" icon="el-icon-edit" @click="editpro" >编辑项目</el-button>
                    </div>
                    <div class="text item">
                        <div class="proImgDiv">
                            <el-carousel indicator-position="outside" >
                                <el-carousel-item v-for="item in imgsrcs" :key="item.id">
                                    <img v-if="item.fileType=='OTHER'" :src="'http://8.130.180.224:9000/epc/'+item.filePath">
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="proInfoDiv">
                           <el-form  label-width="110px">
                                <el-form-item label="项目名称">
                                    <el-input v-model="form.projectName" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="建设单位">
                                    <el-input v-model="hetongData.constructionUnit" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="实施单位">
                                    <el-input v-model="hetongData.implementingEntity" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="合同金额">
                                    <el-input v-model="hetongData.contractorMonetary" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="合同名称">
                                    <el-input v-model="hetongData.contractorName" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="保证金/保函">
                                    <el-input v-model="hetongData.performanceBond" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="签订时间">
                                    <el-input v-model="hetongData.signatureTime" :disabled="true"></el-input>
                                </el-form-item>
                                <el-form-item label="付款比例">
                                    <el-input v-model="hetongData.percentagePayment" :disabled="true"></el-input>
                                </el-form-item>
                            </el-form> 
                            <!-- <el-table
                            :data="tableData"
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                             >
                                <el-table-column 
                                        type="index"
                                        label="序号"
                                        width="50"
                                >
                                </el-table-column>
                                <el-table-column v-for="(item,index) in generatorBusinessList" :key="index" :prop="item.fieldName"  :label="item.showName"  width="150"
                                >
                                </el-table-column>
                                <el-table-column
                                        label="操作"
                                        width="250"
                                        align="center"
                                >
                                    <template v-slot="scope">
                                        <el-button  type="primary" @click="getInfo(scope.row)"  size="mini">详情</el-button>
                                        <el-button  type="primary" @click="getInfo3(scope.$index,scope.row)"  size="mini" >办理详情</el-button >
                                    </template>
                                </el-table-column>
                            </el-table> -->
                        </div>
                    </div>
                </el-card>

            </div>
             <div class="proright">
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item title="项目详情" name="1">
                        <el-card class="box-card">
                        <div class="text item">
                         <el-input  type="textarea" :rows="9"  v-model="form.projectDetails"></el-input>
                     </div>
                 </el-card>
                    </el-collapse-item>
                    <el-collapse-item title="工程量清单" name="2">
                      
                        <el-card class="box-card2">
                            <el-button   @click="writeForm = true;fileListqingdan=[]" >新增工程量清单</el-button>
                        <div>
                            <el-table
                            class="text_item"
                            :data="qingdanData"
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                            <el-table-column prop="fileName"  label="附件名"  width="350">
                                <template v-slot="scope">
                                    <span class="filename" @click="yulan(scope.row.filePath,scope.row.fileName)">{{scope.row.fileName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template v-slot="scope">
                                    <el-button  type="primary" @click="shanchu(scope.row)"  size="mini" >删除</el-button >
                                </template>
                            </el-table-column>
                            </el-table>

                           
                        </div>
                        </el-card>
                    </el-collapse-item>
                    <el-collapse-item title="项目划分" name="3">
                        <el-card class="box-card2">
                            <div class="text item">
                    <el-table
                            :data="huafenData"
                            height="100%"
                            :span-method="arraySpanMethod"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                        <el-table-column prop="unitConstruction"  label="单位工程"  width="120">  
                        </el-table-column>
                        <el-table-column prop="substructure"  label="分部工程"  width="120" >  
                        </el-table-column>
                        <el-table-column prop="subItem"  label="分项工程"  width="120" >  
                            <template v-slot="scope">
                              {{scope.row.subItem.split('-')[1]}}
                             </template>
                        </el-table-column>
                        <el-table-column prop="inspectionLot"  label="检验批名称"  width="260" >  
                        </el-table-column>
                        <el-table-column prop="files"  label="附件"  width="200" >  
                            <template v-slot="scope">
                                <a href="#" @click="yulan(scope.row.filePath)" v-if="scope.row.files.length>=1">  {{scope.row.files[0].fileName}}</a>
                                <span v-else> 暂无附件</span>
                             </template>
                        </el-table-column>
                        <el-table-column prop="eligibility"  label="是否合格" sortable  width="100" >  
                            <template v-slot="scope">
                                <el-tag v-if="scope.row.eligibility==0">未验收</el-tag>
                                <el-tag  v-else-if="scope.row.eligibility==1" type="success">合格</el-tag>
                                <el-tag  v-else-if="scope.row.eligibility==2" type="danger">不合格</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                            </div>
                        </el-card>
                    </el-collapse-item>
                    <!-- <el-collapse-item title="组织架构" name="4">
                     <div style="height:500px"   ref="myPage">
                         <RelationGraph
                                 ref="seeksRelationGraph"
                                 :options="graphOptions"
                                 :on-node-click="onNodeClick"
                                 :on-line-click="onLineClick"
                                 :on-node-expand="onNodeExpand">
                             <div slot="node" slot-scope="{node}" @mouseover="showNodeTips(node, $event)" @mouseout="hideNodeTips(node, $event)" style="width: 100%;height: 100%;display: flex; align-items: center;justify-content: center;">
                                 {{ node.text }}
                             </div>
                         </RelationGraph>
                     </div>
                     <div v-if="isShowNodeTipsPanel" :style="{left: nodeMenuPanelPosition.x + 'px', top: nodeMenuPanelPosition.y + 'px' }" style="z-index: 999;padding:10px;background-color: #ffffff;border:#eeeeee solid 1px;box-shadow: 0px 0px 8px #cccccc;position: absolute;">
                         <div class="c-node-menu-item">姓名：{{currentNode.data.name}}</div>
                         <div class="c-node-menu-item">职务:{{currentNode.data.post}}</div>
                         <div class="c-node-menu-item">电话:{{currentNode.data.telephone}}</div>
                         <div class="c-node-menu-item">邮箱:{{currentNode.data.email}}</div>
                     </div>
                    </el-collapse-item> -->
                    <el-collapse-item title="项目进度" name="5">
                      
                      <el-card class="box-card2">
                          <div class="text item">
                              <el-table
                          :data="progressData"
                          height="100%"
                          border
                          :header-cell-style="{background:'#e3ecfb',color:'black'}"
                        >
                        <el-table-column prop="progressName"  label="进度名称"  width="350">
                        </el-table-column>
                        <el-table-column prop="value" :formatter="ChangeToPercentage" label="进度值"  width="350">
                        </el-table-column>
                  </el-table>
                          </div>
                      </el-card>
                  </el-collapse-item>
                </el-collapse>
             </div>
        </div>
        <el-dialog
                title="修改项目信息"
                :visible.sync="dialogVisibleEdit"
                :modal="false"
                width="45%"
                :close-on-click-modal="false"
                id="editdialog"
                center
                @close="editdialogClose"
        >   
            <el-form ref="editFormRef" :model="editForm" label-width="110px" :rules="editproInfoRules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目名称" prop="projectName">
                            <el-input v-model="editForm.projectName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="项目类型" prop="projectTypeId">
                            <el-select v-model="editForm.projectTypeId" clearable    style="width: 100%"  >
                                <el-option
                                        v-for="item in protypeData"
                                        :key="item.id"
                                        :label="item.typeName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目总投资(亿)" prop="totalInvestment">
                            <el-input type="number" v-model="editForm.totalInvestment"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同签订日期" prop="signContractDate">
                            <el-date-picker
                                    v-model="editForm.signContractDate"
                                    type="date"
                                    value-format="yyyy-MM-dd："
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目负责人" prop="principal">
                            <el-input v-model="editForm.principal"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="负责人电话" prop="principalTel">
                            <el-input v-model="editForm.principalTel"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="业主单位" prop="ownerUnit">
                            <el-input v-model="editForm.ownerUnit"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="总包单位" prop="epcUnit">
                            <el-input v-model="editForm.epcUnit"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目现状" prop="projectStatus">
                            <el-input   type="textarea" :rows="2" v-model="editForm.projectStatus"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目详情" prop="projectDetails">
                            <el-input  type="textarea" :rows="2"  v-model="editForm.projectDetails"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="建筑高度(米)" >
                            <el-input v-model="editForm.buildingHeight"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="建筑面积(m²)" >
                            <el-input v-model="editForm.buildingArea"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目地址" prop="projectAddress">
                            <el-input v-model="editForm.projectAddress"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="经度" prop="longitude">
                            <div style="display: flex;justify-content: space-between;">
                                <el-input type="number" v-model="editForm.longitude" style="width: 30%"></el-input>°
                                <el-input type="number" v-model="editForm.longitudeMinute" style="width: 30%"></el-input>′
                                <el-input type="number" v-model="editForm.longitudeSecond" style="width: 30%"></el-input>″
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="纬度" prop="latitude">
                            <div style="display: flex;justify-content: space-between;">
                                <el-input type="number" v-model="editForm.latitude"  style="width: 30%"></el-input>°
                                <el-input type="number" v-model="editForm.latitudeMinute"  style="width: 30%"></el-input>′
                                <el-input type="number" v-model="editForm.latitudeSecond"  style="width: 30%"></el-input>″
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="计划开始时间" prop="planStartDate">
                            <el-date-picker
                                    v-model="editForm.planStartDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="计划结束时间" prop="planEndDate">
                            <el-date-picker
                                    v-model="editForm.planEndDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="实际开始时间" prop="actualStartDate">
                            <el-date-picker
                                    v-model="editForm.actualStartDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="实际结束时间" prop="actualEndDate">
                            <el-date-picker
                                    v-model="editForm.actualEndDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <!--  <el-col :span="24">
                          <el-form-item label="项目照片">
                                  <el-image
                                          v-for="item in editForm.projectImagePath"
                                          style="width: 100px; height: 100px"
                                          :src=item
                                          :preview-src-list="editForm.projectImagePath">
                                  </el-image>
                          </el-form-item>
                      </el-col>-->
                    <el-col :span="24">
                        <el-form-item label="上传项目照片">
                            <el-upload
                                    action="#"
                                    list-type="picture-card"
                                    :on-preview="handlePreviewEdit"
                                    :on-change="filechangeEdit"
                                    :on-remove="handleRemoveEdit"
                                    :file-list="fileListEdit"
                                    :auto-upload="false">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible1">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                 <el-button round type="goon" size="medium" @click="dialogVisibleEdit = false">取 消</el-button>
                 <el-button round type="goon" size="medium"  @click="editProinfo" >确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
                title="人员信息详情"
                :visible.sync="dialogVisiblePerson"
                width="25%"
                :close-on-click-modal="false"
                center
        >
            <el-form  :model="personForm" label-width="80px" >
                <el-row>
                        <el-form-item label="姓名" >
                            <el-input v-model="personForm.name"></el-input>
                        </el-form-item>
                </el-row>
                <el-row>
                        <el-form-item label="职务">
                            <el-input v-model="personForm.post"></el-input>
                        </el-form-item>
                </el-row>
                <el-row>
                        <el-form-item label="所属部门" >
                            <el-input v-model="personForm.departmentName"></el-input>
                        </el-form-item>
                </el-row>
                <el-row>
                        <el-form-item label="所属单位" >
                            <el-input v-model="personForm.unitName"></el-input>
                        </el-form-item>
                </el-row>
                <el-row>
                        <el-form-item label="电话">
                            <el-input v-model="personForm.telephone"></el-input>
                        </el-form-item>
                </el-row>
                <el-row>
                        <el-form-item label="邮箱" >
                            <el-input v-model="personForm.email"></el-input>
                        </el-form-item>
                </el-row>

            </el-form>
        </el-dialog>
        <el-dialog title="部门人员" :visible.sync="dialogTableVisible">
            <el-table :data="gridData"     
            border
            :header-cell-style="{background:'#e3ecfb',color:'black'}">
                <el-table-column
                                    type="index"
                                    label="序号"
                                    width="60"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="teamName"
                                    label="成员名"
                                    width="200"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="duties"
                                    label="成员职务"
                                    width="200"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="phoneNumber"
                                    label="成员电话"
                                    width="150"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="tenant"
                                    label="成员信息"
                            >
                            </el-table-column>
            </el-table>
    </el-dialog>
    <el-dialog :visible.sync="writeForm" title="新增项目清单" width="50%" >
            <div class="fujian">
                              <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechange"
                                  :on-remove="handleRemove"
                                  :file-list="fileListqingdan"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择项目清单附件</el-button>
                              </el-upload>
                          </div>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm = false">关 闭</el-button>
                                  <el-button @click="uploadModel">上 传</el-button>
                              </span>
                  </template>
        </el-dialog>
    </div>
</template>

<script>
    import proLeft from './proInfoLeft'
    import RelationGraph from 'relation-graph'
    export default {
        name: "proInfo",
        data(){
            return{
                writeForm:false,
                fileListqingdan:[],
                form:{
                    projectName:'项目名',
                    projectAddress:'',
                    projectType:{
                        "typeName": "园区"
                    },
                    totalInvestment:'2.1202亿元',
                    buildingHeight:'4米',
                    buildingArea:'101483.68平方米',
                    epcUnit:'兵团设计院总包公司',
                    ownerUnit:'兵团设计院',
                    planStartDate:'2020-10-04',
                    planEndDate:'2021-09-24',
                    projectDetails:'',
                    id:'',
                },
                imgsrcs:[],
                dialogVisibleEdit:false,
                editForm:{
                },
                dialogTableVisible:false,
                dialogImageUrl: '',
                dialogVisible1: false,
                fileListEdit:[],
                fileListAdd:[],
                fileListDeleteID:[],
                protypeData:[],
                editproInfoRules:{
                    projectName:[{required:true,message:'请输入项目名称',trigger:'blur'}],
                    projectTypeId:[{required:true,message:'请选择项目类型',trigger:'blur'}],
                    totalInvestment:[{required:true,message:'项目总投资只能为数字',trigger:'blur'}],
                    signContractDate:[{required:true,message:'请输入合同签订日期',trigger:'blur'}],
                    principal:[{required:true,message:'请输入项目负责人',trigger:'blur'}],
                    principalTel:[{required:true,message:'请输入项目负责人电话',trigger:'blur'}],
                    ownerUnit:[{required:true,message:'请输入业主单位',trigger:'blur'}],
                    epcUnit:[{required:true,message:'请输入总包单位',trigger:'blur'}],
                    projectStatus:[{required:true,message:'请输入项目现状',trigger:'blur'}],
                    projectDetails:[{required:true,message:'请输入项目详情',trigger:'blur'}],
                    projectAddress:[{required:true,message:'请输入项目地址',trigger:'blur'}],
                    longitude:[{required:true,message:'请输入项目经度',trigger:'blur'}],
                    latitude:[{required:true,message:'请输入项目纬度',trigger:'blur'}],
                    planStartDate:[{required:true,message:'请输入计划开始时间',trigger:'blur'}],
                    planEndDate:[{required:true,message:'请输入计划结束时间',trigger:'blur'}],
                    actualStartDate:[{required:true,message:'请输入实际开始时间',trigger:'blur'}],
                    //actualEndDate:[{required:true,message:'请输入实际结束时间',trigger:'blur'}],
                },
                g_loading: true,
                demoname: '---',
                gridData:[],
                qingdanData:[],
                progressData:[],
                graphOptions: {
                    'backgrounImage': '',
                    'backgrounImageNoRepeat': true,
                    'layouts': [
                        {
                            'label': '中心',
                            'layoutName': 'tree',
                            'layoutClassName': 'seeks-layout-tree',
                            'defaultJunctionPoint': 'border',
                            'defaultNodeShape': 0,
                            'defaultLineShape': 1,
                            'centerOffset_x': 0,
                            'centerOffset_y': -85,
                            'min_per_width': '70',
                            'min_per_height': '250'
                        }
                    ],
                    "defaultExpandHolderPosition": "bottom",
                    'defaultLineShape': 4,
                    'defaultJunctionPoint': 'tb',
                    'defaultNodeShape': 1,
                    'defaultNodeWidth': '50',
                    'defaultNodeHeight': '100',
                    'defaultNodeBorderWidth': 0
                },
                danweiData:[],
                bumenData:[],
                lineData:[],
                personData:[],
                lineData2:[],
                __graph_json_data:'',
                isShowNodeTipsPanel: false,
                nodeMenuPanelPosition: { x: 0, y: 0 },
                currentNode: {
                    data:{
                        email:'',
                        departmentName:'',
                        name:'',
                        unitName:'',
                        post:'',
                        telephone:''
                    }
                },
                personForm:{
                    email:'',
                    departmentName:'',
                    name:'',
                    unitName:'',
                    post:'',
                    telephone:''
                },
                dialogVisiblePerson:false,
                generatorBusinessList:[],
                tableData:[],
                hetongData:{
                    constructionUnit:'',
                    implementingEntity:'',
                    contractorMonetary:'',
                    contractorName:'',
                    performanceBond:'',
                    signatureTime:'',
                    percentagePayment:'',
                },
                activeName:'1',
                huafenData:[],
            }
        },
        created(){
          this.getProinfo();
          this.getProType();
          this.getProApp();
          this.gethetong();
          this.getProHuafen()
          this.getZuzhi();
          this.getProgressInfo();
        },
        methods:{
            ChangeToPercentage(row, column){
                var data1 = (row[column.property])+"%"
                return data1
            },
            async getProApp(){
              const {data:res}= await this.$http9.get('/stencils/findStencilsFromByMenuId?menuId=12001')
                if(res.businessCode!='3000'){
                    this.tableData=[]
                    this.generatorBusinessList=[]
                    return this.$message.error('查询失败！'+res.message)
                }
                this.generatorBusinessList=[]
                for(let i in res.data.generatorBusinessList){
                    if(res.data.generatorBusinessList[i].showStatus==1){
                        this.generatorBusinessList.push(res.data.generatorBusinessList[i])
                    }
                }
                this.tableData=[]
                for(let i in res.data.objectMap){
                    this.tableData.push(res.data.objectMap[i].objectMap)
                }
            },
            async getProinfo(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findById?id='+this.$root.proId)
                if(res.businessCode!='3000'){
                 return this.$message.error('查询项目详情失败！'+res.message)
                }
                this.form=res.data;
                this.imgsrcs=[]
                for(let i in this.form.files){
                    if(this.form.files[i].fileType=='OTHER'){
                        this.imgsrcs.push(this.form.files[i])
                    }
                }
                // for(let i in this.form.files){
                //     const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+this.form.files[i].filePath)
                //     this.form.files[i].filePath=res2.data
                // }
                this.form.totalInvestment=this.form.totalInvestment+'亿元'
                this.form.buildingHeight=this.form.buildingHeight+'米'
                this.form.buildingArea=this.form.buildingArea+'平方米'
                //文件去重
                const map = new Map();
                const newArr = res.data.files.filter(v => !map.has(v.fileName) && map.set(v.fileName, v));
                this.qingdanData=newArr
                // console.log("加载的table数据=========",this.qingdanData)
            },
            async getProHuafen(){
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-qualitative/findAllByProjectId?projectId='+this.$root.proId)
              this.huafenData=res.data
            },
            async gethetong(){
                const {data:res}= await this.$http1.get('/v1.0/enterprises/project-contractor/findAllByProjectId?projectId='+this.$root.proId)
                if(res.data.length!=0){
                    this.hetongData=res.data[0]
                }
            },
            async getProgressInfo(){
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-progress/findInfoById?projectId='+this.$root.proId)
              this.progressData=res.data
            },
            async getProType(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAllType')
                if(res.businessCode!='3000'){
                    return 0
                }
                this.protypeData=res.data;
            },
            async editpro(){
                const {data:res}=await  this.$http1.get('/v1.0/project/basicInfo/findById?id='+this.form.id);
                if(res.businessCode!=3000){
                    return 0;
                }
                this.editForm=res.data;
               this.fileListEdit=[];
               for(let i in this.editForm.files){
                if(this.editForm.files[i].fileType=='OTHER'){
                    this.fileListEdit.push({id:this.editForm.files[i].id,url:'http://8.130.180.224:9000/epc/'+this.editForm.files[i].filePath})
                }
               }
               this.dialogVisibleEdit=true
            //    this.fileListEdit=[];
            //    for(let i in res.data.files){
            //        const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+res.data.files[i].filePath)
            //        this.fileListEdit.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:res2.data})
            //    }
               this.fileListAdd=[];
               this.fileListDeleteID=[]
            },
            async editProinfo(){
                var formData=new FormData();
                /*for(let x in this.filelistEdit){
                    formData.append("multipartFiles",this.filelistEdit[x].raw);
                }*/
                // formData.append("id",this.editForm.id);
                // formData.append("ownerUnit",this.editForm.ownerUnit);
                // formData.append("epcUnit",this.editForm.epcUnit);
                // formData.append("projectName",this.editForm.projectName);
                // formData.append("projectTypeId",this.editForm.projectTypeId);
                // formData.append("totalInvestment",this.editForm.totalInvestment);
                // formData.append("principal",this.editForm.principal);
                // formData.append("principalTel",this.editForm.principalTel);
                // formData.append("projectDetails",this.editForm.projectDetails);
                // formData.append("projectStatus",this.editForm.projectStatus);
                // formData.append("buildingHeight",this.editForm.buildingHeight);
                // formData.append("projectAddress",this.editForm.projectAddress);
                // formData.append("longitude",this.editForm.longitude);
                // formData.append("latitude",this.editForm.latitude);
                // formData.append("projectImagePath",this.editForm.projectImagePath);
                // formData.append("planStartDate",this.editForm.planStartDate);
                // formData.append("planEndDate",this.editForm.planEndDate);
                // formData.append("signContractDate",this.editForm.signContractDate);
                // formData.append("longitudeMinute",this.editForm.longitudeMinute);
                // formData.append("longitudeSecond",this.editForm.longitudeSecond);
                // formData.append("latitudeMinute",this.editForm.latitudeMinute);
                // formData.append("latitudeSecond",this.editForm.latitudeSecond);
                // if(this.editForm.actualStartDate==null){
                //     formData.append("actualStartDate",'');
                // }else {
                //     formData.append("actualStartDate",this.editForm.actualStartDate);
                // }
                // if(this.editForm.actualEndDate==null){
                //     formData.append("actualEndDate",'');
                // }else {
                //     formData.append("actualEndDate",this.editForm.actualEndDate);
                // }
                // if(this.editForm.buildingArea==null){
                //     formData.append("buildingArea",'');
                // }else {
                //     formData.append("buildingArea",this.editForm.buildingArea);
                // }
                
                const {data:res}= await this.$http1.put('/v1.0/project/basicInfo/update', this.editForm)
                if(res.businessCode!='4300'){
                    return this.$message.error('修改项目失败！'+res.message)
                }
                console.log(this.fileListDeleteID)
                for(let i in this.fileListDeleteID){
                    const {data:res2}= await this.$http1.delete('/v1.0/project/basicInfo/file/delete/'+this.fileListDeleteID[i])
                    if(res2.businessCode!='5000'){
                        return this.$message.error('删除项目文件失败！'+res2.message)
                    }
                }
                if(this.fileListAdd.length>0){
                    var formData2=new FormData();
                    for(let j in this.fileListAdd){
                        formData2.append("multipartFiles",this.fileListAdd[j].raw);
                    }
                    formData2.append("fileType",'OTHER');
                    formData2.append("projectId",this.editForm.id)
                    const {data:res3}= await this.$http1.post('/v1.0/project/basicInfo/file/saveAll', formData2)
                    if(res3.businessCode!='1000'){
                        return this.$message.error('添加项目文件失败！'+res3.message)
                    }
                }
                this.$message.success('修改项目成功！');
                this.dialogVisibleEdit=false;
                this.getProinfo();
            },
            filechange(file,List){
             this.fileListqingdan=List
            },
            handleRemove(file, List) {
             this.fileListqingdan=List
            },
            async uploadModel(){
            var formData2=new FormData();
                   for(let j in this.fileListqingdan){
                        formData2.append("multipartFiles",this.fileListqingdan[j].raw);
                    }
                    formData2.append("fileType",'LIST');
                    formData2.append("projectId",this.$root.proId)
                       const {data:res3}= await this.$http1.post('/v1.0/project/basicInfo/file/saveAll', formData2)
                    if(res3.businessCode!='1000'){
                        return this.$message.error('添加文件失败！'+res3.message)
                    }
                    this.$message.success('添加文件成功！')
                    this.writeForm=false;
                    this.getProinfo();
            },
            async downFile(path,name){
                const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = name;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            async shanchu (row)  {
              const confirmResult = await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/project/basicInfo/file/delete/'+row.id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProinfo();
             },
            async yulan(path){
                window.open('http://8.130.180.224:9000/epc/'+path)
             },
            arraySpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    const _row = this.flitterData(this.huafenData).one[rowIndex]
                    return {
                    rowspan: _row,
                    colspan: 1,
                    }
                }
                if (columnIndex === 1) {
                    const _row1 = this.flitterData2(this.huafenData).one[rowIndex]
                    return {
                    rowspan: _row1,
                    colspan: 1,
                    }
                }
                if (columnIndex === 2) {
                    const _row1 = this.flitterData3(this.huafenData).one[rowIndex]
                    return {
                    rowspan: _row1,
                    colspan: 1,
                    }
                }
            },
            flitterData(arr) {
                let spanOneArr = [],
                    spanTwoArr = [],
                    concatOne = 0
                arr.forEach((item, index) => {
                    if (index === 0) {
                    spanOneArr.push(1)
                    spanTwoArr.push(1)
                    } else {
                    // 只需要将 span_name 修改为自己需要合并的字段名即可
                    if (item.unitConstruction === arr[index - 1].unitConstruction) {
                    //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1
                    spanOneArr.push(0)
                    } else {
                    spanOneArr.push(1)
                    concatOne = index
                    }
                    }
                })
                return {
                    one: spanOneArr,
                }
            },
            flitterData2(arr) {
                let spanOneArr = [],
                    spanTwoArr = [],
                    concatOne = 0
                arr.forEach((item, index) => {
                    if (index === 0) {
                    spanOneArr.push(1)
                    spanTwoArr.push(1)
                    } else {
                    // 只需要将 span_name 修改为自己需要合并的字段名即可
                    if (item.substructure === arr[index - 1].substructure) {
                    //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1
                    spanOneArr.push(0)
                    } else {
                    spanOneArr.push(1)
                    concatOne = index
                    }
                    }
                })
                return {
                    one: spanOneArr,
                }
            },
            flitterData3(arr) {
                let spanOneArr = [],
                    spanTwoArr = [],
                    concatOne = 0
                arr.forEach((item, index) => {
                    if (index === 0) {
                    spanOneArr.push(1)
                    spanTwoArr.push(1)
                    } else {
                    // 只需要将 span_name 修改为自己需要合并的字段名即可
                    if (item.subItem.split('-')[1] === arr[index - 1].subItem.split('-')[1]) {
                    //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1
                    spanOneArr.push(0)
                    } else {
                    spanOneArr.push(1)
                    concatOne = index
                    }
                    }
                })
                return {
                    one: spanOneArr,
                }
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            } ,
            editdialogClose(){
                this.$refs.editFormRef.resetFields()
            },
            handleRemoveEdit(file, fileList) {
                console.log(file)
                if(file.id){
                    this.fileListDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListAdd.length; i++) {
                        if (this.fileListAdd[i] == file) {
                            this.fileListAdd.splice(i,1)
                        }
                    }
                }
                this.fileListEdit=fileList
            },
            handlePreviewEdit(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible1 = true;
            },
            filechangeEdit(file,filelist){
                this.fileListAdd.push(file)
                this.fileListEdit=filelist
            },
            async getZuzhi(){
                const {data:res}=await  this.$http1.get('/v1.0/enterprises/project-department/query/ProjectDepartmentListById?projectId='+this.$root.proId)
                if(res.businessCode=='3000'){
                    for(let i in res.data){
                        // this.danweiData.push({'id': 'dw'+res.data[i].unitId,'text': res.data[i].unitName,'color': '#2E4E8F',width:225,height:50})
                        // for(let j in res.data[i].departmentUserDtos){
                        //     this.bumenData.push({'id': 'bm'+ res.data[i].departmentUserDtos[j].departmentId,'text': res.data[i].departmentUserDtos[j].departmentName,'color': '#4ea2f0'})
                        //     this.lineData.push({'from':'dw'+res.data[i].unitId ,'to':'bm'+ res.data[i].departmentUserDtos[j].departmentId})
                        //     for(let x in res.data[i].departmentUserDtos[j].userList){
                        //         this.personData.push({'id':'p'+res.data[i].departmentUserDtos[j].userList[x].id,'text':res.data[i].departmentUserDtos[j].userList[x].name,'data':res.data[i].departmentUserDtos[j].userList[x],width:25})
                        //         this.lineData2.push({'from':'bm'+ res.data[i].departmentUserDtos[j].departmentId,'to':'p'+res.data[i].departmentUserDtos[j].userList[x].id})
                        //     }
                        // }
                        this.danweiData.push({'id': 'dw'+res.data[i].id,'text': res.data[i].departmentName,'color': '#2E4E8F',width:30,height:120})
                        this.lineData.push({'from':'dw'+res.data[i].parentId ,'to':'dw'+ res.data[i].id})
                     }
                }
                var zuzhiData=this.danweiData
                var line =this.lineData
                this.__graph_json_data= { 'rootId': this.danweiData[0].id, 'nodes':zuzhiData,'links':line}
                this.showSeeksGraph()
            },
            showSeeksGraph(query) {
                if(this.__graph_json_data==''){
                    return
                }
                        //var __graph_json_data = { 'rootId': 'N1', 'nodes': [{ 'id': 'N1', 'text': '深圳市腾讯计算机系统有限公司', 'color': '#2E4E8F' }, { 'id': 'N2', 'text': '腾讯云科技有限公司', 'color': '#4ea2f0' }], 'links': [{ 'from': 'N1', 'to': 'N2', 'text': '' }] }
                this.$refs.seeksRelationGraph.setJsonData(this.__graph_json_data, (seeksRGGraph) => {
                    // 这些写上当图谱初始化完成后需要执行的代码
                })
            },
            async onNodeClick(nodeObject, $event) {
                console.log(nodeObject.id)
                const {data:res}=await  this.$http1.get('/v1.0/enterprises/project-team/findAllByDepartmentId?departmentId='+nodeObject.id.substring(2,nodeObject.id.length))
                this.gridData=res.data
                this.dialogTableVisible=true
                // if(nodeObject.data.name){
                //     this.personForm=nodeObject.data
                //     this.dialogVisiblePerson=true
                // }

            },
            onLineClick(lineObject, $event) {
                console.log('onLineClick:', lineObject)
            },
            onNodeExpand(node, e){

            },
            showNodeTips(nodeObject, $event) {
                if(nodeObject.data.name){
                    this.currentNode = nodeObject
                    var _base_position = this.$refs.myPage.getBoundingClientRect()
                    this.isShowNodeTipsPanel = true
                    this.nodeMenuPanelPosition.x = $event.clientX - _base_position.x + 398
                    this.nodeMenuPanelPosition.y = $event.clientY - _base_position.y + 310
                }

            },
            hideNodeTips(nodeObject, $event) {
                this.isShowNodeTipsPanel = false
            }
        },
        mounted(){

        },
        components:{
            proLeft,
            RelationGraph

        }
    }
</script>

<style scoped lang="less">
    #proInfo{
        background-color: white;
        position: absolute;
        left: 510px;
        top: 80px;
        bottom:19px;
        width: 1390px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        justify-content: space-between;
    }
    .proImgDiv{
        width: 580px;
        height: 380px;
       // margin-top: 5px;
        margin-left: 10px;
        /deep/ .el-carousel__container{
            height: 350px;
        }
    }
    .proInfoDiv{
        width: 580px;
        height: 380px;
        max-height: 410px;
        overflow:auto;
        /deep/ .el-form-item{
            margin-bottom: 5px !important;
        }
    }
    .el-carousel__item img {
       width: 100%;
        height: 100%;
    }
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .proright{
        width: 780px;
        //margin-top: 5px;
       // border-left: 2px solid #f0f3fa;
        /deep/ .el-textarea__inner{
            font-size: 18px !important;
            font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
        }
    }
    /deep/ .el-form-item__label{
        font-size: 16px;
    }
    #editdialog{
        /deep/ .el-form-item__label{
            font-size: 14px;
        }
        /deep/ .el-textarea__inner{
            border: 1px solid #C0C4CC !important;
            font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
        }
        /deep/ .el-dialog__body{
            padding-bottom: 0 !important;
        }
        /deep/ .el-form-item{
            margin-bottom: 15px !important;
        }
    }
    /deep/ .el-button{
        background-color: #E3ECFB !important;
        color: black !important;
        font-weight: normal !important;
        border: 0 !important;
        span{
            font-size: 15px !important;
        }
    }
    /deep/ .el-input__inner{
        color: black !important;

    }
    /deep/ .el-date-editor, /deep/ .el-date-editor--date{
        width: 100% !important;
    }
    /deep/ .rel-node{
        padding-left:5px;
        padding-right: 5px;
    }

    /deep/ .el-card{
        height: 90% !important;
    }
    /deep/ .el-card__body{
        padding: 9px !important;
    }
    .box-card{
        height: 90% !important;
    }
    .box-card2{
        height: 500px !important;
        /deep/ .el-table__body-wrapper{
            max-height: 450px;
            overflow-y: auto;
        }
    }
    /deep/ .c-mini-toolbar{
        width: 40px !important;
        margin-left: 740px !important;
        margin-top: 140px !important;
        div{
            width: 38px !important;
        }
    }
    /deep/ .c-mb-button{
        height: 40px !important;
        width: 38px !important;
        font-size: 14px !important;
        line-height: 18px !important;
        padding-top: 0px !important;
    }
    /deep/ .c-mb-child-panel{
        margin-left: -38px !important;
    }
    .proleft{
        /deep/ .el-card{
            height: 100% !important;
        }
    }
    /deep/.el-collapse-item__header{
        background-color: #E3ECFB;
        border-radius: 0.026042rem 0.026042rem 0 0;
        height: 0.182292rem;
        padding: 2px !important;
        font-size:22px ;
        color: black;
        font-weight: bold;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
.text_item{
 
}
</style>
