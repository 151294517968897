<template>
    <div class="bg">
        <!-- <process-left></process-left> -->
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-button size="small"  icon="el-icon-search" style="margin-left: 10px"   @click="anri">按日显示</el-button>
                    <el-button size="small"  icon="el-icon-search" @click="anyue">按月份显示</el-button>
                    <el-button size="small"  icon="el-icon-search" @click="annian">按年份显示</el-button>
                    <el-button size="small"  icon="el-icon-s-promotion" @click="exportPng">导出png</el-button>
                    <el-button size="small"  icon="el-icon-s-promotion" @click="exportPdf">导出pdf</el-button>
                    <el-button size="small"  icon="el-icon-s-promotion" @click="exportExcel">导出EXCLE</el-button>
                    <span class="zongjindu">
                        项目总进度:{{zongjinduNum*100}}%
                    </span>
               
                </div>
                <div ref="gantt1" class="left-container"/>
            </el-card>
        </div>
        <el-dialog
                title="修改任务信息"
                :visible.sync="dialogVisibleEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="editForm" label-width="125px" :rules="addFormRules">
                <el-form-item label="任务名称" prop="taskName">
                    <el-input v-model="editForm.taskName"   :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="计划开始时间" prop="startDate">
                    <el-date-picker
                            v-model="editForm.startDate"
                            type="date"
                            :disabled="true"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="计划完成时间" prop="endDate">
                    <el-date-picker
                            v-model="editForm.endDate"
                            type="date"
                            :disabled="true"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="实际开始时间" prop="actStartDate">
                    <el-date-picker
                            v-model="editForm.actStartDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="实际完成时间" prop="actEndDate">
                    <el-date-picker
                            v-model="editForm.actEndDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="实际完成率" prop="completeRate">
                    <el-input-number v-model="editForm.completeRate"  :min="0" :max="100" label="完成率0-100"></el-input-number>%
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibleEdit = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editProcess">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import processLeft from './processLeft'
    import gantt1 from 'dhtmlx-gantt'  // 引入模块
    //import 'dhtmlx-gantt/codebase/dhtmlxgantt.css'
    import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_terrace.css' //皮肤
    
    var zoomConfig = {
        levels: [
            {
                name:"day",
                scale_height: 27,
                min_column_width:80,
                scales:[
                    {unit: "day", step: 1, format: "%d %M"}
                ]
            },
            {
                name:"week",
                scale_height: 50,
                min_column_width:50,
                scales:[
                    {unit: "week", step: 1, format: function (date) {
                            var dateToStr = gantt1.date.date_to_str("%d %M");
                            var endDate = gantt1.date.add(date, 6, "day");
                            var weekNum = gantt1.date.date_to_str("%W")(date);
                            return "#" + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
                        }},
                    {unit: "day", step: 1, format: "%j %D"}
                ]
            },
            {
                name:"month",
                scale_height: 50,
                min_column_width:120,
                scales:[
                    {unit: "month", format: "%F, %Y"},
                    {unit: "week", format: "Week #%W"}
                ]
            },
            {
                name:"quarter",
                height: 50,
                min_column_width:90,
                scales:[
                    {unit: "month", step: 1, format: "%M"},
                    {
                        unit: "quarter", step: 1, format: function (date) {
                            var dateToStr = gantt1.date.date_to_str("%M");
                            var endDate = gantt1.date.add(gantt1.date.add(date, 3, "month"), -1, "day");
                            return dateToStr(date) + " - " + dateToStr(endDate);
                        }
                    }
                ]},
            {
                name:"year",
                scale_height: 50,
                min_column_width: 30,
                scales:[
                    {unit: "year", step: 1, format: "%Y"}
                ]}
        ]
    };
    export default {
        name: "progantee",
        data() {
            return {
                zongjinduNum:0,
                menuTable:[],
                isZhank: false,
                tasks: {
                    data:[]
                },
                selectId:'',
                dialogVisibleEdit:false,
                editForm:{},
                count:null,
                addFormRules:{
                    taskName:[{required:true,message:'请输入任务名称',trigger:'blur'}],
                },
                yonghuOptions:[],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }]
                },
            }
        },
        created(){
            gantt1.clearAll()
            this.getTaskAll();
            this.getUsers();
            this.getLeftMenu()
        },
        mounted() {
            // 初始化
            gantt1.i18n.setLocale("cn");
            gantt1.config.readonly = true
            gantt1.config.date_scale = '%Y-%m-%d'
            gantt.config.duration_unit = 'month' // 工期计算的基本单位
            gantt1.plugins({
                tooltip: true// 启用 tooltip 插件
            })
            gantt1.config.columns = [ // 配置左边的表格项
                {
                    name: 'taskName',
                    tree:true,
                    label: '任务名',
                    width: 170
                },
                {
                    name: 'serialNum',
                    label: '标识码',
                    align:'center',
                    width: 40
                },
                {
                    name: 'isMileStone',
                    align:'center',
                    label: '里程碑',
                    width: 40,
                    template: function (task) {
                        if(task.isMileStone==1){
                            return (
                                '<i style="color: red" class="el-icon-star-on"></i>'
                            );
                        }
                    }
                },
                {
                    name: 'duration',
                    label: '工期',
                    align:'center',
                    width: 40,
                    template: function (task) {
                        if(task.duration){
                            return (
                                '<span style="color: red;font-weight:777" >'+(task.duration+1)+'</span>'
                            );
                        }
                    }
                },
                {
                    name: 'startDate',
                    label: '计划开始时间',
                    align:'center',
                    width: 90
                },
                {
                    name: 'endDate',
                    label: '计划完成时间',
                    align:'center',
                    width: 90
                },
                {
                    name: 'actStartDate',
                    label: '实际开始时间',
                    align:'center',
                    width: 90
                },
                {
                    name: 'actEndDate',
                    label: '实际完成时间',
                    align:'center',
                    width: 90
                },
                {
                    name: 'principal',
                    label: '责任人',
                    align:'center',
                    width: 60
                },
                {
                    name: 'approve',
                    label: '审批人',
                    align:'center',
                    width: 60
                },
                {
                    name: 'completeRate',
                    label: '完成率',
                    align:'center',
                    width: 50,
                    template: function (task) {
                        if(task.completeRate){
                            return (
                                '<span style="color: red;font-weight:777" >'+task.completeRate+'</span>'
                            );
                        }
                    }
                },
             //   {name:"add",        label:"" },
            ]
            /*
                        gantt.templates.tooltip_text = (start, end, task) => {
                            return `${task[fields.user]}${task[fields.text]}计划开始时间 ${format(task[fields.start_date], 'YYYY-MM-DD')}计划结束时间 ${format(task[fields.end_date], 'YYYY-MM-DD')}`
                        }*/
            gantt1.templates.tooltip_text = (start, end, task) => {
                return "<b>任务名:</b> "+task.taskName+"<br/><b>工期:</b> " + task.duration+"<br/><b>计划开始时间:</b> " + task.startDate+"<br/><b>计划结束时间:</b> " + task.endDate;
                //  return `${task[fields.user]}${task[fields.text]}计划开始时间 ${format(task[fields.start_date], 'YYYY-MM-DD')}计划结束时间 ${format(task[fields.end_date], 'YYYY-MM-DD')}`
            }
            gantt1.templates.task_text=function(start,end,task){
                return "<b>"+task.taskName+"</b> ";
            };
            gantt1.config.scale_unit = "day";
            gantt1.init(this.$refs.gantt1)

            gantt1.parse(this.tasks)
            gantt1.attachEvent("onTaskClick", (id, e) =>{
              console.log(id)
                this.selectId=id
                return true;
            });
            gantt1.attachEvent("onTaskDblClick", (id, e) =>{
                var i=0
                if(i==0){
                    this.findByid(id)
                    this.dialogVisibleEdit=true
                }
                 i++
                return false;
            });
          /*  gantt.init(this.$refs.gantt)
            // 数据解析
            gantt.parse(this.tasks)*/
        },
        methods:{
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=42')
                this.menuTable=[]
                for(let i in res.data){
                  if(res.data[i].belongOrg==1){
                    this.menuTable.push(res.data[i])
                  }
                }
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            async getTaskAll(){
                const {data:res}= await this.$http6.get('/v1.0/schedule/implement/findAll?projectId='+this.$root.proId)
                                                              
                if(res.businessCode!='3000'){
                    return this.$message.error(res.message)
                }
                if(res.data.length>0){
                    let count=0;let index=0;
                    for(let i in res.data){
                        if(res.data[i].parentId==0){
                            count=count+res.data[i].completeRate;
                            index++
                        }
                    }
                    this.zongjinduNum=(count/index).toFixed(4)
                }

                this.tasks.data=res.data;
                for(let i in this.tasks.data){
                    this.tasks.data[i].start_date=this.tasks.data[i].startDate.split("-").reverse().join("-")
                    this.tasks.data[i].end_date=this.tasks.data[i].endDate.split("-").reverse().join("-")
                    this.tasks.data[i].parent=this.tasks.data[i].parentId
                    if(this.tasks.data[i].completeRate==null){
                        this.tasks.data[i].completeRate='0%'
                    }else {
                        let ddd=((this.tasks.data[i].completeRate*100)/100).toFixed(4)
                        console.log(ddd)
                        this.tasks.data[i].completeRate=(Number(ddd)*100).toFixed(2)+'%'
                       
                    }

                    this.tasks.data[i].open=true
                    if(this.tasks.data[i].configType=='project'){
                        this.tasks.data[i].type=gantt1.config.types.project
                    }else {
                        this.tasks.data[i].type=gantt1.config.types.task
                    }
                }
                gantt1.clearAll();
                gantt1.parse(this.tasks)
            },
            async getUsers(){
                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.businessCode!='3000'){
                    return this.$message.error(res.message)
                }
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghuOptions.push(res.data[i])
                }
            },
            async findByid(id){
                const {data:res}=await this.$http6.get('/v1.0/schedule/implement/findById?id='+id);
                if(res.businessCode!='3000'){
                    return this.$message.error('查询计划失败！'+res.message)
                }
                this.editForm=res.data
                this.editForm.completeRate= res.data.completeRate*100;
            },
            async editProcess(){
                console.log(this.editForm.completeRate)
                var process=((this.editForm.completeRate)/100).toFixed(2)
                const {data:res}=await this.$http6.put('/v1.0/schedule/implement/updateCompleteRate?completeRate='+process+'&id='+this.editForm.id);
                if(res.businessCode!='4300'){
                    return this.$message.error('更新完成率失败！'+res.message)
                }
                if(this.editForm.actEndDate==null){
                    this.editForm.actEndDate=''
                }
                if(this.editForm.actStartDate==null){
                    this.editForm.actStartDate=''
                }
                const {data:res1}=await this.$http6.put('/v1.0/schedule/implement/updateActStartDateAndEndDate?actEndDate='+this.editForm.actEndDate+'&actStartDate='+this.editForm.actStartDate+'&id='+this.editForm.id);
                if(res1.businessCode!='4300'){
                    return this.$message.error('更新实际开始完成日期失败！'+res1.message)
                }
                this.$message.success('更新成功！')
                this.getTaskAll();
                this.dialogVisibleEdit=false
            },
            anri(){
                gantt1.config.date_scale = '%Y-%m-%d'
                gantt1.config.scale_unit = "day";
                gantt1.init(this.$refs.gantt1)
            },
            anyue(){
                gantt1.config.date_scale = '%Y-%m'
                gantt1.config.scale_unit = "month";
                gantt1.init(this.$refs.gantt1)
            },
            annian(){
                gantt1.config.date_scale = '%Y'
                gantt1.config.scale_unit = "year";
                gantt1.init(this.$refs.gantt1)
            },
            exportPdf(){
                gantt1.exportToPDF()
            },
            exportPng(){
                gantt1.exportToPNG()
            },
            exportExcel(){
                gantt1.exportToExcel()
            },
            handleClose(done) {
             /*   this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});*/
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
        },
        components:{
            processLeft
        }
    }
</script>

<style scoped lang="less">
    #gante{
        display: flex;
        justify-content: space-between;
    }
    .left-container {
     /*   position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        height: 800px;*/
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 110px);
    }

.zongjindu{
    font-size: 22px !important;
    color: #000 !important;
    margin-left: 200px;
}
</style>
