<template>
  <div class="mainbox">
    <div class="header">
      <div class="timeDiv">
        <span class="time">{{ date | formatDate }}</span>
      </div>
      <div class="titleDiv">
        <span class="title">兵团设计院EPC项目管理云平台</span>
      </div>
      <div class="adminDiv">
        <img
          src="../assets/images/设置.png"
          class="shezhiImg"
          @click="goSystem"
        />
        <img src="../assets/images/admin.png" class="adminImg" />
        <div>
          <el-dropdown>
            <span class="loginname"
              >{{ users }}<i class="el-icon-arrow-down el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click="logout"
                ><span @click="logout">注销账户</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="leftCont">
        <div class="box leftContTop">
          <div class="boxheader">
            <span> 项目统计 </span>
          </div>
          <div class="boxcont">
            <div class="xmtj">
              <div>
                <span class="xmtjspan1">01</span>
                <span class="xmtjspan2">项目总数</span>
              </div>
              <span class="xmtjspan3"
                >{{ numfrom.totalCount }}<span class="xmtjspan4">个</span></span
              >
            </div>
            <div class="xmtj">
              <div>
                <span class="xmtjspan1">02</span>
                <span class="xmtjspan2">新签项目</span>
              </div>
              <span class="xmtjspan3"
                >{{ numfrom.signCount }}<span class="xmtjspan4">个</span></span
              >
            </div>
            <div class="xmtj">
              <div>
                <span class="xmtjspan1">03</span>
                <span class="xmtjspan2">在建项目</span>
              </div>
              <span class="xmtjspan3"
                >{{ numfrom.buildingCount
                }}<span class="xmtjspan4">个</span></span
              >
            </div>
            <div class="xmtj">
              <div>
                <span class="xmtjspan1">04</span>
                <span class="xmtjspan2">完成项目</span>
              </div>
              <span class="xmtjspan3"
                >{{ numfrom.completeCount
                }}<span class="xmtjspan4">个</span></span
              >
            </div>
            <div class="xmtj">
              <div>
                <span class="xmtjspan1">05</span>
                <span class="xmtjspan2">本年总投资</span>
              </div>
              <span class="xmtjspan3"
                >{{ numfrom.totalInvestmentCurrent
                }}<span class="xmtjspan4">亿</span></span
              >
            </div>
            <div class="xmtj">
              <div>
                <span class="xmtjspan1">06</span>
                <span class="xmtjspan2">项目总投资</span>
              </div>
              <span class="xmtjspan3"
                >{{ numfrom.totalInvestment
                }}<span class="xmtjspan4">亿</span></span
              >
            </div>
          </div>
        </div>
        <div class="box leftContCen">
          <div class="boxheader">
            <span> 基础信息统计 </span>
          </div>
          <div class="boxcont aqsccont">
            <div class="aqsc">
              <p class="aqscP1"><span>1565</span>人</p>
              <p>平台已录人员</p>
            </div>
            <div class="aqsc">
              <p class="aqscP1"><span>0</span>个</p>
              <p>监控设备</p>
            </div>
            <div class="aqsc">
              <p class="aqscP1"><span>1219</span>次</p>
              <p>访问次数</p>
            </div>
            <div class="aqsc">
              <p class="aqscP1"><span>39</span>个</p>
              <p>文件数量</p>
            </div>
          </div>
        </div>
        <div class="box leftContBot">
          <div class="boxheader">
            <span> 质量统计 </span>
          </div>
          <div class="boxcont zhiliang">
            <div class="zltj">
              <img src="../assets/images/合格.png" />
              <p class="zltjP">合格</p>
              <p class="zltjP1">{{ qualiFrom.pass }}</p>
            </div>
            <div class="zltj">
              <img src="../assets/images/不合格.png" />
              <p class="zltjP">不合格</p>
              <p class="zltjP2">{{ qualiFrom.acceptedQuantity-qualiFrom.pass }}</p>
            </div>
            <div class="zltj">
              <img src="../assets/images/未评定.png" />
              <p class="zltjP">合格率</p>
              <p class="zltjP3">{{ qualiFrom.passRate*100 }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="centerCont">
        <div class="xiangmu">
          <div  class="xiangmuDiv1" :class="{ mapactive: mapcurrent == 1 }"  @click="getdoing" >
            <span>正在进行中({{ doingNum }})</span>
          </div>
          <div class="xiangmuDiv2" :class="{ mapactive: mapcurrent == 2 }" @click="getdone">
            <span>已完成项目({{ doneNum }})</span>
          </div>
          <div class="xiangmuDiv3" :class="{ mapactive: mapcurrent == 3 }" @click="gettodo">
            <span>未开始项目({{ todoNum }})</span>
          </div>
          <div class="xiangmuDiv4" :class="{ mapactive: mapcurrent == 4 }" @click="getnotdo">
            <span>已超期项目({{ notdoNum }})</span>
          </div>
        </div>
        <div class="popup" id="popup" v-show="popShow">
          <div class="popupHead">
            <span :title="popupdata.projectName">{{
              popupdata.projectName
            }}</span>
            <a class="popx" @click="closePop">x</a>
          </div>
          <div class="popupCont">
            <ul>
              <li>
                <span :title="popupdata.projectAddress" class="popspan"
                  >项目地址：{{ popupdata.projectAddress }}</span
                >
              </li>
              <li>
                <span :title="popupdata.projectType" class="popspan"
                  >项目类型：{{ popupdata.projectType }}</span
                >
              </li>
              <li>
                <span :title="popupdata.totalInvestment" class="popspan"
                  >项目总投资：{{ popupdata.totalInvestment }}</span
                >
              </li>
              <li>
                <span :title="popupdata.projectStatus" class="popspan"
                  >项目进度：{{ popupdata.projectStatus }}</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="centerContTop" id="map"></div>
        <div class="centerContBot">
          <div class="box centerContTopTop">
            <div class="boxheader">
              <span> 项目列表 </span>
            </div>
            <div class="boxcont" style="margin-top: 1%">
              <el-table
                :data="proData"
                style="width: 100%"
                :row-class-name="tableRowClassName"
              >
                <el-table-column
                  prop="projectName"
                  label="项目名称"
                  width="280"
                >
                  <template slot-scope="scope">
                    <span
                      @click="gopro(scope.row)"
                      :title="scope.row.projectName"
                      style="
                        color: #b1d1ff;
                        cursor: pointer;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        white-space: pre-line;
                        -webkit-line-clamp: 1;
                      "
                      >{{ scope.row.projectName }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="projectType"
                  label="项目类型"
                  width="100"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.projectType">{{ scope.row.projectType.typeName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="planStartDate"
                  label="计划开始时间"
                  width="130"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="principal"
                  label="负责人"
                  width="100"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="totalInvestment"
                  label="投资规模"
                  width="130"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.totalInvestment }}亿</span>
                  </template>
                </el-table-column>
                <el-table-column prop="jindu" label="项目进度">
                  <template slot-scope="scope">
                    <div class="jinduDiv">
                      <div
                        class="jinduNum"
                        v-bind:style="{ width: scope.row.jindunum + '%' }"
                      ></div>
                    </div>
                    &nbsp;{{ scope.row.jindunum }}%
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="rightCont">
        <div class="box rightContTop">
          <div class="boxheader">
            <span> 项目大事记</span>
          </div>
          <div class="boxcont dashiji">
            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item>
                <div class="carcont">
                  <div class="dashijiimg"></div>
                  <div class="dashijicont">
                    <table>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #2fabe0"
                          ></div>
                        </td>
                        <td style="width: 50px">项目:</td>
                        <td style="width: 130px; color: #19eaef">
                          <span
                            style="
                              display: -webkit-box;
                              text-overflow: ellipsis;
                              overflow: hidden;
                              -webkit-box-orient: vertical;
                              white-space: pre-line;
                              -webkit-line-clamp: 3;
                            "
                            title="图木舒克市达坂山工业园区 10 平方公里基础设施配套项目（道路工程）设计施工总承包（EPC）"
                            >图木舒克市达坂山工业园区 10
                            平方公里基础设施配套项目（道路工程）设计施工总承包（EPC）</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #08d15f"
                          ></div>
                        </td>
                        <td style="width: 50px">标题：</td>
                        <td style="width: 130px; color: #19eaef">
                          东西一线完工
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #1fa780"
                          ></div>
                        </td>
                        <td style="width: 50px">内容：</td>
                        <td style="width: 130px; color: #19eaef">
                          东西一线沥青混泥土面层施工完毕
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #d3dd30"
                          ></div>
                        </td>
                        <td style="width: 50px">日期：</td>
                        <td style="width: 130px; color: #19eaef">2021-08-07</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="carcont">
                  <div class="dashijiimg dashijiimg2"></div>
                  <div class="dashijicont">
                    <table>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #2fabe0"
                          ></div>
                        </td>
                        <td style="width: 50px">项目:</td>
                        <td style="width: 130px; color: #19eaef">
                          <span
                            title="图木舒克市农副产品精深加工标准化厂房项目（EPC总承包）"
                            style="
                              display: -webkit-box;
                              text-overflow: ellipsis;
                              overflow: hidden;
                              -webkit-box-orient: vertical;
                              white-space: pre-line;
                              -webkit-line-clamp: 3;
                            "
                          >
                            图木舒克市农副产品精深加工标准化厂房项目（EPC总承包）
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #08d15f"
                          ></div>
                        </td>
                        <td style="width: 50px">标题：</td>
                        <td style="width: 130px; color: #19eaef">主体验收</td>
                      </tr>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #1fa780"
                          ></div>
                        </td>
                        <td style="width: 50px">内容：</td>
                        <td style="width: 130px; color: #19eaef">
                          1号、2号、5号、6号厂房主体验收
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #d3dd30"
                          ></div>
                        </td>
                        <td style="width: 50px">日期：</td>
                        <td style="width: 130px; color: #19eaef">2021-11-22</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="box rightContCen">
          <div class="boxheader">
            <span> 合同信息 </span>
          </div>
          <div class="boxcont hetong">
            <el-table
              :data="tableData2"
              style="width: 100%"
              :row-class-name="tableRowClassName2"
            >
              <el-table-column
                prop="contractorName"
                label="合同名"
                width="173"
                align="center"
              >
                <template slot-scope="scope">
                  <a
                    :title="scope.row.contractorName"
                    @click="yulan(scope.row.files[0].filePath)"
                    style="
                      color: #ff919c;
                      cursor: pointer;
                      display: -webkit-box;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      -webkit-box-orient: vertical;
                      white-space: pre-line;
                      -webkit-line-clamp: 1;
                      text-align: left;
                    "
                  >
                    {{ scope.row.contractorName }}</a
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="signatureTime"
                label="签订日期"
                width="100"
                show-overflow-tooltip
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="contractorMonetary"
                label="总金额(元)"
                width="95"
                sortable
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="box rightContBot">
          <div class="boxheader">
            <span> 费用监管 </span>
          </div>
          <div class="boxcont aqsccont" style="height: 94%">
            <!-- <ul class="infinite-list" style="overflow:auto">
                <li v-for="(item,index) in costData" :key="index" class="infinite-list-item">
                  <el-descriptions :title="item.contractorName" border :column="4" direction="vertical">
                        <el-descriptions-item label="合同总额">{{item.contractSum}}</el-descriptions-item>
                        <el-descriptions-item label="付款比例">{{item.paymentRatio}}</el-descriptions-item>
                        <el-descriptions-item label="进度计量">{{item.progressMeasurement}}</el-descriptions-item>
                        <el-descriptions-item label="项目应收">{{item.projectReceivable}}</el-descriptions-item>
                        <el-descriptions-item label="实际收款">{{item.actualCollection}}</el-descriptions-item>
                        <el-descriptions-item label="项目拨款">{{item.projectAppropriation}}</el-descriptions-item>
                        <el-descriptions-item label="账上余额">{{item.balanceAccount}}</el-descriptions-item>
                        <el-descriptions-item label="剩余应收款项">{{item.remainingReceivables}}</el-descriptions-item>
                    </el-descriptions>
                </li>
              </ul> -->
            <div class="aqsc2">
              <img src="../assets/images/anquan.png" />
              <div>
                <p>合同总额</p>
                <p class="anquan">{{costData.contractSumTotal}}元</p>
              </div>
            </div>
            <div class="aqsc2">
              <img src="../assets/images/anquan.png" />
              <div>
                <p>实际收款</p>
                <p class="anquan">{{costData.actualCollectionTotal}}元</p>
              </div>
            </div>
            <div class="aqsc">
              <p class="aqscP1"><span>{{costData.projectAppropriationTotal}}</span>元</p>
              <p>项目拨款</p>
            </div>
            <div class="aqsc">
              <p class="aqscP1"><span>{{costData.remainingReceivablesTotal}}</span>元</p>
              <p>剩余应收款项</p>
            </div>
          </div>
        </div>
      </div>
      <el-button round size="mini" class="shouye" @click="gohome"
        >首页</el-button
      >
    </div>
    <div class="videocon" v-show="jiankongShow">
      <a href="#" @click="guanbiShexiang" class="jkpopx">X</a>
      <div class="toolButton">
        <el-row>
          <el-button
            type="info"
            icon="el-icon-top"
            circle
            size="small"
            @click="gotop"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-bottom"
            circle
            size="small"
            @click="gobottom"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-back"
            circle
            size="small"
            @click="goleft"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-right"
            circle
            size="small"
            @click="goright"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-bottom-left"
            circle
            size="small"
            @click="gobottomleft"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-bottom-right"
            circle
            size="small"
            @click="gobottomright"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-top-left"
            circle
            size="small"
            @click="gotopleft"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-top-right"
            circle
            size="small"
            @click="gotopright"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-zoom-in"
            circle
            size="small"
            @click="gozoomin"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-zoom-out"
            circle
            size="small"
            @click="gozoomout"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-circle-plus-outline"
            circle
            size="small"
            @click="goplusoutline"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-remove-outline"
            circle
            size="small"
            @click="goremoveoutline"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-circle-close"
            circle
            size="small"
            @click="gostop"
          ></el-button>
        </el-row>
      </div>
      <div id="video-container"></div>
    </div>
  </div>
</template>

<script>
import "../assets/css/ol1.css";
import "../assets/css/ol2.css";
import EZUIKit from "ezuikit-js";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import Overlay from "ol/Overlay";
import * as control from "ol/control";
import * as olProj from "ol/proj";
import * as olExtent from "ol/extent";
import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import GeoJSON from "ol/format/GeoJSON";
import Stroke from "ol/style/Stroke";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Point from "ol/geom/Point";
import Circle from "ol/style/Circle";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import {
  TileSuperMapRest,
  GetFeaturesBySQLParameters,
  FeatureService,
} from "@supermap/iclient-ol";
var map, proSource, pronameSource;
var player;
var padDate = function (value) {
  return value < 10 ? "0" + value : value;
};
export default {
  name: "largescreen",
  data() {
    return {
      date: new Date(),
      users: "管理员",
      allUrl:
        "http://8.130.130.12:8808/iserver/services/map-epcdata/rest/maps/china",
      dataurl: "http://8.130.130.12:8808/iserver/services/data-epcdata/rest/data",
      popShow: false,
      name: "",
      shexiangurl: "",
      jiankongShow: false,
      mapcurrent: 0,
      doneNum: null,
      todoNum: null,
      notdoNum: null,
      doingNum: null,
      accessToken:
        "at.8gw4vfjo8wq5lrag2c6sy4ea9fjjor2r-6pn6l05ik6-1xq86j8-fvhjjneuy",
      deviceSerial: "",
      channelNo: "",
      typeData:[],
      tableData: [
      ],
      tableData2: [],
      costData:[],
      popupdata: {
        projectName: "",
        projectAddress: "",
        projectType: "",
        projectStatus: "",
        totalInvestment: "",
      },
      processData: [],
      proData: [],
      numfrom: {},
      qualiFrom: {},
      doingData:[],
      doneData:[],
      todoData:[],
      notdoData:[]
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "shallow-row";
      } else {
        return "deep-row";
      }
    },
    tableRowClassName2({ row, rowIndex }) {
      return "tou-row";
    },
    async getTongji() {
      const { data: res } = await this.$http1.get(
        "/v1.0/project/statistics/findAllByProjectId"
      );
      if (res.businessCode != "3000") {
        return this.$message.error("查询EPC项目个数失败！" + res.message);
      }
      this.numfrom = res.data;
      this.numfrom.totalInvestment = this.numfrom.totalInvestment.toFixed(4);
    },
    async getProtype(){
        const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAllType')
        this.typeData=res.data
     },
    async yulan(path){
        window.open('http://8.130.180.224:9000/epc/'+path)
    },
    async getpross() {
      const { data: res2 } = await this.$http6.get(
        "/v1.0/schedule/statistics/queryAvgCompleteRateStatistics"
      );
      if (res2.businessCode != "3000") {
        this.$message.error(res2.message);
        return this.processData == [];
      }
      this.processData = res2.data;
    },
    async getProInfo() {
      const { data: res } = await this.$http1.get(
        "/v1.0/project/basicInfo/findAll"
      );
      if (res.businessCode != "3000") {
        return;
      }
      window.sessionStorage.setItem("proName", "");
      this.proData = res.data;
      var numprocee = 0;
      for (let i in this.proData) {
        this.proData[i]['projectType']=this.typeData.find(o => o.id == this.proData[i].projectTypeId)
        console.log(this.proData[i]['projectType'])
        numprocee = 0;
        for (let j in this.processData) {
          if (this.processData[j].projectId == this.proData[i].id) {
            numprocee = Number(
              (Number(this.processData[j].avgCompleteRate) * 100).toFixed(2)
            );
            this.proData[i].jindunum = numprocee;
          } else {
            this.proData[i].jindunum = numprocee;
          }
        }
        if (this.proData[i].actualEndDate) {
          this.proData[i].tianshu = "已完成";
        } else {
          if (
            new Date(this.proData[i].planStartDate.replace("-", "/")) -
              new Date() >
            0
          ) {
            this.proData[i].tianshu = "未开始";
          } else {
            var time = (
              (new Date(this.proData[i].planEndDate.replace("-", "/")) -
                new Date()) /
              (1 * 24 * 60 * 60 * 1000)
            ).toFixed(0);
            time = time - 1;
            if (time < 0) {
              this.proData[i].tianshu = "已超期";
            } else {
              this.proData[i].tianshu = "正在进行中";
            }
          }
        }
      }
      var tianshu, status;
      proSource.clear();
      this.doneNum = 0;
      this.todoNum = 0;
      this.notdoNum = 0;
      this.doingNum = 0;
      this.doingData=[]
      this.doneData=[]
      this.todoData=[]
      this.notdoData=[]
     
      for (let i in this.proData) {
        if (this.proData[i].actualEndDate) {
          tianshu = "已完成";
          status = 0;
          this.doneNum++;
          this.doneData.push(this.proData[i])
        } else {
          if (
            new Date(this.proData[i].planStartDate.replace("-", "/")) -
              new Date() >
            0
          ) {
            tianshu = "未开始";
            status = 1;
            this.todoNum++;
            this.todoData.push(this.proData[i])
          } else {
            var time = (
              (new Date(this.proData[i].planEndDate.replace("-", "/")) -
                new Date()) /
              (1 * 24 * 60 * 60 * 1000)
            ).toFixed(0);
            if (time < 0) {
              tianshu = "已超期" + time + "天";
              status = 2;
              this.notdoNum++;
              this.notdoData.push(this.proData[i])
            } else {
              tianshu = "计划还剩" + time + "天";
              status = 3;
              this.doingNum++;
              this.doingData.push(this.proData[i])
            }
          }
        }
        var jingdu =
          Math.abs(this.proData[i].longitude) +
          (Math.abs(this.proData[i].longitudeMinute) / 60 +
            Math.abs(this.proData[i].longitudeSecond) / 3600);
        var weidu =
          Math.abs(this.proData[i].latitude) +
          (Math.abs(this.proData[i].latitudeMinute) / 60 +
            Math.abs(this.proData[i].latitudeSecond) / 3600);
        var gjFeature = new Feature({
          geometry: new Point([Number(jingdu), Number(weidu)]), //几何信息
          name: this.proData[i].projectName + "--" + tianshu,
          projectName: this.proData[i].projectName,
          projectType: this.proData[i].projectType.typeName,
          projectAddress: this.proData[i].projectAddress,
          planEndDate: this.proData[i].planEndDate,
          projectStatus: this.proData[i].projectStatus,
          totalInvestment: this.proData[i].totalInvestment,
        });
        //gjFeature.setStyle(createdoneStyle(gjFeature));
        var gjFeature2 = new Feature({
          geometry: new Point([
            Number(this.proData[i].longitude),
            Number(this.proData[i].latitude),
          ]), //几何信息
        });
        proSource.addFeature(gjFeature);

        // pronameSource.addFeature(gjFeature);
        // if(status==0){
        //     doneSource2.addFeature(gjFeature2)
        //     gjFeature.setStyle(createdoneStyle(gjFeature));
        //     doneSource.addFeature(gjFeature)

        // }else if(status==1){
        //     todoSource2.addFeature(gjFeature2)
        //     gjFeature.setStyle(createtodoStyle(gjFeature));
        //     todoSource.addFeature(gjFeature)

        // }else if(status==2){
        //     notdoSource2.addFeature(gjFeature2)
        //     gjFeature.setStyle(createnotdoStyle(gjFeature));
        //     notdoSource.addFeature(gjFeature)

        // }else if(status==3){
        //     doingSource2.addFeature(gjFeature2)
        //     gjFeature.setStyle(createdoingStyle(gjFeature));
        //     doingSource.addFeature(gjFeature)
        //     this.doingNum++;
        // }
      }
    },
    async getQuality() {
      const { data: res } = await this.$http1.get(
        "/v1.0/enterprises/project-qualitative/statisticsAll"
      );
      if (res.businessCode != "3000") {
        return;
      }
      this.qualiFrom = res.data[0];
    },
    async getCost() {
      const { data: res } = await this.$http1.get(
        "/v1.0/enterprises/project-progress/projectStatisticsAll"
      );
      if (res.businessCode != "3000") {
        return;
      }
      this.costData = res.data;
    },
    async getHetong(){
      const { data: res } = await this.$http1.get(
        "/v1.0/enterprises/project-contractor/findAllList"
      );
      if (res.businessCode != "3000") {
        return;
      }
      this.tableData2 = res.data;
    },
    getProName(id){
        for(let i in this.proData){
            if(this.proData[i].id==id){
                return this.proData[i].projectName
            }
        }
    },
    async addmap() {
      map = new Map({
        target: "map",
        controls: control.defaults({
          zoom: false,
          attribution: false,
        }),
        view: new View({
          center: [105.28838135, 34.209817352],
          zoom: 4,
          projection: "EPSG:4326",
        }),
      });
      var allLayer = new TileLayer({
        source: new TileSuperMapRest({
          url: this.allUrl,
          wrapX: false,
        }),
        projection: "EPSG:4326",
      });
      var hoverSource = new VectorSource({
        wrapX: false,
      });
      var hoverLayer = new VectorLayer({
        source: hoverSource,
        style: new Style({
          fill: new Fill({
            //矢量图层填充颜色，以及透明度
            color: "rgba(166,138,101,0.1)",
          }),
          stroke: new Stroke({
            //边界样式
            color: "rgba(243, 160, 119, 1)",
            width: 2,
          }),
        }),
      });
      map.addLayer(allLayer);
      map.addLayer(hoverLayer);
      var sqlParam = new GetFeaturesBySQLParameters({
        queryParameter: {
          name: "china@epcdata",
          attributeFilter: "",
        },
        toIndex: 9999,
        datasetNames: ["epcdata:china"],
      });
      new FeatureService(this.dataurl).getFeaturesBySQL(
        sqlParam,
        (serviceResult) => {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(serviceResult.result.features),
            wrapX: false,
          });
          var zhenLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
              fill: new Fill({
                //矢量图层填充颜色，以及透明度
                color: "rgba(166,138,101,0)",
              }),
              stroke: new Stroke({
                //边界样式
                color: "rgba(243, 160, 9, 0)",
                width: 2,
              }),
            }),
          });
          map.addLayer(zhenLayer);
        }
      );
      let cxFeature2;
      proSource = new VectorSource();
      pronameSource = new VectorSource();
      var pronameLayer = new VectorLayer({
        source: pronameSource,
      });
      var proLayer = new VectorLayer({
        source: proSource,
        className: "jklayer",
        style: new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: require("../assets/images/jiankong.png"),
          }),
        }),
      });
      // const { data: result } = await this.$httpaxios.get("./json/shi.json");
      // for (let i = 0; i < result.length; i++) {
      //   var shifea1 = new Feature({
      //     geometry: new Point([result[i].经度, result[i].纬度]),
      //     name: result[i].标准名,
      //   });
      //   proSource.addFeature(shifea1);
      // }
      map.addLayer(proLayer);
      pronameLayer.setVisible(false);
      map.addLayer(pronameLayer);
      map.on("pointermove", (evt) => {
        map.forEachFeatureAtPixel(evt.pixel, (cxFeature, layer) => {
          if (cxFeature2 == cxFeature) {
            return;
          } else {
            cxFeature2 = cxFeature;
            hoverSource.clear();
            hoverSource.addFeature(cxFeature);
          }
        });
      });
      var pop1 = new Overlay({
        element: document.getElementById("popup"),
        offset: [-130, -10],
      });
      var vectorSource = new VectorSource({});
      var vectorSource2 = new VectorSource({});
      var vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          stroke: new Stroke({
            color: "#68fffa",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      });
      var vectorLayer2 = new VectorLayer({
        source: vectorSource2,
        style: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#68fffa",
            }),
            radius: 3,
          }),
        }),
      });
      map.addLayer(vectorLayer);
      map.addLayer(vectorLayer2);
      vectorLayer2.setVisible(false);
      map.on("click", (evt) => {
        let coordinate = evt.coordinate;
        let jishu = 0;
        map.forEachFeatureAtPixel(evt.pixel, (cxFeature, layer) => {
          if (layer.className_ == "jklayer") {
            if (jishu >= 1) {
              return;
            }
            this.popShow = true;
            console.log(cxFeature);
            this.name = cxFeature.values_.name;
            map.addOverlay(pop1);
            this.popupdata = cxFeature.values_;
            this.timerpop = setInterval(() => {
              let first = this.popupdata.projectName.substring(0, 1);
              let last = this.popupdata.projectName.substring(1);
              this.popupdata.projectName = last + first;
            }, 400);
            console.log(cxFeature.geometryChangeKey_.target.flatCoordinates);
            let pointjwd = cxFeature.geometryChangeKey_.target.flatCoordinates;
            let roadLine = new LineString([
              pointjwd,
              [(74 + pointjwd[0]) / 2, pointjwd[1]],
              [74, 28.5],
            ]);
            let point = new Point([74, 28.5]);
            let point2 = new Point(pointjwd);
            vectorSource.clear();
            vectorSource2.clear();
            vectorSource2.addFeature(new Feature(point));
            vectorSource2.addFeature(new Feature(point2));
            vectorSource.addFeature(new Feature(roadLine));
            vectorLayer2.setVisible(true);
            pop1.setPosition([74, 28.5]);
            this.shexiangurl = "ezopen://open.ys7.com/AF3754209/2.hd.live";
            this.deviceSerial = "AF3754209";
            this.channelNo = 2;
            this.getVideo();
            this.jiankongShow = true;
            jishu++;
          }
        });
      });
      // map.getView().on("change:resolution", function () {
      //   if (map.getView().getZoom() > 8) {
      //     pronameLayer.setVisible(true);
      //   } else {
      //     pronameLayer.setVisible(false);
      //   }
      // });
    },
    getAllproMap(){
      this.mapcurrent=0
      proSource.clear()
      for (let i in this.proData) {
        var jingdu =
          Math.abs(this.proData[i].longitude) +
          (Math.abs(this.proData[i].longitudeMinute) / 60 +
            Math.abs(this.proData[i].longitudeSecond) / 3600);
        var weidu =
          Math.abs(this.proData[i].latitude) +
          (Math.abs(this.proData[i].latitudeMinute) / 60 +
            Math.abs(this.proData[i].latitudeSecond) / 3600);
        var gjFeature = new Feature({
          geometry: new Point([Number(jingdu), Number(weidu)]), //几何信息
          name: this.proData[i].projectName,
          projectName: this.proData[i].projectName,
          projectType: this.proData[i].projectType.typeName,
          projectAddress: this.proData[i].projectAddress,
          planEndDate: this.proData[i].planEndDate,
          projectStatus: this.proData[i].projectStatus,
          totalInvestment: this.proData[i].totalInvestment,
        });
        proSource.addFeature(gjFeature);
      }
    },
    async getdoing() {
      if(this.mapcurrent==1){
           this.getAllproMap()
      }else{
        this.mapcurrent=1
        proSource.clear()
      for (let i in this.doingData) {
        var jingdu =
          Math.abs(this.doingData[i].longitude) +
          (Math.abs(this.doingData[i].longitudeMinute) / 60 +
            Math.abs(this.doingData[i].longitudeSecond) / 3600);
        var weidu =
          Math.abs(this.doingData[i].latitude) +
          (Math.abs(this.doingData[i].latitudeMinute) / 60 +
            Math.abs(this.doingData[i].latitudeSecond) / 3600);
        var gjFeature = new Feature({
          geometry: new Point([Number(jingdu), Number(weidu)]), //几何信息
          name: this.doingData[i].projectName,
          projectName: this.doingData[i].projectName,
          projectType: this.doingData[i].projectType.typeName,
          projectAddress: this.doingData[i].projectAddress,
          planEndDate: this.doingData[i].planEndDate,
          projectStatus: this.doingData[i].projectStatus,
          totalInvestment: this.doingData[i].totalInvestment,
        });
        proSource.addFeature(gjFeature);
      }
      }
      
    },
    async getdone() {
      if(this.mapcurrent==2){
           this.getAllproMap()
      }else{
        this.mapcurrent=2
        proSource.clear()
      for (let i in this.doneData) {
        var jingdu =
          Math.abs(this.doneData[i].longitude) +
          (Math.abs(this.doneData[i].longitudeMinute) / 60 +
            Math.abs(this.doneData[i].longitudeSecond) / 3600);
        var weidu =
          Math.abs(this.doneData[i].latitude) +
          (Math.abs(this.doneData[i].latitudeMinute) / 60 +
            Math.abs(this.doneData[i].latitudeSecond) / 3600);
        var gjFeature = new Feature({
          geometry: new Point([Number(jingdu), Number(weidu)]), //几何信息
          name: this.doneData[i].projectName,
          projectName: this.doneData[i].projectName,
          projectType: this.doneData[i].projectType.typeName,
          projectAddress: this.doneData[i].projectAddress,
          planEndDate: this.doneData[i].planEndDate,
          projectStatus: this.doneData[i].projectStatus,
          totalInvestment: this.doneData[i].totalInvestment,
        });
        proSource.addFeature(gjFeature);
      }
      }
    },
    async gettodo() {
      if(this.mapcurrent==3){
           this.getAllproMap()
      }else{
        this.mapcurrent=3
        proSource.clear()
      for (let i in this.todoData) {
        var jingdu =
          Math.abs(this.todoData[i].longitude) +
          (Math.abs(this.todoData[i].longitudeMinute) / 60 +
            Math.abs(this.todoData[i].longitudeSecond) / 3600);
        var weidu =
          Math.abs(this.todoData[i].latitude) +
          (Math.abs(this.todoData[i].latitudeMinute) / 60 +
            Math.abs(this.todoData[i].latitudeSecond) / 3600);
        var gjFeature = new Feature({
          geometry: new Point([Number(jingdu), Number(weidu)]), //几何信息
          name: this.todoData[i].projectName,
          projectName: this.todoData[i].projectName,
          projectType: this.todoData[i].projectType.typeName,
          projectAddress: this.todoData[i].projectAddress,
          planEndDate: this.todoData[i].planEndDate,
          projectStatus: this.todoData[i].projectStatus,
          totalInvestment: this.todoData[i].totalInvestment,
        });
        proSource.addFeature(gjFeature);
      }
      }
    },
    async getnotdo() {
      if(this.mapcurrent==4){
           this.getAllproMap()
      }else{
        this.mapcurrent=4
        proSource.clear()
      for (let i in this.notdoData) {
        var jingdu =
          Math.abs(this.notdoData[i].longitude) +
          (Math.abs(this.notdoData[i].longitudeMinute) / 60 +
            Math.abs(this.notdoData[i].longitudeSecond) / 3600);
        var weidu =
          Math.abs(this.notdoData[i].latitude) +
          (Math.abs(this.notdoData[i].latitudeMinute) / 60 +
            Math.abs(this.notdoData[i].latitudeSecond) / 3600);
        var gjFeature = new Feature({
          geometry: new Point([Number(jingdu), Number(weidu)]), //几何信息
          name: this.notdoData[i].projectName,
          projectName: this.notdoData[i].projectName,
          projectType: this.notdoData[i].projectType.typeName,
          projectAddress: this.notdoData[i].projectAddress,
          planEndDate: this.notdoData[i].planEndDate,
          projectStatus: this.notdoData[i].projectStatus,
          totalInvestment: this.notdoData[i].totalInvestment,
        });
        proSource.addFeature(gjFeature);
      }
      }
    },
    gotop() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 0);
    },
    gobottom() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 1);
    },
    goleft() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 2);
    },
    goright() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 3);
    },
    gobottomleft() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 4);
    },
    gobottomright() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 5);
    },
    gotopleft() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 6);
    },
    gotopright() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 7);
    },
    gozoomin() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 8);
    },
    gozoomout() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 9);
    },
    goplusoutline() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 10);
    },
    goremoveoutline() {
      this.shexiangstart(this.deviceSerial, this.channelNo, 11);
    },
    async gostop() {
      const { data: res } = await this.$http10.post(
        "/lapp/device/ptz/stop?accessToken=" +
          this.accessToken +
          "&deviceSerial=" +
          this.deviceSerial +
          "&channelNo=" +
          this.channelNo
      );
    },
    async shexiangstart(deviceSerial, channelNo, direction) {
      const { data: res } = await this.$http10.post(
        "/lapp/device/ptz/start?accessToken=" +
          this.accessToken +
          "&deviceSerial=" +
          deviceSerial +
          "&channelNo=" +
          channelNo +
          "&direction=" +
          direction +
          "&speed=1"
      );
      if (res.code == 200) {
        setTimeout(() => {
          this.gostop();
        }, 1000);
      }
    },
    closePop() {
      this.popShow = false;
      vectorSource.clear();
      vectorSource2.clear();
    },
    getVideo() {
      player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: "video-container",
        accessToken: this.accessToken,
        url: this.shexiangurl,
        template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        //plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
        // openSoundCallBack: data => console.log("开启声音回调", data),
        // closeSoundCallBack: data => console.log("关闭声音回调", data),
        // startSaveCallBack: data => console.log("开始录像回调", data),
        // stopSaveCallBack: data => console.log("录像回调", data),
        // capturePictureCallBack: data => console.log("截图成功回调", data),
        // fullScreenCallBack: data => console.log("全屏回调", data),
        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        width: 1135,
        height: 630,
      });
    },
    guanbiShexiang() {
      player.stop();
      this.jiankongShow = false;
      player.stop();
    },
    gopro(row) {
      console.log(row);
      //this.$router.push({ path: "/largepro",params:{id:row.id,proname:row.projectName} });
      window.sessionStorage.setItem("id", row.id);
      window.sessionStorage.setItem("proname", row.projectName);
      this.$router.push({ path: "/largepro" });
    },
    goSystem() {
      this.$router.push({ path: "/system" });
    },
    gohome() {
      this.$router.push({ path: "/home" });
    },
    logout() {
      window.sessionStorage.removeItem("token");
      this.$router.push({ path: "/login" });
    },
  },
  filters: {
    formatDate: function (value) {
      var date = new Date(value);
      var year = date.getFullYear();
      var month = padDate(date.getMonth() + 1);
      var day = padDate(date.getDate());
      var hours = padDate(date.getHours());
      var minutes = padDate(date.getMinutes());
      var seconds = padDate(date.getSeconds());
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
  mounted() {
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = new Date(); // 修改数据date
    }, 1000);
    this.addmap();
  },
  created() {
    this.getProtype()
    this.getTongji();
    this.getpross();
    this.getProInfo();
    this.getQuality();
    this.getCost();
    this.getHetong();
   
  },
};
</script>

<style scoped lang="less">
.mainbox {
  color: #fff;
  font-size: 18px;
  background: url(../assets/images/beijing.png) no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  .header {
    background: url(../assets/images/header.png) no-repeat;
    background-size: 100% 100%;
    height: 60px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .timeDiv {
      width: 30%;
      .time {
        margin-left: 10px;
        line-height: 64px;
        color: #aae1ff;
        font-size: 18px;
      }
    }
    .titleDiv {
      text-align: center;
      width: 40%;
      .title {
        font-size: 32px;
        color: #36f2ff;
        letter-spacing: 4px;
        line-height: 54px;
        font-weight: 555;
      }
    }
    .adminDiv {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .shezhiImg {
        margin-left: 30%;
        height: 28px;
        margin-top: 19px;
        cursor: pointer;
      }
      .adminImg {
        margin-left: 30%;
        height: 38px;
        margin-top: 13px;
      }
      div {
        line-height: 60px;
        margin-right: 20px;
        .loginname {
          color: #aae1ff;
          font-size: 20px !important;
          cursor: pointer;
        }
      }
    }
  }

  .content {
    height: calc(100vh - 60px);
    display: flex;
    justify-content: space-between;
    .leftCont {
      margin-top: 30px;
      width: 20%;
      height: calc(100% - 60px);
      margin-left: 1%;
      background-color: rgba(4, 45, 66, 0.6);
      padding: 10px;
      padding-top: 0;
      .leftContTop {
        height: 43%;
        .xmtj {
          height: 15%;
          margin-bottom: 2%;
          background: url(../assets/images/项目框.png) no-repeat;
          background-size: 100% 100%;
          line-height: 42px;
          display: flex;
          justify-content: space-between;
          div {
            width: 44%;
          }
          .xmtjspan1 {
            margin-left: 11%;
          }
          .xmtjspan2 {
            margin-left: 21%;
            color: rgba(255, 255, 255, 0.8);
          }
          .xmtjspan3 {
            display: block;
            font-size: 24px;
            margin-right: 4%;
            color: #fced10;
          }
          .xmtjspan4 {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
      .leftContCen {
        width: 100%;
        height: 28%;
        margin-top: 2%;
        .aqsccont {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          .aqsc {
            width: 48%;
            height: 40%;
            background: url(../assets/images/安全生产框.png) no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            .aqscP1 {
              font-weight: 555;
              margin-top: 1%;
            }
            span {
              color: #47ffdf;
              font-size: 26px;
            }
          }
          .aqsc2 {
            width: 100%;
            height: 30%;
            background: url(../assets/images/安全生产框长.png) no-repeat;
            background-size: 100% 100%;
            text-align: left;
            display: flex;
            img {
              margin-left: 4%;
              margin-top: 3%;
              height: 60%;
            }
            div {
              margin-top: 1%;
              margin-left: 4%;
              line-height: 25px;
              p {
                text-shadow: 0 1px #37b6ff;
              }
              .anquan {
                color: #47ffdf;
                font-size: 20px;
              }
            }
          }
        }
      }
      .leftContBot {
        height: 29%;
        .zhiliang {
          display: flex;
          justify-content: space-between;
        }
        .zltj {
          width: 33%;
          margin-top: 0%;
          height: 90%;
          text-align: center;
          background: url(../assets/images/合格框.png) no-repeat;
          background-size: 100% 100%;
          .zltjP {
            font-size: 15px;
          }
          .zltjP1 {
            color: #2cffb1;
            margin-top: 0;
            font-size: 22px;
          }
          .zltjP2 {
            color: #1cfbff;
            margin-top: 0;
            font-size: 22px;
          }
          .zltjP3 {
            color: #7a84e4;
            margin-top: 0;
            font-size: 22px;
          }
        }
      }
    }
    .centerCont {
      margin-top: 30px;
      width: 50%;
      height: calc(100% - 50px);
      .xiangmu {
        position: absolute;
        width: 9%;
        z-index: 999999;
        bottom:32%;
        left: 65%;
        div {
          height: 30px;
          cursor: pointer;
          line-height: 30px;
          margin-bottom: 15px;
          span {
            font-style: italic;
            margin-left: 20%;
          }
        }
        > div:hover {
          background: url(../assets/images/xiangmuhover.png) no-repeat !important;
          background-size: 100% 100% !important;
        }
        .xiangmuDiv1 {
          background: url(../assets/images/xiangmu2.png) no-repeat;
          background-size: 100% 100%;
        }
        .xiangmuDiv2 {
          background: url(../assets/images/xiangmu4.png) no-repeat;
          background-size: 100% 100%;
        }
        .xiangmuDiv3 {
          background: url(../assets/images/xiangmu3.png) no-repeat;
          background-size: 100% 100%;
        }
        .xiangmuDiv4 {
          background: url(../assets/images/xiangmu1.png) no-repeat;
          background-size: 100% 100%;
        }
        .mapactive {
          background: url(../assets/images/xiangmuhover.png) no-repeat !important;
          background-size: 100% 100% !important;
        }
      }
      .popup {
        width: 270px;
        height: 174px;
        background: url(../assets/images/地图框.png) no-repeat;
        background-size: 100% 100%;
        font-size: 15px;
        .popupHead {
          line-height: 60px;
          margin-left: 25px;
          width: 220px;
          height: 45px;
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          white-space: nowrap;
          //当对象内文本溢出时显示省略标志
          text-overflow: ellipsis;
          border-bottom: 2px solid rgba(22, 214, 220, 0.5) !important;
          span {
            color: #fff;
          }
          .popx {
            color: #c3c3c3;
            text-decoration: none;
            font-weight: bold;
            background: transparent;
            cursor: pointer;
          }
        }
        .popupCont {
          margin-left: 40px;
          width: 200px;
          ul li {
            height: 27px;
            white-space: nowrap;
            //当对象内文本溢出时显示省略标志
            text-overflow: ellipsis;
          }
          ul li::marker {
            color: rgba(22, 214, 220);
            text-shadow: 0 5px #37b6ff;
            font-size: 18px;
          }
          ul li span {
            width: 210px;
            margin-left: -6px;
            overflow: hidden; /*超出部分隐藏*/
            text-overflow: ellipsis; /*超出部分省略号表示*/
            white-space: nowrap; /*强制单行显示*/
            display: inline-block; /*转换为行内块元素*/
          }
        }
      }
      .centerContTop {
        height: 66%;
        width: 100%;
      }
      .centerContBot {
        padding: 10px 20px;
        height: 32%;
        background-color: rgba(4, 45, 66, 0.6);
      }
    }
    .rightCont {
      margin-top: 30px;
      width: 22%;
      height: calc(100% - 60px);
      margin-right: 1%;
      background-color: rgba(4, 45, 66, 0.6);
      padding: 10px;
      padding-top: 0;
      .rightContTop {
        height: 30%;
        .dashiji {
          width: 94%;
          padding: 2%;
          height: 72%;
          border: 4px solid rgba(28, 175, 248, 0.2);
          /deep/ .el-carousel {
            width: 100%;
            height: 100%;
          }
          /deep/ .el-carousel__container {
            height: 100%;
          }
          /deep/ .el-carousel__indicator--horizontal {
            padding-top: 0;
            padding-bottom: 0;
          }
          /deep/ .el-carousel__arrow--left {
            left: 0;
          }
          /deep/ .el-carousel__arrow--right {
            right: 0;
          }
          /deep/ .el-carousel__arrow {
            background-color: rgba(31, 55, 111, 0.3);
          }
          .carcont {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
          }
          .dashijiimg {
            width: 40%;
            height: 170px;
            border: 1px solid rgb(125, 204, 243);
            background: url(../assets/images/dashiji.jpg) no-repeat;
            background-size: 100% 100%;
          }
          .dashijiimg2 {
            background: url(../assets/images/dashiji2.jpg) no-repeat;
            background-size: 100% 100%;
          }
          .dashijicont {
            font-size: 16px !important;
            width: 58%;
            height: 100%;
            tr {
              height: 25px;
            }
            .yuandian {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              margin-top: 3%;
            }
            /deep/ .el-button--mini {
              padding: 5px 8px !important;
            }
            .xiugai {
              background-color: #01384d !important;
              color: #fff !important;
              border: 1px solid rgba(28, 175, 248, 0.2) !important;
            }
            .shanchu {
              background-color: #044c9d !important;
              color: #fff !important;
              border: 1px solid rgba(28, 175, 248, 0.2) !important;
            }
          }
        }
      }
      .rightContCen {
        height: 34%;
        .hetong {
          /deep/.el-table .cell {
            padding: 0 !important;
          }
          /deep/ .el-table__header-wrapper tr,
          /deep/ .el-table th {
            background-color: #013044 !important;
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
      .rightContBot {
        height: 34%;
          .aqsccont {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          .aqsc {
            width: 48%;
            height: 30%;
            background: url(../assets/images/安全生产框.png) no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            .aqscP1 {
              font-weight: 555;
              margin-top: 1%;
            }
            span {
              color: #47ffdf;
              font-size: 26px;
            }
          }
          .aqsc2 {
            width: 100%;
            height: 30%;
            background: url(../assets/images/安全生产框长.png) no-repeat;
            background-size: 100% 100%;
            text-align: left;
            display: flex;
            img {
              margin-left: 4%;
              margin-top: 3%;
              height: 60%;
            }
            div {
              margin-top: 1%;
              margin-left: 4%;
              line-height: 25px;
              width: 80%;
              text-align: center;
              p {
                text-shadow: 0 1px #37b6ff;
              }
              .anquan {
                color: #47ffdf;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .box {
      width: 100%;

      .boxheader {
        width: 100%;
        height: 34px;
        background: url(../assets/images/5548.png) no-repeat;
        span {
          margin-left: 64px;
          line-height: 35px;
          font-style: italic;
          font-weight: 455;
          color: #fff;
          text-shadow: 0px 2px #37b6ff;
          letter-spacing: 3px;
        }
      }
      .boxcont {
        width: 100%;
        height: 85%;
        margin-top: 2%;
      }
    }
  }
}

/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/deep/ .el-table__body-wrapper {
  background-color: transparent !important;
  max-height: 190px;
  overflow-y: auto;
}
/deep/ .el-table__header-wrapper tr,
/deep/ .el-table th {
  background-color: transparent !important;
  color: #fff;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 0 !important;
}
/deep/ .el-table__header-wrapper .el-table__cell {
  border-bottom: 0 !important;
}
/deep/ .el-table .el-table__cell {
  padding: 4px 0;
}
/deep/ .el-table__header-wrapper .cell {
  text-align: center !important;
}
.jinduDiv {
  float: left;
  width: 100px;
  height: 15px;
  border-radius: 20px;
  margin-top: 4px;
  border: 2px solid #1296df;
  overflow: hidden;
  .jinduNum {
    border-radius: 20px;
    height: 100%;
    background-color: #0d97df;
  }
}
/deep/ .shallow-row {
  // background-color: #024962 !important;
  background-color: rgba(2, 63, 85, 0.8) !important;
  color: rgba(22, 214, 220, 0.9) !important;
}
/deep/ .deep-row {
  // background-color: #02415b !important;
  background-color: rgba(2, 55, 75, 0.6) !important;
  // color: #16d6dc !important;
  color: rgba(22, 215, 222, 0.9) !important;
}
/deep/ .tou-row {
  background-color: transparent !important;
  color: #fff !important;
}
/deep/ .el-table__body tr:hover > td {
  background-color: #02283a !important;
}
.shouye {
  position: absolute;
  right: 20px;
  top: 55px;
  background-color: #01384d !important;
  color: #fff !important;
  border: 1px solid rgba(28, 175, 248, 0.2) !important;
}
.videocon {
  position: absolute;
  z-index: 999999999999;
  width: 1135px;
  height: 630px;
  left: 300px;
  top: 120px;

  .toolButton {
    position: absolute;
    z-index: 9999999999999;
    left: 300px;
    top: 10px;

    .el-row {
      .el-button--small {
        padding: 5px;
        font-size: 18px !important;
      }
    }
  }

  .jkpopx {
    position: absolute;
    color: #fff;
    z-index: 9999999999999;
    top: 10px;
    right: 2px;
    font-size: 24px;
  }
}
</style>
