<template>
    <div class="bg">
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                  <el-button  icon="el-icon-circle-plus-outline" @click="beforeuploadModel">新增质量验收表</el-button>
                  <el-button  icon="el-icon-circle-plus-outline" @click="downModel">下载验收模板</el-button>
                  <el-tag class="tag"  type="info" size="mini">校验批总数:{{ishege.checkBatchTotal}}</el-tag>
                  <el-tag class="tag"  type="success" size="mini">合格:{{ishege.pass}}</el-tag>
                  <el-tag  class="tag"  type="danger" size="mini">合格率:{{ishege.passRate*100}}%</el-tag>
                </div>
               
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            row-key="id" 
                            :span-method="arraySpanMethod"
                            @selection-change="handleSelectionChange"
                            border
                            :empty-text="loadInfo"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                          <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="unitConstruction"  label="单位工程"  width="120">  
                        </el-table-column>
                        <el-table-column prop="substructure"  label="分部工程"  width="120" >  
                        </el-table-column>
                        <el-table-column prop="subItem"  label="分项工程"  width="120" >  
                            <template v-slot="scope">
                              {{scope.row.subItem.split('-')[1]}}
                             </template>
                        </el-table-column>
                        <el-table-column prop="inspectionLot"  label="检验批名称"  width="260" >  
                        </el-table-column>
                        <el-table-column prop="files"  label="附件"  width="200" >  
                            <template v-slot="scope">
                                <a href="#" @click="yulan(scope.row.files[0].filePath)" v-if="scope.row.files.length>=1">  {{scope.row.files[0].fileName}}</a>
                                <span v-else> 暂无附件</span>
                             </template>
                        </el-table-column>
                        <el-table-column prop="eligibility"  label="是否合格" sortable  width="100" >  
                            <template v-slot="scope">
                                <el-tag v-if="scope.row.eligibility==0">未验收</el-tag>
                                <el-tag  v-else-if="scope.row.eligibility==1" type="success">合格</el-tag>
                                <el-tag  v-else-if="scope.row.eligibility==2" type="danger">不合格</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="hege(scope.row)"  size="mini">合格</el-button>
                                <el-button  type="primary" @click="buhege(scope.row)"  size="mini" >不合格</el-button>
                                <el-button  type="primary" @click="quxiao(scope.row)"  size="mini" >取消</el-button>
                                <el-button  type="primary" @click="beforeuploadModel1(scope.row)"  size="mini" >附件上传</el-button>
                                <el-button  type="primary" @click="downFile(scope.row)"  size="mini" >附件下载</el-button >
                                <el-button  type="primary" @click="removeFile(scope.row)"  size="mini" >附件删除</el-button >
                                <el-button @click="deleteSelected" type="danger" :disabled="selectedItems.length === 0" size="mini">删除选中项 ({{ selectedItems.length }})</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
   
        <el-dialog :visible.sync="writeForm" title="附件上传" width="50%" >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" >
                <el-form-item label="附件" prop="tenant">
                    <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechange"
                                  :on-remove="handleRemove"
                                  :file-list="fileList"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <!-- <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div> -->
                              </el-upload>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm = false">关 闭</el-button>
                                  <el-button @click="uploadModel">保 存</el-button>
                              </span>
                  </template>
        </el-dialog>

        <el-dialog :visible.sync="writeForm1" title="附件上传" width="50%" >
            <el-form ref="addFormRef" :model="addForm1" label-width="120px" >
                <el-form-item label="附件" prop="tenant">
                    <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechange1"
                                  :on-remove="handleRemove1"
                                  :file-list="fileList1"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div>
                              </el-upload>
                              <el-progress :percentage="percentage" v-if="percentage > 0"></el-progress>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm1 = false">关 闭</el-button>
                                  <el-button @click="uploadModel1">保 存</el-button>
                              </span>
                  </template>
        </el-dialog>
    </div>
  </template>
  
  <script>

  let inputel=[]
    export default {
        name: "commonTable",
        data(){
            return{
                selectedItems:[],
                loadbut:false,
                loadbutext:"上 传",
                progress: 0,
                percentage:0,
                writeForm1:false,
                loadInfo:"数据加载中...",
                tableData:[],
                generatorBusinessList:[],
                menuTable:[],
                isZhank: false,
                dialogVisible: false,
                writeForm:false,
                fileList:[],
                fileList1:[],
                menuId:window.sessionStorage.getItem('menuId'),
                addForm:{
                    projectId:this.$root.proId,
                    attacheIds:[]
                },
                addForm1:{},
                hegenum:0,
                buhegenum:0,
                pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
                },
                ishege:{
                    acceptedQuantity:0,
                    checkBatchTotal:0,
                    pass:0,
                    passRate:0
                },
                editrow:{}
            }
        },
        created(){
            this.getLeftMenu()
            this.getProApp()
            this.getTongji()
        },
        methods:{
            async getTongji(){
                const {data:res}= await this.$http1.get('/v1.0/enterprises/project-qualitative/statisticsByProjectId?projectId='+this.$root.proId)
                this.ishege=res.data
            },
            async getProApp(){
                this.tableData=[]
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-qualitative/findAllByProjectId??page=1&pageSize=10000&projectId='+this.$root.proId)
              this.tableData=res.data.list
              this.loadInfo = ''
            },
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=51')
                this.menuTable=[]
                for(let i in res.data){
                  if(res.data[i].belongOrg==1){
                    this.menuTable.push(res.data[i])
                  }
                }
            },
            handleSelectionChange(selection){
                this.selectedItems = selection;
            },
            deleteSelected(){
                if (this.selectedItems.length === 0) {
                    this.$message.warning('请至少选择一项！');
                    return;
                }

                this.$confirm('确定删除选中的 ' + this.selectedItems.length + ' 项吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    const selectedIds = this.selectedItems.map(item => item.id);
                    console.log(selectedIds)
                    const {data:res}=  this.$http1.delete('v1.0/enterprises/project-qualitative/deleteBatch/'+selectedIds)
                    console.log(res)
                    if(res.businessCode!== 5000){
                        return this.$message.error('删除失败！'+res.message)
                    }
                    this.getProApp()
                    this.$message.success('删除成功！');
               
                })
            },
            arraySpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    const _row = this.flitterData(this.tableData).one[rowIndex]
                    return {
                    rowspan: _row,
                    colspan: 1,
                    }
                }
                if (columnIndex === 1) {
                    const _row1 = this.flitterData2(this.tableData).one[rowIndex]
                    return {
                    rowspan: _row1,
                    colspan: 1,
                    }
                }
                if (columnIndex === 2) {
                    const _row1 = this.flitterData3(this.tableData).one[rowIndex]
                    return {
                    rowspan: _row1,
                    colspan: 1,
                    }
                }
            },
            flitterData(arr) {
                let spanOneArr = [],
                    spanTwoArr = [],
                    concatOne = 0
                arr.forEach((item, index) => {
                    if (index === 0) {
                    spanOneArr.push(1)
                    spanTwoArr.push(1)
                    } else {
                    // 只需要将 span_name 修改为自己需要合并的字段名即可
                    if (item.unitConstruction === arr[index - 1].unitConstruction) {
                    //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1
                    spanOneArr.push(0)
                    } else {
                    spanOneArr.push(1)
                    concatOne = index
                    }
                    }
                })
                return {
                    one: spanOneArr,
                }
            },
            flitterData2(arr) {
                let spanOneArr = [],
                    spanTwoArr = [],
                    concatOne = 0
                arr.forEach((item, index) => {
                    if (index === 0) {
                    spanOneArr.push(1)
                    spanTwoArr.push(1)
                    } else {
                    // 只需要将 span_name 修改为自己需要合并的字段名即可
                    if (item.substructure === arr[index - 1].substructure) {
                    //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1
                    spanOneArr.push(0)
                    } else {
                    spanOneArr.push(1)
                    concatOne = index
                    }
                    }
                })
                return {
                    one: spanOneArr,
                }
            },
            flitterData3(arr) {
                let spanOneArr = [],
                    spanTwoArr = [],
                    concatOne = 0
                arr.forEach((item, index) => {
                    if (index === 0) {
                    spanOneArr.push(1)
                    spanTwoArr.push(1)
                    } else {
                    // 只需要将 span_name 修改为自己需要合并的字段名即可
                    if (item.subItem.split('-')[1] === arr[index - 1].subItem.split('-')[1]) {
                    //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1
                    spanOneArr.push(0)
                    } else {
                    spanOneArr.push(1)
                    concatOne = index
                    }
                    }
                })
                return {
                    one: spanOneArr,
                }
            },
            async hege(row){
                console.log(row)
                delete row.files
                row.eligibility=1;
                row.attacheIds=[];
                const {data:res}= await this.$http1.put('/v1.0/enterprises/project-qualitative/update',row)
                if(res.businessCode==4300){
                    this.$message.success("合格成功");
                    this.getProApp()
                    this.getTongji()
                }else{
                this.$message.error("合格失败！");
                }
            },
            async buhege(row){
                console.log(row)
                delete row.files
                row.eligibility=2;
                row.attacheIds=[];
                const {data:res}= await this.$http1.put('/v1.0/enterprises/project-qualitative/update',row)
                if(res.businessCode==4300){
                    this.$message.success("不合格成功");
                    this.getProApp()
                    this.getTongji()
                }else{
                this.$message.error("不合格失败！");
                }
            },
            async quxiao(row){
                delete row.files
                row.eligibility=0;
                row.attacheIds=[];
                const {data:res}= await this.$http1.put('/v1.0/enterprises/project-qualitative/update',row)
                if(res.businessCode==4300){
                    this.$message.success("取消评定成功");
                    this.getProApp()
                    this.getTongji()
                }else{
                this.$message.error("取消评定失败！");
                }
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            filechange(file,List){
            this.fileList=List
            },
            handleRemove(file, List) {
                    this.fileList=List
              },
              filechange1(file,List){
            this.fileList1=List
            },
            handleRemove1(file, List) {
                    this.fileList1=List
              },
            async shanchu (row)  {
              const confirmResult = await this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http2.delete('/enterprises/project-collections/delete/'+row.id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProApp();
             },
            async yulan(path){
                console.log(path)
                window.open('http://8.130.180.224:9000/epc/'+path)
             },
             async downModel(){
                  const {data:res}= await this.$http1.post('/v1.0/enterprises/project-qualitative/downloadProjectQualitativeTemplateTwo',{ },{ responseType: 'blob'})
                  console.log(res)
              if(res){
                  var elink = document.createElement('a');
                      elink.download = '质量验收表模板';
                      elink.style.display = 'none';
                      var blob = new Blob([res],{type:"application/vnd.ms-excel"});
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            async deleteModel(){

            },
            async downFile(row){
                  const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:row.files[0].filePath },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = row.files[0].fileName;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            beforeuploadModel(row){
                this.fileList=[]
                this.writeForm=true
                this.editrow=row
            },
            beforeuploadModel1(row){
                this.fileList1=[]
                this.writeForm1=true
                this.editrow=row
            },
            isPDF(file) {
            return file.name.toLowerCase().endsWith('.pdf');
        },
           async uploadModel(){
            var formData = new FormData();
            formData.append("file", this.fileList[0].raw);
            formData.append("projectId", this.$root.proId);
            const { data: res } = await this.$http1.post('/v1.0/enterprises/project-qualitative/import/ProjectQualitative', formData)
            if(res.businessCode==4300){
                this.$message.success("提交成功");
                this.getProApp()
                this.writeForm = false;
            }else{
              this.$message.error("提交失败！");
            }
          },

          async uploadModel1(){
            var formData = new FormData();
            for(let i in this.fileList1){
                if(this.isPDF(this.fileList1[i])){
                    formData.append("file", this.fileList1[i].raw);
                    formData.append("projectId", this.editrow.id);
                 }else{
                    this.$message.error("文件格式为PDF");
                    return
                 }
            }
            // formData.append("file", this.fileList1[0].raw);
            // formData.append("projectId", this.editrow.id);
            const config = {
                    onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        console.log(this.percentage)
                    }
                    }
                };
            const { data: res } = await this.$http1.post('/v1.0/enterprises/project-qualitative/upload/file', formData,config)
            if(res.businessCode==1000){
                this.$message.success("提交成功");
                this.getProApp()
                this.percentage = 0
                this.loadbut = false
                this.loadbutext = "上 传"
                this.writeForm1 = false;
            }else{
              this.$message.error("提交失败！");
            }
        },
          async removeFile(row){
            const confirmResult = await this.$confirm('此操作将永久删除该附件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                // console.log("row.files[0].attacheId"+row.files[0].attacheId)
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/enterprises/project-file/delete/AttacheInfoByAttacheId?attacheId='+row.files[0].attacheId)
                if(res.code!= 200){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProApp();

          }
        },
        
        watch: {
            $route(to, from) {
                this.getProApp()
                this.getLeftMenu()
            },
           
        },
        mounted() {
        },
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 90.6% !important;
   }
   .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
#asiderBar .el-menu-item{
  left: 2%;
  width: 96%;
  padding-left: 5px !important;
}
.btnheader{
    display: flex;
    .tag{
        height: 30px;
        margin-left:20px;
        margin-top: 8px;
    }
}
  </style>
  