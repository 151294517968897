import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import "./assets/icon/iconfont.css";
import "lib-flexible/flexible";
import axios from "axios";
import dataV from "@jiaminghi/data-view";
import daohang from "./components/daohanglan";
import dbottom from "./components/dhbootm";
import formCreate from "@form-create/element-ui";
import FcDesigner from "@form-create/designer";
import "bpmn-js/dist/assets/diagram-js.css"; // 左边工具栏以及编辑节点的样式
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css";
import quanju from "./components/tools/quanju.vue";
import * as filters from "./components/tools/fileter";
import "./assets/css/elcommon.less";
import net from "../public/static/index.js";
import times from "./utils/times.js";
import "lib-flexible";
// import VScaleScreen from 'v-scale-screen'

// Vue.use(VScaleScreen)
Vue.use(dataV);
Vue.use(formCreate);
Vue.use(FcDesigner);
Vue.prototype.times = times;
Vue.component("x-daohang", daohang);
Vue.component("x-dbottom", dbottom);
Vue.prototype.proId = quanju.proId;
Vue.config.productionTip = false;
Vue.prototype.NET = net;
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.prototype.$httpaxios = axios;

const serviceAuto = axios.create({
  baseURL: "/auto",
  withCredentials: true,
});
serviceAuto.interceptors.request.use((config) => {
  console.log(config);
  return config;
});
Vue.prototype.$httpAuto = serviceAuto;
const serviceaccount = axios.create({
  baseURL: "/account",
  withCredentials: true,
});
serviceaccount.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http0 = serviceaccount;

const serviceactiviti = axios.create({
  baseURL: "/activiti",
  withCredentials: true,
});
serviceactiviti.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http2 = serviceactiviti;

const servicePro = axios.create({
  baseURL: "/proinfo",
  withCredentials: true,
});
servicePro.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http1 = servicePro;

const serviceDevice = axios.create({
  baseURL: "/device",
  withCredentials: true,
});
serviceDevice.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http4 = serviceDevice;

const serviceCost = axios.create({
  baseURL: "/cost",
  withCredentials: true,
});
serviceCost.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http5 = serviceCost;

const serviceschedule = axios.create({
  baseURL: "/schedule",
  withCredentials: true,
});
serviceschedule.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http6 = serviceschedule;

const servicequality = axios.create({
  baseURL: "/quality",
  withCredentials: true,
});
servicequality.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http7 = servicequality;

const servicefile = axios.create({
  baseURL: "/files",
  withCredentials: true,
});
servicefile.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http8 = servicefile;

const serviceformula = axios.create({
  baseURL: "/formula",
  withCredentials: true,
});
serviceformula.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http9 = serviceformula;

const servicelog = axios.create({
  baseURL: "/log",
  withCredentials: true,
});
servicelog.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " + window.sessionStorage.getItem("token");
  return config;
});
Vue.prototype.$http10 = servicelog;


Vue.directive("has", {
  bind: function (el, binding) {
    if (!Vue.prototype.$_has(binding.value)) {
      el.style.display = "none";
    }
  },
});
// 权限检查方法
Vue.prototype.$_has = function (value) {
  let isExist = false;
  let role = JSON.parse(window.sessionStorage.getItem("btnData"));
  if (role.indexOf(value) !== -1) {
    isExist = true;
  }
  return isExist;
};
new Vue({
  router,
  data: function () {
    return {
      proId: window.sessionStorage.getItem("proId"),
      liuchengxml: "",
    };
  },
  render: (h) => h(App),
}).$mount("#app");
