<template>
  <div class="header">
    <div class="btksicon" @click="gohome">
      <img src="../assets/img/logo.png" alt="" v-show="homeShow" />
      <img src="../assets/img/btkslogo.jpg" alt="" v-show="!homeShow" />
      <span v-show="homeShow">
        兵团设计院
        <br />EPC项目管理云平台
      </span>
    </div>
    <div class="content">
      <span class="dang" v-show="homeShow">当前项目:</span>
      <span class="proname" v-show="homeShow">{{ proName }}</span>
      <span class="centertitle" v-show="!homeShow"
        >兵团设计院EPC项目管理云平台</span
      >
    </div>
    <div class="tools">
      <el-tooltip effect="dark" content="异常消息" placement="top-start">
        <el-badge :value="msgCount" :max="99999" class="item">
          <i
            class="el-icon-message-solid"
            @click="checkNews"
            style="cursor: pointer"
          ></i>
        </el-badge>
      </el-tooltip>
      <el-tooltip effect="dark" content="新版驾驶舱" placement="top-start">
        <i
          class="el-icon-s-marketing"
          @click="gojiashicang_new"
          style="cursor: pointer"
        ></i>
      </el-tooltip>
      <el-tooltip effect="dark" content="旧版驾驶舱" placement="top-start">
        <i
          class="el-icon-s-marketing"
          @click="gojiashicang"
          style="cursor: pointer"
        ></i>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="系统管理"
        placement="top-start"
      >
        <i
          class="el-icon-s-tools"
          @click="goxitong"
          style="cursor: pointer"
        ></i>
      </el-tooltip>

      <!-- <img src="../assets/img/erweima.jpg">-->
      <!--    <el-image
                    style="width: 35px; height: 35px"
                    :src="url"
                    :preview-src-list="srcList">
            </el-image>-->
    </div>
    <img src="../assets/img/username3.png" alt="" />
    <div class="logout">
      <div
        style="
          text-align: center; display: inline-block;vertical-align: middle;"
      >
        <el-dropdown>
          <span class="loginname"
            >{{ name }}<i class="el-icon-arrow-down el-icon--right"></i
          ></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              ><span @click="editPwd">修改密码</span></el-dropdown-item
            >
            <el-dropdown-item>修改个人信息</el-dropdown-item>
            <el-dropdown-item>版本信息</el-dropdown-item>
            <el-dropdown-item @click="logout"
              ><span @click="logout">注销账户</span></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="editdialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
    >
      <el-form
        ref="editFormRef"
        :model="editForm"
        label-width="100px"
        :rules="editFormRules"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="editForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="原密码">
          <el-input
            type="password"
            show-password
            v-model="editForm.oldpwd"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            v-model="editForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="passwordIs">
          <el-input
            type="password"
            v-model="editForm.passwordIs"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          type="goon"
          size="medium"
          @click="editdialogVisible = false"
          >取 消</el-button
        >
        <el-button round type="goon" size="medium" @click="editUserInfo"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看异常消息列表 -->
    <el-dialog title="设备异常消息" :visible.sync="exceptiondialogVisible" width="25%" center :close-on-click-modal="false">
      <el-table :data="newsData" height="100%" :header-cell-style="{ background: '#e3ecfb', color: 'black' }" style="width: 100%; background-color: #142245">
        <el-table-column prop="deviceName" align="center" label="设备名称" width="150">
          <template #default="scope">
            {{ scope.row.deviceName }}
          </template>
        </el-table-column>
        <el-table-column prop="content" align="center" label="消息内容" width="150">
          <template #default="scope">
            {{ scope.row.content }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="scope">
            <el-button type="primary" size="mini" @click="deviceYichang(scope.row.id)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 异常设备详情 -->
    <el-dialog title="异常设备详情" :visible.sync="execptionDialogVisibleDetail" width="25%"
      center :close-on-click-modal="false" @close="dialogClose">
      <el-form :model="deviceExceptionData" label-width="100px">
        <el-form-item label="负责人" prop="principal">
          <el-input size="medium" v-model="deviceExceptionData.principal" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="负责人电话" prop="principalTel">
          <el-input size="medium" v-model="deviceExceptionData.principalTel" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName">
          <el-input size="medium" v-model="deviceExceptionData.deviceName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="消息详情" prop="content">
          <el-input size="medium" v-model="deviceExceptionData.content" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "daohanglan",
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.editForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (value === this.editForm.oldpwd) {
        callback(new Error("不能与旧密码一致!"));
      } else {
        callback();
      }
    };
    return {
      principal: window.sessionStorage.getItem("name"),
      principalTel: window.sessionStorage.getItem("username"),
      msgCount: [],
      deviceExceptionData: [],
      newsData: [],
      exceptiondialogVisible: false,
      execptionDialogVisibleDetail: false,
      name: window.sessionStorage.getItem("name"),
      proName: window.sessionStorage.getItem("proName"),
      url: require("../assets/img/EPC.png"),
      srcList: [require("../assets/img/EPC.png")],
      homeShow: false,
      editdialogVisible: false,
      editForm: {
        username: window.sessionStorage.getItem("username"),
        oldpwd: "",
        password: "",
        passwordIs: "",
      },
      editFormRules: {
        passwordIs: [
          { validator: validatePass2, trigger: "blur" },
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
        oldpwd: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        password: [
          { validator: validatePass, trigger: "blur" },
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        roles: [{ required: true, message: "请选择角色", trigger: "blur" }],
      },
    };
  },
  methods: {
    gohome() {
      this.$router.push({ path: "/home" });
    },
    editPwd() {
      this.editdialogVisible = true;
    },
    logout() {
      sessionStorage.clear();
      this.$router.push({ path: "/login" });
    },
    goxitong() {
      this.$router.push({ path: "/system" });
    },
    gojiashicang() {
      this.$router.push({ path: "/largescreen" });
    },
    gojiashicang_new(){
      this.$router.push({ path: "/largescreen_new" });
    },
    async checkNews() {
      const { data: result } = await this.$http4.post(
        "/notice/query/list",
        {
          isCheck: 0,
          principal: this.principal,
          principalTel: this.principalTel
        }
      );
      if (result.businessCode == "3000") {
        this.newsData = result.data;
        this.exceptiondialogVisible = true;
      } else {
        this.newsData = [];
        this.$message.error(result.message);
      }
    },
    async deviceYichang(id) {
      const { data: result } = await this.$http4.get(
        "/notice/query/info?id=" + id
      );
      if (result.businessCode == "3000") {
        this.deviceExceptionData = result.data;
        this.execptionDialogVisibleDetail = true;
      } else {
        this.deviceExceptionData = [];
        this.$message.error(result.message);
      }
    },
    async dialogClose() {
      const { data: result } = await this.$http4.post(
        "/notice/query/list",
        {
          isCheck: 0,
          principal: this.principal,
          principalTel: this.principalTel
        }
      );
      if (result.businessCode == "3000") {
        this.newsData = result.data;
        this.getMsgCount();
      } else {
        this.newsData = [];
        this.$message.error(result.message);
      }
    },
    async getMsgCount() {
      const { data: result } = await this.$http4.post(
        "/notice/check/count",
        {
          isCheck: 0,
          principal: this.principal,
          principalTel: this.principalTel
        }
      );
      if (result.businessCode == "3000") {
        this.msgCount = result.data;
      } else {
        this.msgCount = [];
        this.$message.error(result.message);
      }
    },
    async editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post(
          "/v1.0/user/updatePassword/" +
            this.editForm.oldpwd +
            "/" +
            this.editForm.password +
            "/" +
            window.sessionStorage.getItem("userid")
        );
        if (res.status !== "2009") {
          // console.log(res);
          return this.$message.error("修改密码失败！" + res.message);
        }
        this.$message.success("修改密码成功！请重新登陆");
        this.dialogVisible = false;
        this.logout();
      });
    },
  },
  mounted() {
    //30秒钟检查一次消息异常内容
    //  this.timer = setInterval(() => {
    //  this.getMsgCount();}, 30000);
  },
  created() {
    var Path = [
      "/system",
      "/home",
      "/usermanage",
      "/unitmanage",
      "/departmentmanage",
      "/promanage",
    ];
    if (Path.indexOf(this.$route.path) !== -1) {
      this.homeShow = false;
    } else {
      this.homeShow = true;
    }
  },
};
</script>

<style scoped lang="less">
.header {
  height: 74px;
  width: 100%;
  display: flex;
  background-color: #fff;
  background-size: 100%;
  display: flex;
  justify-content: space-between;
  img {
    //width: 40px;
    height: 40px;
  }
  > div {
    margin-left: 15px;
    display: flex;
    align-items: center;
    height: 100%;
    span {
      margin-left: 15px;
      font-weight: bold;
      color: #000;
    }
  }
  .btksicon {
    width: 500px;
    img {
      cursor: pointer;
      height: 55px;
    }
    span {
      font-size: 22px;
      text-align: left;
      cursor: pointer;
    }
  }
  .content {
    width: 1350px;
    .dang {
      width: 100px;
    }
    span {
      line-height: 34px;
      font-family: SourceHanSansCN-Medium;
      font-size: 20px;
      color: black;
      position: relative;
      top: 0px;
      margin-right: 19px;
      font-weight: bold;
    }
  }
  .tools {
    width: 180px;
    /deep/ .el-icon-s-tools {
      font-size: 34px;
      margin-left: 5px;
    }
    /deep/ .el-icon-s-marketing {
      font-size: 34px;
      margin-right: 20px;
    }
    /deep/ .el-icon-message-solid {
      font-size: 34px;
      margin-right: 25px;
    }
  }
  .el-badge {
    /deep/.el-badge__content {
      // border-right: 0px;
      margin-right: 30px;
    }
  }
  .logout {
    text-align: center;
    width: 150px;
  }
}
/*  .el-dropdown-menu{
        background-color: #142245;
        color:#c6e2ff !important;
        border: 1px solid #2862b7;
    }
    .el-dropdown-menu__item{
        color:#c6e2ff !important;
    }*/
.loginname {
  font-size: 22px !important;
  text-align: left;
  cursor: pointer;
}
.centertitle {
  font-size: 38px !important;
  margin-left: 170px !important;
}
/deep/ .el-input {
  width: 76%;
}
/deep/ .el-tooltip {
  color: #000;
}
</style>
