<template>
  <div class="mainbox">
    <div class="header">
      <div class="timeDiv">
        <span class="time">{{ date | formatDate }}</span>
      </div>
      <div class="titleDiv">
        <span class="title">兵团设计院EPC项目管理云平台</span>
      </div>
      <div class="adminDiv">
        <img
          src="../assets/images/设置.png"
          class="shezhiImg"
          @click="goSystem"
        />
        <img src="../assets/images/admin.png" class="adminImg" />
        <div>
          <el-dropdown>
            <span class="loginname"
              >{{ name }}<i class="el-icon-arrow-down el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click="logout"
                ><span @click="logout">注销账户</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="leftCont">
        <div class="box leftContTop">
          <div class="boxheader">
            <span> 基本信息统计 </span>
          </div>
          <div class="boxcont aqsccont">
            <div class="aqsc">
              <p class="aqscP1"><span>18</span>人</p>
              <p>项目组成员</p>
            </div>
            <div class="aqsc">
              <p class="aqscP1"><span>9</span>个</p>
              <p>项目部门</p>
            </div>
            <div class="aqsc">
              <p class="aqscP1"><span>0</span>个</p>
              <p>监控设备</p>
            </div>
            <div class="aqsc">
              <p class="aqscP1"><span>39</span>个</p>
              <p>文件数量</p>
            </div>
          </div>
        </div>
        <div class="box leftContCen">
          <div class="boxheader">
            <span> 质量统计 </span>
          </div>
          <div class="boxcont zhiliang">
            <div class="zltj">
              <img src="../assets/images/合格.png" />
              <p class="zltjP">合格</p>
              <p class="zltjP1">{{qualiFrom.pass}}</p>
            </div>
            <div class="zltj">
              <img src="../assets/images/不合格.png" />
              <p class="zltjP">不合格</p>
              <p class="zltjP2">{{qualiFrom.acceptedQuantity-qualiFrom.pass }}</p>
            </div>
            <div class="zltj">
              <img src="../assets/images/未评定.png" />
              <p class="zltjP">合格率</p>
              <p class="zltjP3">{{ qualiFrom.passRate*100 }}%</p>
            </div>
          </div>
        </div>
        <div class="box leftContBot">
          <div class="boxheader">
            <span> 总合同信息 </span>
          </div>
          <div class="boxcont">
            <!-- <div class="time-line">
              <div class="left-btn" @click="leftlcb"></div>
              <div class="right-btn" @click="rightlcb"></div>
              <div class="time-box">
                <ul>
                  <li
                    v-for="(item, index) in liData"
                    :key="index"
                    class="li1 active"
                    style="background-size: 100%"
                    :style="{
                      background:
                        'url(' + item.filePath + ') no-repeat center ',
                    }"
                  >
                    <div v-if="index % 2" class="lcbdiv2" @click.stop="">
                      <p>{{ item.title }}</p>
                      <p>{{ item.milestoneDate }}</p>
                    </div>
                    <div v-else class="lcbdiv1">
                      <p>{{ item.milestoneDate }}</p>
                      <p>{{ item.title }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div> -->
            <!-- <el-table
              :data="tableData2"
              style="width: 100%"
              :row-class-name="tableRowClassName2"
            >
              <el-table-column
                prop="contractorName"
                label="合同名"
                width="173"
                align="center"
              >
                <template slot-scope="scope">
                  <a
                    :title="scope.row.contractorName"
                    @click="yulan(scope.row.files[0].filePath)"
                    style="
                      color: #ff919c;
                      cursor: pointer;
                      display: -webkit-box;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      -webkit-box-orient: vertical;
                      white-space: pre-line;
                      -webkit-line-clamp: 1;
                      text-align: left;
                    "
                  >
                    {{ scope.row.contractorName }}</a
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="signatureTime"
                label="签订日期"
                width="100"
                show-overflow-tooltip
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="contractorMonetary"
                label="总金额(元)"
                width="95"
                align="center"
              >
              </el-table-column>
            </el-table> -->
            <el-descriptions :column="2" direction="vertical">
              <el-descriptions-item label="合同名称"  :span="2"><a style=" color: #ff919c; cursor: pointer;"  @click="yulan(tableData2.files[0].filePath)">{{tableData2.contractorName}}</a></el-descriptions-item>
              <el-descriptions-item label="签订日期">{{tableData2.signatureTime}}</el-descriptions-item>
              <el-descriptions-item label="合同金额">{{tableData2.contractorMonetary}}</el-descriptions-item>
                        <el-descriptions-item label="建设单位（业主单位名称）">{{tableData2.constructionUnit}}</el-descriptions-item>
                        <el-descriptions-item label="建设单位-联系人及联系方式">{{tableData2.constructionLiaison}}</el-descriptions-item>
                        <el-descriptions-item label="实施单位（分院名称）">{{tableData2.implementingEntity}}</el-descriptions-item>
                        <el-descriptions-item label="实施单位-联系人及联系方式">{{tableData2.implementingLiaison}}</el-descriptions-item>
                  </el-descriptions>
          </div>
        </div>
      </div>
      <div class="centerCont">
        <div class="protitle">
          <span>{{ proname }}</span></div>
        <!-- <div class="jkList">
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
          <div class="jiankong">
            <img src="../assets/images/监控图标.png" />
            <p>监控设备1</p>
          </div>
        </div> -->
        <div class="centerContTop">
          <div class="box centerContTopTop">
            <div class="boxheader">
              <span> 项目进度 </span>
            </div>
            <div class="boxcont" style="margin-top: 1%">
              <el-table
                :data="tasksdata"
                style="width: 100%"
                :row-class-name="tableRowClassName"
              >
                <el-table-column prop="taskName" label="" width="150">
                  <template slot-scope="scope">
                    <span :title="scope.row.taskName"  style="color: yellow;display: -webkit-box;text-overflow: ellipsis;overflow: hidden; -webkit-box-orient: vertical; white-space: pre-line; -webkit-line-clamp: 1;
                      ">{{ scope.row.taskName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="startDate"
                  label="计划开工时间"
                  width="130"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="endDate"
                  label="计划完工时间"
                  width="130"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="actStartDate"
                  label="实际开工时间"
                  width="130"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="actEndDate"
                  label="实际完工时间"
                  width="150"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="completeRate" label="完成率">
                  <template slot-scope="scope">
                    <div class="jinduDiv">
                      <div
                        class="jinduNum"
                        v-bind:style="{ width: scope.row.completeRate + '%' }"
                      ></div>
                    </div>
                    &nbsp;{{ scope.row.completeRate }}%
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="centerContBot">
          <div class="box">
            <div class="boxheader">
              <span> 视频监控 </span>
            </div>
            <div class="boxcont" style="margin-top: 1%">
              <div class="centerContBotLeft">
                <div class="jiankong1">
                  <p class="p1">查看监控设备1</p>
                  <p class="p2">监控设备1位于1号厂房</p>
                </div>
                <div class="jiankong1">
                  <p class="p1">查看监控设备2</p>
                  <p class="p2">监控设备1位于2号厂房</p>
                </div>
                <div class="jiankong1">
                  <p class="p1">查看监控设备3</p>
                  <p class="p2">监控设备1位于3号厂房</p>
                </div>
              </div>
              <div class="centerContBotRight">
                <div class="centerContBotRighttitle">
                  <p>监控设备</p>
                </div>
                <div id="video-container" >

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightCont">
        <div class="box rightContTop">
          <div class="boxheader">
            <span> 项目信息</span>
          </div>
          <div class="boxcont">
            <ul>
              <li>
                <span :title="popupdata.projectAddress" class="popspan"
                  >项目地址：{{ popupdata.projectAddress }}</span
                >
              </li>
              <li>
                <span v-if='popupdata.projectType' :title="popupdata.projectType.typeName" class="popspan"
                  >项目类型：{{ popupdata.projectType.typeName }}</span
                >
              </li>
              <li>
                <span :title="popupdata.buildingArea" class="popspan"
                  >项目规模：{{ popupdata.buildingArea }}平方米</span
                >
              </li>
              <li>
                <span :title="popupdata.totalInvestment" class="popspan"
                  >项目总投资：{{ popupdata.totalInvestment }}</span
                >
              </li>
              <li>
                <span :title="popupdata.planStartDate" class="popspan"
                  >建设期限：{{ popupdata.planStartDate }}至 {{popupdata.planEndDate}}</span
                >
              </li>
              <li>
                <span :title="popupdata.ownerUnit" class="popspan"
                  >业主单位：{{ popupdata.ownerUnit }}</span
                >
              </li>
              <li>
                <span :title="popupdata.epcUnit" class="popspan"
                  >总包单位：{{ popupdata.epcUnit }}</span
                >
              </li>
              <li>
                <span :title="popupdata.projectStatus" class="popspan"
                  >项目现状：{{ popupdata.projectStatus }}</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="box rightContCen">
          <div class="boxheader">
            <span> 项目大事记</span>
          </div>
          <div class="boxcont dashiji">
            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item v-for="item in 2" :key="item">
                <div class="carcont">
                  <div class="dashijiimg"></div>
                  <div class="dashijicont">
                    <table>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #08d15f"
                          ></div>
                        </td>
                        <td style="width: 50px">标题：</td>
                        <td style="width: 120px; color: #19eaef">
                          东西一线完工
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #1fa780"
                          ></div>
                        </td>
                        <td style="width: 50px">内容：</td>
                        <td style="width: 120px; color: #19eaef">
                          东西一线沥青混泥土面层施工完毕
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 20px">
                          <div
                            class="yuandian"
                            style="background-color: #d3dd30"
                          ></div>
                        </td>
                        <td style="width: 50px">日期：</td>
                        <td style="width: 120px; color: #19eaef">2021-08-07</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="box rightContBot">
          <div class="boxheader">
            <span> 费用监管 </span>
          </div>
          <div class="boxcont" style="height: 94%">
                  <el-descriptions :column="3" direction="vertical">
                        <el-descriptions-item label="合同总额">{{costData.contractSum}}</el-descriptions-item>
                        <el-descriptions-item label="付款比例">{{costData.paymentRatio}}</el-descriptions-item>
                        <el-descriptions-item label="履约保证金">{{costData.performanceBond}}</el-descriptions-item>
                        <el-descriptions-item label="履约保函期限">{{costData.performanceDeadlines}}</el-descriptions-item>
                        <el-descriptions-item label="工程进度计量">{{costData.progressMeasurement}}</el-descriptions-item>
                        <el-descriptions-item label="项目应收">{{costData.projectReceivable}}</el-descriptions-item>
                        <el-descriptions-item label="项目实际收款">{{costData.actualCollection}}</el-descriptions-item>
                        <el-descriptions-item label="项目拨款">{{costData.projectAppropriation}}</el-descriptions-item>
                        <el-descriptions-item label="账上余额">{{costData.balanceAccount}}</el-descriptions-item>
                        <el-descriptions-item label="剩余应收款项">{{costData.remainingReceivables}}</el-descriptions-item>
                        <el-descriptions-item label="质量保证金">{{costData.qualityAssurance}}</el-descriptions-item>
                        <el-descriptions-item label="质量保函期限">{{costData.qualityDeadlines}}</el-descriptions-item>
                  </el-descriptions>
          </div>
        </div>
      </div>
      <el-button round size="mini" class="zongti" @click="golarge">返回总体</el-button>
      <el-button round size="mini" class="shouye" @click="gohome">首页</el-button>
    </div>
  </div>
</template>

<script>
import "../assets/css/ol1.css";
import "../assets/css/ol2.css";
import EZUIKit from "ezuikit-js";
var padDate = function (value) {
  return value < 10 ? "0" + value : value;
};
export default {
  name: "largescreen",
  data() {
    return {
      date: new Date(),
      name: "管理员",
      proname: window.sessionStorage.getItem("proname"),
      proid: window.sessionStorage.getItem("id"),
      allUrl:
        "http://8.130.130.12:8808/iserver/services/map-epcdata/rest/maps/china",
      dataurl: "http://8.130.130.12:8808/iserver/services/data-epcdata/rest/data",
      accessToken: "at.8gw4vfjo8wq5lrag2c6sy4ea9fjjor2r-6pn6l05ik6-1xq86j8-fvhjjneuy",
      shexiangurl : "ezopen://open.ys7.com/AF3754209/2.hd.live",
      deviceSerial: "",
      channelNo: "",
      costData:{
        "contractSum": null,
                    "paymentRatio": null,
                    "progressMeasurement": null,
                    "projectReceivable": null,
                    "actualCollection": null,
                    "projectAppropriation": null,
                    "balanceAccount": null,
                    "remainingReceivables": null,
                    performanceBond:null,
                    performanceDeadlines:null,
                    qualityAssurance:null,
                    qualityDeadlines:null,
      },
      tableData2: {
        contractorName:"",
        constructionUnit:"",
        contractorMonetary:"",
        constructionUnit:"",
        constructionLiaison:"",
        implementingEntity:"",
        implementingLiaison:''
      }
      ,
      liData: [
       
      ],
      index: 0,
      tasksdata:[],
      popupdata:{},
      qualiFrom:{
      },
      popupdata:{},
      costform:{}
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "shallow-row";
      } else {
        return "deep-row";
      }
    },
    tableRowClassName2({ row, rowIndex }) {
      return "tou-row";
    },
    async getHetong(){
      const { data: res } = await this.$http1.get(
        "/v1.0/enterprises/project-contractor/findAllByProjectId?projectId="+this.proid
      );
      if (res.businessCode != "3000") {
        return;
      }
      this.tableData2 = res.data[0];
    },
    async getProinfo() {
      const { data: res } = await this.$http1.get(
        "/v1.0/project/basicInfo/findById?id=" + this.proid
      );
      if (res.businessCode != "3000") {
        return this.$message.error("查询项目失败！" + res.message);
      }
      this.popupdata = res.data;
    },
    leftlcb() {
      if (this.index > 0) {
        this.index = this.index - 1;
        document.querySelector(".time-box ul").style.transform = `translate(${
          -170 * this.index
        }px)`;
      }
    },
    rightlcb() {
      if (this.index < this.liData.length - 2) {
        this.index++;
        document.querySelector(".time-box ul").style.transform = `translate(${
          -170 * this.index
        }px)`;
      }
    },
    async yulan(path){
        window.open('http://8.130.180.224:9000/epc/'+path)
    },
    async getTaskAll(){
                const {data:res}= await this.$http6.get('/v1.0/schedule/implement/findAll?projectId='+this.proid)
                if(res.businessCode!='3000'){
                    return this.$message.error(res.message)
                }
                this.tasksdata=res.data;
                for(let i in this.tasksdata){
                    this.tasksdata[i].start_date=this.tasksdata[i].startDate.split("-").reverse().join("-")
                    this.tasksdata[i].end_date=this.tasksdata[i].endDate.split("-").reverse().join("-")
                    this.tasksdata[i].parent=this.tasksdata[i].parentId
                    if(this.tasksdata[i].completeRate==null){
                        this.tasksdata[i].completeRate='0'
                    }else {
                        this.tasksdata[i].completeRate=this.tasksdata[i].completeRate*100
                    }
                    this.tasksdata[i].open=true
                }
    },
    async getQuali (){
      const {data:res}= await this.$http1.get('/v1.0/enterprises/project-qualitative/statisticsByProjectId?projectId='+this.proid)
      if (res.businessCode != "3000") {
        return 
      }
      this.qualiFrom = res.data;
    },
    async getlichengbei(){
      const {data:res}= await this.$http6.get('/v1.0/schedule/milestone/findAll?projectId='+this.proid)
                    if(res.businessCode!='3000'){
                        return 
            }
            this.liData = res.data;
      console.log(this.liData)
      for(let i in this.liData){
        this.liData[i].filePath=require("../assets/images/lichengbei"+i+".jpg")
      }
    },
    async getCost(){
      // const {data:res}= await this.$http5.post('/v1.0/cost/contract/manage/findByProjectId?projectId='+this.proid)
      //         if(res.businessCode!='3000'){
      //           return 
      //        }
      //       this.costform = res.data.costAmountStatistics;
      const {data:res}= await this.$http1.get('/v1.0/enterprises/project-progress/statisticsByProjectId?projectId='+this.proid)
      if (res.businessCode != "3000") {
        return;
      }
      this.costData = res.data;
    },
    getVideo() {
      let player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: "video-container",
        accessToken: this.accessToken,
        url: this.shexiangurl,
        template: "standard", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        header: [""], // 如果templete参数不为simple,该字段将被覆盖
        footer: [""], // 如果template参数不为
        width: 500,
        height: 210,
      });
    },
    goSystem() {
      this.$router.push({ path: "/system" });
    },
    golarge(){
      this.$router.push({ path: "/largescreen" });
    },
    gohome() {
      this.$router.push({ path: "/home" });
    },
    logout() {
      window.sessionStorage.removeItem("token");
      this.$router.push({ path: "/login" });
    },
  },
  filters: {
    formatDate: function (value) {
      var date = new Date(value);
      var year = date.getFullYear();
      var month = padDate(date.getMonth() + 1);
      var day = padDate(date.getDate());
      var hours = padDate(date.getHours());
      var minutes = padDate(date.getMinutes());
      var seconds = padDate(date.getSeconds());
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
  mounted() {
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = new Date(); // 修改数据date
    }, 1000);
    this.getVideo();
  },
  created(){
    this.getHetong()
    this.getProinfo();
    this.getTaskAll();
    this.getQuali();
    this.getlichengbei()
    this.getCost();
   
  
  }
};
</script>

<style scoped lang="less">
.mainbox {
  color: #fff;
  font-size: 18px;
  background: url(../assets/images/beijing.png) no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  .header {
    background: url(../assets/images/header.png) no-repeat;
    background-size: 100% 100%;
    height: 60px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .timeDiv {
      width: 30%;
      .time {
        margin-left: 10px;
        line-height: 64px;
        color: #aae1ff;
        font-size: 18px;
      }
    }
    .titleDiv {
      text-align: center;
      width: 40%;
      .title {
        font-size: 32px;
        color: #36f2ff;
        letter-spacing: 4px;
        line-height: 54px;
      }
    }
    .adminDiv {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .shezhiImg {
        margin-left: 30%;
        height: 28px;
        margin-top: 19px;
        cursor: pointer;
      }
      .adminImg {
        margin-left: 30%;
        height: 38px;
        margin-top: 13px;
      }
      div {
        line-height: 60px;
        margin-right: 20px;
        .loginname {
          color: #aae1ff;
          font-size: 20px !important;
          cursor: pointer;
        }
      }
    }
  }

  .content {
    height: calc(100vh - 60px);
    display: flex;
    justify-content: space-between;
    .leftCont {
      margin-top: 30px;
      width: 20%;
      height: calc(100% - 70px);
      margin-left: 1%;
      background-color: rgba(4, 45, 66, 0.6);
      padding: 10px;
      .leftContTop {
        width: 100%;
        height: 34%;
        margin-top: 2%;
        .aqsccont {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          .aqsc {
            width: 48%;
            height: 30%;
            background: url(../assets/images/安全生产框.png) no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            .aqscP1 {
              font-weight: 555;
              margin-top: 4%;
            }
            span {
              color: #47ffdf;
              font-size: 26px;
            }
          }
          .aqsc2 {
            width: 100%;
            height: 30%;
            background: url(../assets/images/安全生产框长.png) no-repeat;
            background-size: 100% 100%;
            text-align: left;
            display: flex;
            img {
              margin-left: 4%;
              margin-top: 4%;
              height: 60%;
            }
            div {
              margin-top: 2%;
              margin-left: 4%;
              line-height: 28px;
              p {
                text-shadow: 0 1px #37b6ff;
              }
            }
          }
        }
      }
      .leftContCen {
        height: 26%;
        .zhiliang {
          display: flex;
          justify-content: space-between;
        }
        .zltj {
          width: 33%;
          margin-top: 1%;
          height: 90%;
          text-align: center;
          background: url(../assets/images/合格框.png) no-repeat;
          background-size: 100% 100%;
          .zltjP {
            font-size: 15px;
          }
          .zltjP1 {
            color: #2cffb1;
            margin-top: 3px;
            font-size: 22px;
          }
          .zltjP2 {
            color: #1cfbff;
            margin-top: 3px;
            font-size: 22px;
          }
          .zltjP3 {
            color: #7a84e4;
            margin-top: 3px;
            font-size: 22px;
          }
        }
      }
      .leftContBot {
        width: 100%;
        height: 40%;
        margin-top: 1%;
      }
    }
    .centerCont {
      margin-top: 30px;
      width: 50%;
      height: calc(100% - 50px);
      position: relative;
      .protitle {
        position: absolute;
        width: 100%;
        font-size: 22px;
        font-style: italic;
        letter-spacing: 4px;
        font-weight: 455;
        color: #fff;
        text-shadow: 0px 2px #73cbfd;
        text-align: center;
        margin-top: -10px;
        z-index: 999999999999;
        background: url('../assets/images/5548.png') no-repeat;
        background-size: 100% 100%;
        span{
          display: block;
          width: 68%;
          margin-left: 16%;
        }
      }
      .jkList {
        width: 80px;
        height: 500px;
        max-height: 500px;
        overflow-y: auto;
        position: absolute;
        right: 2px;
        z-index: 999999999999;
        .jiankong {
          background: url(../assets/images/dizuo.png) no-repeat;
          background-position-y: bottom;
          text-align: center;
          height: 80px;
          img {
            width: 80px;
            height: 75px;
            cursor: pointer;
          }
          p {
            font-size: 14px;
            margin-top: -20px;
          }
        }
      }
      .centerContTop {
        margin-top: 4%;
        height: 58%;
        width: 100%;
        .centerContTopTop{
          height: 100%;
          .boxcont{
            width: 98%;
            background: url('../assets/images/processkuang.png') no-repeat;
            background-size: 100% 100%;
            padding: 10px;
          }
        }
      }
      .centerContBot {
        padding: 10px 20px;
        height: 34%;
        background: url('../assets/images/jiankongkuang.png') no-repeat;
        background-size: 100% 100%;
        .boxcont{
          display: flex;
          justify-content: space-between;
          .centerContBotLeft{
            width: 35%;
            .jiankong1{
              background: url('../assets/images/监控1.png') no-repeat;
              background-size: 100% 100%;
              width: 100%;
              height: 70px;
              margin-top: 5px;
              margin-bottom: 5px;
              .p1{
                margin-left: 104px;
                padding-top: 4px;
                color: #fff;
                font-size: 16px;
                text-shadow: 0 2px #73cbfd;
                letter-spacing: 1px;
              }
              .p2{
                margin-left: 104px;
                padding-top: 6px;
                font-size: 16px;
                color:rgba(255, 255, 255, 0.8);
                letter-spacing: 1px;
              }
            }
          }
          .centerContBotRight{
            width: 60%;
            background: url('../assets/images/监控2.png') no-repeat;
            background-size: 100% 100%;
            text-align: center;
            margin-top: -20px;
            .centerContBotRighttitle{
              margin-left: 34%;
              margin-top: -15px;
                width: 30%;
                background: url('../assets/images/监控列表.png') no-repeat;
                background-size: 100% 100%;
                p{
              font-size: 20px;
              text-shadow: 0 2px #73cbfd;
                }
            }
            .video-container{
              margin-left: 20px;
              width: 460px;
              height: 210px;
            }
           
          }
        }
      }
    }
    .rightCont {
      margin-top: 30px;
      width: 20%;
      height: calc(100% - 70px);
      margin-right: 1%;
      background-color: rgba(4, 45, 66, 0.6);
      padding: 10px;
      .rightContTop{
        height: 34%;
        .boxcont{
          background: url(../assets/images/项目详情框.png) no-repeat;
          background-size: 100% 100%;
          margin-top: 1%;
          ul{
            list-style: none;
          }
          ul li {
            height: 27px;
           
            margin-left: 20px;
          }
          
          ul li span {
            line-height: 27px;
            width: 350px;
            margin-left: -6px;
            font-size: 14px;
            overflow: hidden; /*超出部分隐藏*/
            text-overflow: ellipsis; /*超出部分省略号表示*/
            white-space: nowrap; /*强制单行显示*/
            display: inline-block; /*转换为行内块元素*/
          }
          ul li::marker {
            color: rgba(22, 214, 220);
            text-shadow: 0 5px #37b6ff;
            font-size: 18px;
            line-height: 7px;
            display: inline-block; 
          }
        }
      }
      .rightContCen {
        height: 25%;
        .dashiji {
          width: 94%;
          height: 78%;
          padding: 2%;
          border: 4px solid rgba(28, 175, 248, 0.2);

          /deep/ .el-carousel {
            width: 100%;
            height: 100%;
          }
          /deep/ .el-carousel__container {
            height: 100%;
          }
          /deep/ .el-carousel__indicator--horizontal {
            padding-top: 0;
            padding-bottom: 0;
          }
          /deep/ .el-carousel__arrow--left {
            left: 0;
          }
          /deep/ .el-carousel__arrow--right {
            right: 0;
          }
          /deep/ .el-carousel__arrow {
            background-color: rgba(31, 55, 111, 0.3);
          }
          .carcont {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
          }
          .dashijiimg {
            width: 38%;
            height: 120px;
            margin-top: 5px;
            border: 1px solid rgb(125, 204, 243);
            background: url(../assets/images/dashiji.jpg) no-repeat;
            background-size: 100% 100%;
          }
          .dashijicont {
            font-size: 16px !important;
            width: 60%;
            height: 100%;
            tr {
              height: 25px;
            }
            .yuandian {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              margin-top: 3%;
            }
            /deep/ .el-button--mini {
              padding: 5px 8px !important;
            }
            .xiugai {
              background-color: #01384d !important;
              color: #fff !important;
              border: 1px solid rgba(28, 175, 248, 0.2) !important;
            }
            .shanchu {
              background-color: #044c9d !important;
              color: #fff !important;
              border: 1px solid rgba(28, 175, 248, 0.2) !important;
            }
          }
        }
      }
      
      .rightContBot {
        height: 34%;
        margin-top: 6%;
             .boxcont{
              background: transparent;
             }
      }
    }
    .box {
      width: 100%;

      .boxheader {
        width: 100%;
        height: 34px;
        background: url(../assets/images/5548.png) no-repeat;
        span {
          margin-left: 64px;
          line-height: 35px;
          font-style: italic;
          font-weight: 455;
          color: #fff;
          text-shadow: 0px 2px #37b6ff;
          letter-spacing: 3px;
        }
      }
      .boxcont {
        width: 100%;
        height: 85%;
        margin-top: 2%;
      }
    }
  }
}
.zongti {
  position: absolute;
  left: 20px;
  top: 55px;
  background-color: #01384d !important;
  color: #fff !important;
  border: 1px solid rgba(28, 175, 248, 0.2) !important;
}
.shouye {
  position: absolute;
  right: 20px;
  top: 55px;
  background-color: #01384d !important;
  color: #fff !important;
  border: 1px solid rgba(28, 175, 248, 0.2) !important;
}
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/deep/ .el-table__body-wrapper {
  background-color: transparent !important;
  max-height: 380px;
  overflow-y: auto;
}
/deep/ .el-table__header-wrapper tr,
/deep/ .el-table th {
  background-color: transparent !important;
  color: #fff;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 0 !important;
}
/deep/ .el-table__header-wrapper .el-table__cell {
  border-bottom: 0 !important;
}
/deep/ .el-table .el-table__cell {
  padding: 4px 0;
}
/deep/ .el-table__header-wrapper .cell {
  text-align: center !important;
}
.jinduDiv {
  float: left;
  width: 150px;
  height: 15px;
  border-radius: 20px;
  margin-top: 4px;
  border: 2px solid #1296df;
  overflow: hidden;
  .jinduNum {
    border-radius: 20px;
    height: 100%;
    background-image: linear-gradient(to right, #2b89e0, #00deff);
  }
}
/deep/ .shallow-row {
  // background-color: #024962 !important;
  background: transparent !important;
  color: rgba(22, 214, 220, 0.9) !important;
}
/deep/ .deep-row {
  // background-color: #02415b !important;
  background: transparent !important;
  // color: #16d6dc !important;
  color: rgba(22, 215, 222, 0.9) !important;
}
/deep/ .tou-row {
  background-color: transparent !important;
  color: #fff !important;
}
/deep/ .el-table__body tr:hover > td {
  background-color: #02283a !important;
}

//里程碑
.time-line {
  width: 390px;
  margin: 10px auto;
  margin-top: 0;
  position: relative;
}
.time-line .left-btn,
.time-line .right-btn {
  z-index: 99999;
  width: 40px;
  height: 30px;
  background: url("../assets/img/process/arrow.png") no-repeat;
  position: absolute;
  margin-top: 5px;
  cursor: pointer;
}
.time-line .left-btn {
  left: -10px;
  top: 40%;
}
.time-line .right-btn {
  right: 0;
  top: 40%;
  transform: rotate(180deg);
}
.time-line .right-btn:hover {
  background: url("../assets/img/process/arrow-hover.png") no-repeat;
  transform: rotate(180deg);
}
.time-line .left-btn:hover {
  background: url("../assets/img/process/arrow-hover.png") no-repeat;
}
.time-line .time-box {
  width: 360px;
  margin: 0 auto;
  margin-left: 20px;
  overflow: hidden;
}
.time-line .time-box ul {
  list-style: none;
  overflow: hidden;
  position: relative;
  height: 220px;
  width: 100rem;
  padding-top: 10px;
  transition: all 0.5s linear;
}
.time-line .time-box ul:after {
  content: "";
  background: #d2d2d2;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
}
.time-line .time-box li {
  width: 140px;
  float: left;
  box-sizing: border-box;
  border: 1px solid #d2d2d2;
  position: relative;
  cursor: pointer;
  height: 90px;
  margin-left: 20px;
}
.time-line .time-box li:nth-child(2n) {
  margin-top: 130px;
  margin-left: 40px;
}
.time-line .time-box li:before {
  content: "";
  border-left: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  position: absolute;
  width: 16px;
  height: 16px;
  background: #fff;
  bottom: -9px;
  transform: rotate(-45deg);
  left: 50%;
  margin-left: -8px;
}
.time-line .time-box li:after {
  content: "";
  width: 15px;
  height: 15px;
  background: url("../assets/img/process/point.png") no-repeat;
  position: absolute;
  left: 50%;
  margin-left: -7px;
  bottom: -33px;
  z-index: 1;
}
.time-line .time-box li:nth-child(2n):before {
  bottom: auto;
  top: -10px;
  transform: rotate(-225deg);
}
.time-line .time-box li:nth-child(2n):after {
  bottom: auto;
  top: -33px;
}
.time-line .time-box li:hover,
.time-line .time-box li.active {
  color: #fff;
  border-color: #1b96ff;
}
.time-line .time-box li:hover:before,
.time-line .time-box li.active:before {
  border-color: #1b96ff;
  background: #1b96ff;
}
.time-line .time-box li:hover:after,
.time-line .time-box li.active:after {
  background: url("../assets/img/process/point-hover.png") no-repeat;
}
.lcbdiv2 {
  position: absolute;
  margin-top: -130px;
  width: 140px;
  text-align: center;
  p {
    word-wrap: break-word;
  }
}
.lcbdiv1 {
  position: absolute;
  margin-top: 130px;
  width: 140px;
  text-align: center;
  p {
    word-wrap: break-word;
  }
}
/deep/ .el-descriptions__body{
  background: transparent !important;
}
/deep/ .el-descriptions-item__label{
    color: #fff;
    font-size: 15px;
    font-weight: 777 !important;
    background: transparent !important;
}
/deep/ .el-descriptions-item__content{
    font-size: 13px;
    color: rgb(25, 234, 239);
}
</style>
